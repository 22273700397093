@import "entry";

.landcomp_footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -380px;
	background: @mainBack;
	height: 380px;
	.lg-block({
		bottom: -610px;
		height: 610px;
	});
	.md-block({
		bottom: -600px;
		height: 600px;
	});
	.xs-block({
		bottom: -630px;
		height: 630px;
	});

	&__block {
		display: flex;
		align-items: flex-start;
		padding: 24px 0;
		.lg-block({
			flex-direction: column;
			align-items: center;
		});
		.xs-block({
			flex-direction: column;
			align-items: center;
		});
		.logo {
			.logo();
			.xs-block({
				margin-bottom: 20px;
			    transform: translateX(-20px);
			});
		}

		.other_specializations {
			margin: 0 auto;
			padding-top: 15px;
		    .lg-block({
		        flex-shrink: 0;
		    });
		    .title {
		    	.xs-block({
		    		text-align: center;
		    	});
		    }
		    .links {
		        display: flex;
		        flex-direction: column;
		        margin-top: 10px;
		        height: 275px;
		        overflow-y: scroll;
		        overflow-x: hidden;
		        background: #ffffff;
		        border-radius: 30px 0 0 30px;
		        padding: 10px 20px 10px;
		        .md-block({
		        	margin-top: 10px;
		        });
		         a {
		            color: @optiColor;
		            text-transform: uppercase;
		            font-weight: 600;
		            font-size: 15px;
		            line-height: 292%;
		            &:hover {
		                opacity: .7;
		                transition: .3s;
		            }
		            .lg-block({
		                font-size: 14px;
		                line-height: 290%;
		                text-align: center;
		            });
		            .md-block({
		                font-size: 13px;
		                line-height: 270%;
		            });
		            .xs-block({
		                line-height: 310%;
		            });
		         }
		    }
		}

		.time {
			.time();
			margin: 0 50px 0 auto;
			padding-top: 5px;
			.lg-block({
				margin: 0 auto;
				margin-top: 20px;
			});
			.md-block({
				margin: 0 auto;
				margin-top: 20px;
			});
			.xs-block({
				margin-top: 30px;
			});
		}
		.tel {
			.tel();
			padding-top: 10px;
			margin-right: 50px;
			display: flex;
			.lg-block({
				margin: 0 auto;
				margin-top: 20px;
			});
		}
	}
}