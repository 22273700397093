@import "entry";

.landcomp_bid {
	width: 100%;
	height: 800px;
	background: url(../img/bid/back.jpg) no-repeat;
	background-size: cover;
	.md-block({
		height: 1200px;
		background-position: 61% 100%;
	});
	.xs-block({
		height: auto;
	});
	.wrapper {
		display: flex;
		justify-content: space-between;
		.md-block({
			flex-direction: column;
		});
		.xs-block({
			padding: 0;
		});
	}
	&__left {
		padding-top: 130px;
		padding-left: 60px;
		margin-right: 135px;
		position: relative;
		.lg-block({
			padding-left: 0;
			margin-right: 60px;
		});
		.md-block({
			padding-top: 65px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0;
		});
		&::after {
			content: "";
			position: absolute;
			background: url(../img/bid/clock.png) no-repeat;
			background-size: cover;
			width: 288px;
			height: 288px;
			top: 13px;
			right: -244px;
			z-index: 2;
			.lg-block({
				background: url(../img/bid/clock_lg.png) no-repeat;
				background-size: cover;
				width: 248px;
				height: 248px;
				top: 20px;
				right: -140px;
			});
			.md-block({
				background: url(../img/bid/clock_md.png) no-repeat;
				background-size: cover;
				width: 222px;
				height: 223px;
				top: 10px;
				right: -60px;
			});
			.xs-block({
				background: url(../img/bid/clock_xs.png) no-repeat;
				background-size: cover;
				width: 153px;
				height: 152px;
				top: 150px;
				right: -60px;
			});
		}
		.desc {
			font-weight: 700;
			font-size: 36px;
			line-height: 126%;
			color: @mainBack;
			margin-bottom: 60px;
			.lg-block({
				font-size: 28px;
				margin-bottom: 50px;
			});
			.md-block({
				font-size: 26px;
				width: 460px;
				text-align: center;
				br {
					display: none;
				}
			});
			.xs-block({
				width: 290px;
			});
		}
		.landcomp_bid__form {
			.error_name {
				.error();
			}
			.error_tel {
				.error();
			}
			.input_item {
				&:first-child {
					margin-bottom: 40px;
				}
				input {
					.input(452px; 50px);
					.lg-block({
						.input(400px; 50px);
					});
					.xs-block({
						.input(290px; 50px);
					});
				}
			}
			.action {
				.button_std(452px; 70px);
				margin-top: 60px;
				.lg-block({
					.button_std(400px; 70px);
				});
				.xs-block({
					.button_std(290px; 70px);
				});
			}
		}
	}
	&__right {
		margin-top: 90px;
		width: 681px;
		height: 600px;

		background: rgba(34, 34, 34, 0.6);
		border-radius: 38px;
		position: relative;
		padding-top: 56px;
		padding-left: 74px;
		.lg-block({
			width: 552px;
			padding-left: 30px;
		});
		.md-block({
			margin: 0 auto;
			margin-top: 60px;
			padding-top: 45px;
			display: flex;
			flex-direction: column;
			align-items: center;
		});
		.xs-block({
			width: 100%;
			height: auto;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 40px;
			border-radius: 0;
		});
		&::before {
			content: "";
			position: absolute;
			background: url(../img/bid/back.jpg) no-repeat;
			background-size: cover;
			background-attachment: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		    filter: blur(7px) brightness(0.6);
		    .md-block({
		    	background-position: 61% 100%;
		    });
		}
		&::after {
			content: "";
			position: absolute;
			background: url(../img/bid/border.png) no-repeat;
			background-size: cover;
			width: 100%;
			height: 100%;
			left: -45px;
			top: 35px;
			.lg-block({
				background: url(../img/bid/border_lg.png) no-repeat;
				background-size: cover;
				top: 25px;
				left: -35px;
			});
			.xs-block({
				display: none;
			});
		}
		.title {
			position: relative;
			z-index: 2;
			font-weight: 700;
			font-size: 26px;
			line-height: 126%;
			color: #ffffff;
			padding-bottom: 60px;
			width: 410px;
			.md-block({
				font-size: 20px;
				padding-bottom: 40px;
			});
			.xs-block({
				width: 290px;
			});
		}
		ul {
			position: relative;
			z-index: 2;

			font-weight: 300;
			font-size: 18px;
			line-height: 150%;
			color: #ffffff;
			width: 487px;
			.lg-block({
				width: 407px;
			});
			.xs-block({
				width: 100%;
				padding: 0 15px;
			});

			li {
				position: relative;
				margin-bottom: 30px;
				padding-left: 68px;
				.lg-block({
					padding-left: 48px;
				});
				&:last-child {
					margin-bottom: 0;
				}
				&:nth-child(2) {
					&::before {
						background: url(../img/bid/2.svg) no-repeat;
						background-size: cover;
					}
				}
				&:nth-child(3) {
					&::before {
						background: url(../img/bid/3.svg) no-repeat;
						background-size: cover;
					}
				}
				&:nth-child(4) {
					&::before {
						background: url(../img/bid/4.svg) no-repeat;
						background-size: cover;
					}
				}
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					background: url(../img/bid/1.svg) no-repeat;
					background-size: cover;
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}