@import "entry";

@keyframes slideLeftShow {
    0% {left: -100%;}
    100% {
    	left: 0%;
    	box-shadow: -20px 40px 230px #000000;
    }
}

@keyframes slideLeftClose {
    0% {left: 0%;}
    100% {
    	left: -100%;
    	box-shadow: none;
    }
}

.landcomp__mob_menu {
    z-index: 700;
    background: #ffffff;
    top: 0;
    width: 365px;
    height: 575px;
    position: fixed;
    overflow-y: auto;
    left: -100%;
    padding-bottom: 70px;

    .xs-block({
    	width: 100%;
    	height: 100%;
    });

	.wrapper {
		padding: 0;
	}
    &.show {
        animation: slideLeftShow .3s linear forwards;
    }
    &.close {
        animation: slideLeftClose .3s linear forwards;
    }

    &__block {
        .list_menu {
            padding: 0 19px;
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            li {
            	margin-bottom: 40px;
            	&:last-child {
            		margin-bottom: 0;
            	}
                a {
                	font-weight: 700;
                	font-size: 18px;
                	line-height: 22px;
                    color: @twoColor;
                }
            }
        }

        .action {
        	display: none;
        	.button_std(290px; 55px);
        	justify-content: center;
        	margin: 0 auto;
        	margin-top: 50px;
        	.xs-block({
        		display: flex;
        	});
        }

        .mob_menu_action {
        	padding-top: 12px;
        	padding-bottom: 10px;
        	border-bottom: 1px solid #EEF1F8;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-left: 19px;
            img {
                margin-right: 15px;
            }
        }
    }
}