body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1360px;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

.debug {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 999999999;
}

.debug > div {
  height: inherit;
  max-width: 1360px;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

.debug > div > div {
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.debug > div > div > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(8.333333333333334% - 20px);
  height: inherit;
  outline: 1px solid #ff0;
  background: rgba(0, 0, 0, 0.5);
}

@font-face {
  font-family: Museo Cyrl;
  src: url("../fonts/Museo Cyrl 300.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Museo Cyrl;
  src: url("../fonts/Museo Cyrl 500.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: Museo Cyrl;
  src: url("../fonts/Museo Cyrl 700.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: Museo Cyrl;
  src: url("../fonts/Museo Cyrl 900.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
}

* {
  font-size: 16px;
  font-family: Museo Cyrl, Arial, sans-serif;
  box-sizing: border-box;
}

body {
  background: #fff;
  color: #000;
  font-family: Museo Cyrl, Arial, sans-serif;
  font-weight: bold;
  height: 100%;
  min-height: auto;
  position: relative;
  word-wrap: break-word;
  color: #000000;
  box-sizing: border-box;
  overflow-x: hidden;
  letter-spacing: 0.05em;
}

strong {
  font-weight: bold;
}

ul,
li,
a {
  font-family: Museo Cyrl;
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

.wrapper h1,
.wrapper h2,
.wrapper h3,
.wrapper h4,
.wrapper h5 {
  padding: 0 !important;
  border-bottom: none !important;
}

.fancybox-slide--html {
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

[disabled] {
  cursor: not-allowed;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #fff;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: #FF833D;
  box-shadow: none;
}

/*--------------------------------------------------------------*/

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: 0.2s;
}

.fade-leave-active {
  transition: 0.2s;
}

.fade-leave-to {
  opacity: 0;
}

/*--------------------------------------------------------------*/

.lazy_show {
  opacity: 1;
  transition: 0.5s;
}

img.lazy {
  opacity: 0;
}

@keyframes fixed {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.landcomp__header {
  border-bottom: 1px solid #EEF1F8;
}

.landcomp__header.fixed {
  animation: fixed 0.5s ease;
  transition: 0.5s;
  position: fixed;
  background: #ffffff;
  z-index: 600;
  width: 100%;
}

.landcomp__header.fixed .back_fixed {
  animation: fixed 0.5s ease;
  transition: 0.5s;
  display: block;
}

.landcomp__header .back_fixed {
  display: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  height: 42px;
  width: 100%;
  background: #ffffff;
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
}

.landcomp__header .mob_menu_action {
  display: none;
}

.landcomp__header nav {
  display: flex;
}

.landcomp__header nav.fixed {
  animation: fixed 0.5s ease;
  transition: 0.5s;
  position: fixed;
  background: #ffffff;
  z-index: 300;
}

.landcomp__header nav.fixed .back_fixed {
  animation: fixed 0.5s ease;
  transition: 0.5s;
  display: block;
}

.landcomp__header nav.fixed .tel {
  display: flex;
}

.landcomp__header nav a {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  margin-right: 60px;
  padding: 12px 0;
  position: relative;
}

.landcomp__header nav a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #FF833D;
  bottom: -1px;
  opacity: 0;
}

.landcomp__header nav a:hover {
  transition: color 0.2s;
  color: #FF833D;
}

.landcomp__header nav a:hover::before {
  transition: 0.2s;
  opacity: 1;
}

.landcomp__header nav .tel {
  display: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.landcomp__header nav .tel:hover .title {
  transition: 0.3s;
  color: #FF833D;
}

.landcomp__header nav .tel img {
  margin-right: 10px;
}

.landcomp__header nav .tel .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 29px;
  color: #333333;
}

.landcomp__header .addr {
  display: none;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
}

.landcomp__header .tel {
  display: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.landcomp__header .tel:hover .title {
  transition: 0.3s;
  color: #FF833D;
}

.landcomp__header .tel img {
  margin-right: 10px;
}

.landcomp__header .tel .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 29px;
  color: #333333;
}

.landcomp__header .mob_menu_action {
  display: none;
  align-items: center;
  cursor: pointer;
}

.landcomp__header .mob_menu_action img {
  margin-right: 15px;
  display: none;
}

.landcomp__header .mob_menu_action img.show {
  display: block;
}

@keyframes slideLeftShow {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
    box-shadow: -20px 40px 230px #000000;
  }
}

@keyframes slideLeftClose {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
    box-shadow: none;
  }
}

.landcomp__mob_menu {
  z-index: 700;
  background: #ffffff;
  top: 0;
  width: 365px;
  height: 575px;
  position: fixed;
  overflow-y: auto;
  left: -100%;
  padding-bottom: 70px;
}

.landcomp__mob_menu .wrapper {
  padding: 0;
}

.landcomp__mob_menu.show {
  animation: slideLeftShow 0.3s linear forwards;
}

.landcomp__mob_menu.close {
  animation: slideLeftClose 0.3s linear forwards;
}

.landcomp__mob_menu__block .list_menu {
  padding: 0 19px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landcomp__mob_menu__block .list_menu li {
  margin-bottom: 40px;
}

.landcomp__mob_menu__block .list_menu li:last-child {
  margin-bottom: 0;
}

.landcomp__mob_menu__block .list_menu li a {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.landcomp__mob_menu__block .action {
  display: none;
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 290px;
  height: 55px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
}

.landcomp__mob_menu__block .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__mob_menu__block .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__mob_menu__block .mob_menu_action {
  padding-top: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EEF1F8;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 19px;
}

.landcomp__mob_menu__block .mob_menu_action img {
  margin-right: 15px;
}

.landcomp__banner {
  height: 720px;
  padding-top: 14px;
  background: url(../img/main/back_main.jpg) no-repeat;
  background-size: cover;
  position: relative;
}

.landcomp__banner.other {
  background: url(../img/main/back_main_other.png) no-repeat;
  background-size: cover;
}

.landcomp__banner .wrapper {
  position: relative;
}

.landcomp__banner_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 67px;
  z-index: 2;
}

.landcomp__banner_top .logo {
  display: flex;
  align-items: center;
  color: #333333;
  cursor: default;
}

.landcomp__banner_top .logo img {
  width: 83px;
  height: 72px;
  margin-right: 12px;
}

.landcomp__banner_top .logo .title {
  font-weight: 900;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0.01em;
}

.landcomp__banner_top .logo .desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.03em;
  display: block;
  width: 244px;
}

.landcomp__banner_top .time {
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  margin-right: 150px;
}

.landcomp__banner_top .time img {
  margin-right: 27px;
}

.landcomp__banner_top .addr {
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  flex-wrap: wrap;
}

.landcomp__banner_top .addr .title {
  width: 100%;
  margin-bottom: -30px;
}

.landcomp__banner_top .addr .text {
  width: 100%;
}

.landcomp__banner_top .tel {
  display: none;
  align-items: center;
  padding: 0;
  margin: 0;
  display: flex;
}

.landcomp__banner_top .tel:hover .title {
  transition: 0.3s;
  color: #FF833D;
}

.landcomp__banner_top .tel img {
  margin-right: 20px;
}

.landcomp__banner_top .tel .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
}

.landcomp__banner_top .tel:hover .title {
  transition: 0.3s;
  color: #FF833D;
}

.landcomp__banner_top .tel img {
  margin-right: 20px;
}

.landcomp__banner_top .tel .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #333333;
}

.landcomp__banner_top .tel::before {
  display: none;
}

.landcomp__banner_main h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 126%;
  width: 890px;
  margin-bottom: 25px;
}

.landcomp__banner_main h1 span {
  font-weight: 600;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__banner_main .desc {
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  width: 480px;
  margin-bottom: 54px;
}

.landcomp__banner_main_form .error_name {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

.landcomp__banner_main_form .error_name.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

.landcomp__banner_main_form .error_tel {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

.landcomp__banner_main_form .error_tel.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

.landcomp__banner_main_form .input_item:first-child {
  margin-bottom: 30px;
}

.landcomp__banner_main_form .input_item input {
  box-sizing: border-box;
  width: 370px;
  height: 50px;
  padding-left: 0;
  outline: none;
  border: 1px solid #EDEDED;
  border-radius: 29px;
  transition: 0.2s;
  color: #000000;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.landcomp__banner_main_form .input_item input::placeholder {
  color: #8A8A8A;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
}

.landcomp__banner_main_form .action {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 370px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 40px;
}

.landcomp__banner_main_form .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__banner_main_form .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__banner_territory {
  position: absolute;
  bottom: 210px;
  left: 520px;
  display: flex;
}

.landcomp__banner_territory img {
  width: 12px;
  height: 16px;
  margin-right: 8px;
  margin-top: 1px;
}

.landcomp__banner_territory .ter_text {
  color: #333333;
  line-height: 130%;
}

.landcomp__banner_territory .ter_text .ter_desc {
  font-weight: 500;
  font-size: 16px;
}

.landcomp__banner_territory .ter_text .ter_title {
  font-weight: 900;
  font-size: 16px;
}

.landcomp__banner_territory .ter_text .ter_title br {
  display: none;
}

.landcomp__banner_territory__mob {
  display: none;
  width: 290px;
  margin: 0 auto;
}

.landcomp__banner_territory__mob img {
  width: 12px;
  height: 16px;
  margin-right: 8px;
  margin-top: 1px;
}

.landcomp__banner_territory__mob .ter_text {
  color: #ffffff;
  line-height: 130%;
}

.landcomp__banner_territory__mob .ter_text .ter_desc {
  font-weight: 500;
  font-size: 16px;
}

.landcomp__banner_territory__mob .ter_text .ter_title {
  font-weight: 900;
  font-size: 16px;
}

.landcomp__advantages {
  padding-top: 50px;
  padding-bottom: 100px;
}

.landcomp__advantages_block {
  display: flex;
  justify-content: space-between;
}

.landcomp__advantages_block .item .for_img {
  width: 304px;
  height: 274px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landcomp__advantages_block .item .text {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  width: 315px;
}

.landcomp__advantages .counter {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
  display: none;
}

.landcomp__advantages .counter .now,
.landcomp__advantages .counter .all {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
}

.landcomp__brands {
  padding-bottom: 60px;
}

.landcomp__brands h3 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
}

.landcomp__brands h3 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__brands h3::before {
  position: absolute;
  content: '';
  height: 1px;
  width: 150px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  left: 0;
  top: 22px;
}

.landcomp__brands h3::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 150px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  right: 0;
  top: 22px;
}

.landcomp__brands .slider .item {
  width: 145px;
  height: 85px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: 0.3s;
}

.landcomp__brands .slider .item:hover {
  filter: grayscale(0);
  opacity: 1;
}

.landcomp__cost {
  background: #FAFAFA;
  padding-top: 60px;
  padding-bottom: 105px;
  position: relative;
  z-index: 0;
}

.landcomp__cost::before {
  content: '';
  position: absolute;
  width: 187px;
  height: 187px;
  background: url(../img/cost/left_circle.png) 0 0 / cover no-repeat;
  left: 155px;
  bottom: 30px;
  z-index: -1;
}

.landcomp__cost::after {
  content: '';
  position: absolute;
  width: 107px;
  height: 64px;
  background: url(../img/cost/right_circle.png) 0 0 / cover no-repeat;
  right: 455px;
  bottom: 0;
  z-index: -1;
}

.landcomp__cost .wrapper {
  position: relative;
  z-index: 0;
}

.landcomp__cost .wrapper::before {
  content: '';
  position: absolute;
  width: 310px;
  height: 320px;
  background: url(../img/cost/left.png) 0 0 / cover no-repeat;
  left: -155px;
  top: -55px;
  z-index: -1;
}

.landcomp__cost .wrapper::after {
  content: '';
  position: absolute;
  width: 233px;
  height: 210px;
  background: url(../img/cost/right.png) no-repeat;
  background-size: cover;
  right: -65px;
  top: -10px;
  z-index: -1;
}

.landcomp__cost .wrapper .counter {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
  display: none;
}

.landcomp__cost .wrapper .counter .now,
.landcomp__cost .wrapper .counter .all {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
}

.landcomp__cost h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  margin-bottom: 100px;
}

.landcomp__cost h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__cost_block {
  display: flex;
  flex-wrap: wrap;
}

.landcomp__cost_block .item {
  width: 648px;
  min-width: 648px;
  height: 310px;
  background: #ffffff;
  padding: 30px 50px 30px 30px;
  display: flex;
}

.landcomp__cost_block .item:nth-child(odd) {
  margin-right: 30px;
  margin-bottom: 30px;
}

.landcomp__cost_block .item:nth-child(4) {
  padding: 30px 20px 30px 30px;
}

.landcomp__cost_block .item .for_img {
  width: 190px;
  min-width: 190px;
  height: 250px;
  margin-right: 30px;
}

.landcomp__cost_block .item .desc {
  display: flex;
  flex-direction: column;
}

.landcomp__cost_block .item .desc .title {
  color: #333333;
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  margin-bottom: 15px;
}

.landcomp__cost_block .item .desc .text {
  color: #000000;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
}

.landcomp__cost_block .item .desc button {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 270px;
  height: 50px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin: auto 0 0 0;
}

.landcomp__cost_block .item .desc button:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__cost_block .item .desc button::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__cost_block .item .desc button:after {
  background: url(../img/cost/highlight.png) no-repeat;
}

.landcomp__diagnostics {
  padding-top: 100px;
  padding-bottom: 95px;
}

.landcomp__diagnostics .wrapper {
  position: relative;
}

.landcomp__diagnostics .wrapper::before {
  content: '';
  position: absolute;
  width: 431px;
  height: 431px;
  background: url(../img/diagnostics/circle_left.png) 0 0 / cover no-repeat;
  left: -168px;
  bottom: -81px;
  z-index: -1;
}

.landcomp__diagnostics .wrapper::after {
  content: '';
  position: absolute;
  width: 323px;
  height: 323px;
  background: url(../img/diagnostics/circle_right.png) 0 0 / cover no-repeat;
  top: 35px;
  right: -125px;
  z-index: -1;
}

.landcomp__diagnostics h3 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  margin-bottom: 20px;
}

.landcomp__diagnostics h3 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__diagnostics .desc {
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #333333;
}

.landcomp__diagnostics_block {
  background: url(../img/diagnostics/back.png) no-repeat;
  background-size: cover;
  width: 1326px;
  height: 593px;
  filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.05));
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  padding: 72px 90px 50px 90px;
}

.landcomp__diagnostics_block .img_count {
  position: absolute;
  width: 250px;
  height: 250px;
  left: 48px;
  top: -125px;
  z-index: -1;
  transition: 0.3s;
}

.landcomp__diagnostics_block .img_count.num_1 {
  background: url(../img/diagnostics/counter_1.png) 0 0 / cover no-repeat;
}

.landcomp__diagnostics_block .img_count.num_2 {
  background: url(../img/diagnostics/counter_2.png) 0 0 / cover no-repeat;
}

.landcomp__diagnostics_block .img_count.num_3 {
  background: url(../img/diagnostics/counter_3.png) 0 0 / cover no-repeat;
}

.landcomp__diagnostics_block .img_count.num_4 {
  background: url(../img/diagnostics/counter_4.png) 0 0 / cover no-repeat;
}

.landcomp__diagnostics_block::after {
  content: '';
  position: absolute;
  width: 333px;
  height: 361px;
  background: url(../img/diagnostics/ammeter.png) 0 0 / cover no-repeat;
  top: -250px;
  right: -30px;
  z-index: -1;
}

.landcomp__diagnostics_block .title {
  text-align: center;
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin-bottom: 125px;
}

.landcomp__diagnostics_block .start {
  display: flex;
  justify-content: space-between;
}

.landcomp__diagnostics_block .start.opti {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 360px;
  margin-top: -40px;
  transition: 0.3s;
}

.landcomp__diagnostics_block .start .item_start {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 220px;
  height: 220px;
  cursor: pointer;
}

.landcomp__diagnostics_block .start .item_start:hover .title {
  transition: color 0.2s;
  color: #FF833D;
}

.landcomp__diagnostics_block .start .item_start:hover::before {
  transition: 0.3s;
  background: url(../img/diagnostics/pseudo_before_active.png) 0 0 / cover no-repeat;
}

.landcomp__diagnostics_block .start .item_start:hover .for_img {
  filter: drop-shadow(0 20px 25px rgba(0, 0, 0, 0.2));
}

.landcomp__diagnostics_block .start .item_start:hover::after {
  animation-name: pulse_circle;
  animation-duration: 0.4s;
}

.landcomp__diagnostics_block .start .item_start::before {
  content: '';
  position: absolute;
  width: 220px;
  height: 110px;
  background: url(../img/diagnostics/pseudo_before.png) 0 0 / cover no-repeat;
  left: 0;
  top: 0;
  z-index: -1;
}

.landcomp__diagnostics_block .start .item_start::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  background: red;
  border-radius: 50%;
  left: calc(220px / 2);
  top: 110px;
  z-index: -1;
}

.landcomp__diagnostics_block .start .item_start .for_img {
  width: 196px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landcomp__diagnostics_block .start .item_start .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 126%;
  color: #333333;
  margin: 0;
}

.landcomp__diagnostics_block .start .item {
  width: 25%;
  cursor: pointer;
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 38px;
  position: relative;
}

.landcomp__diagnostics_block .start .item .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 126%;
  color: #333333;
  padding: 0;
  margin: 0;
  width: 200px;
  text-align: left;
}

.landcomp__diagnostics_block .start .item:hover .title {
  transition: color 0.2s;
  color: #FF833D;
}

.landcomp__diagnostics_block .start .item:hover::before {
  background: url(../img/diagnostics/item_active.png) no-repeat;
  background-size: cover;
  transition: 0.3s;
}

.landcomp__diagnostics_block .start .item::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background: url(../img/diagnostics/item_std.png) no-repeat;
  background-size: cover;
  left: 0;
  top: 0;
}

.landcomp__diagnostics .fade-enter {
  opacity: 0;
}

.landcomp__diagnostics .fade-enter-active {
  transition: 0.2s;
}

.landcomp__diagnostics .fade-leave-active {
  transition: 0.2s;
}

.landcomp__diagnostics .fade-leave-to {
  opacity: 0;
}

@keyframes pulse_circle {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 131, 61, 0.4);
  }

  70% {
    box-shadow: 0 0 0 150px rgba(255, 131, 61, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 131, 61, 0);
  }
}

.landcomp__breakdowns {
  padding-top: 50px;
  position: relative;
}

.landcomp__breakdowns::before {
  display: none;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 248px;
  width: 100%;
  height: calc(100% - 248px);
  background: #FAFAFA;
}

.landcomp__breakdowns .wrapper {
  position: relative;
}

.landcomp__breakdowns .wrapper::before {
  content: '';
  position: absolute;
  width: 246px;
  height: 191px;
  background: url(../img/breakdowns/pseudo_before.png) 0 0 / cover no-repeat;
  top: 105px;
  left: -155px;
  z-index: -1;
}

.landcomp__breakdowns .wrapper::after {
  content: '';
  position: absolute;
  width: 323px;
  height: 328px;
  background: url(../img/breakdowns/pseudo_after.png) 0 0 / cover no-repeat;
  top: -85px;
  right: 30px;
  z-index: -1;
}

.landcomp__breakdowns h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  width: 455px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.landcomp__breakdowns h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__breakdowns h2::before {
  position: absolute;
  content: '';
  height: 1px;
  width: 100px;
  background: linear-gradient(164.24deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  top: 25px;
  left: -130px;
}

.landcomp__breakdowns h2::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 100px;
  background: linear-gradient(164.24deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  top: 25px;
  right: -130px;
}

.landcomp__breakdowns .desc {
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #333333;
  margin-bottom: 80px;
}

.landcomp__breakdowns .tabs {
  display: flex;
}

.landcomp__breakdowns .tabs li {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FF833D;
  text-align: center;
  transition: 0.3s;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 60px;
  box-shadow: 0px 8px 24px rgba(255, 131, 61, 0.4);
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  border-radius: 100px 100px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.landcomp__breakdowns .tabs li:hover {
  background: rgba(255, 131, 61, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__breakdowns .tabs li::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.5;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 131, 61, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 131, 61, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 131, 61, 0);
  }
}

.landcomp__breakdowns .tabs li:hover {
  animation: unset;
}

.landcomp__breakdowns .tabs li:after {
  animation-duration: 2.5s;
  background: url(../img/breakdowns/highlight.png) no-repeat;
}

.landcomp__breakdowns .tabs li.active {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 60px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  border-radius: 100px 100px 0 0;
}

.landcomp__breakdowns .tabs li.active:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__breakdowns .tabs li.active::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__breakdowns .tabs li.active:hover {
  animation: unset;
}

.landcomp__breakdowns .tabs li.active:after {
  animation-delay: 2s;
  background: url(../img/breakdowns/highlight.png) no-repeat;
}

.landcomp__breakdowns .tab_container {
  background: #FAFAFA;
  padding: 0 40px 55px 40px;
}

.landcomp__breakdowns .tab_container .show {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 63px;
  font-weight: 700;
  font-size: 16px;
  line-height: 126%;
  color: #333333;
  z-index: 1;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  transition: 0.3s;
}

.landcomp__breakdowns .tab_container .show.less::before {
  top: 10px;
}

.landcomp__breakdowns .tab_container .show.less::after {
  background: url(../img/breakdowns/arrow_up.svg) no-repeat;
  background-size: contain;
  top: -20px;
}

.landcomp__breakdowns .tab_container .show.less:hover {
  color: #FF833D;
}

.landcomp__breakdowns .tab_container .show.less:hover::before {
  top: 0;
}

.landcomp__breakdowns .tab_container .show.less:hover::after {
  top: -30px;
}

.landcomp__breakdowns .tab_container .show::before {
  content: '';
  position: absolute;
  width: 115px;
  height: 20px;
  background: #FFFFFF;
  border-radius: 50px;
  z-index: -1;
  top: -10px;
  transition: 0.3s;
}

.landcomp__breakdowns .tab_container .show::after {
  content: '';
  position: absolute;
  background: url(../img/breakdowns/arrow_down.svg) no-repeat;
  background-size: contain;
  width: 22px;
  height: 11px;
  z-index: -1;
  top: 30px;
  transition: 0.3s;
}

.landcomp__breakdowns .tab_container .show:hover {
  color: #FF833D;
}

.landcomp__breakdowns .tab_container .show:hover::before {
  top: 0;
}

.landcomp__breakdowns .tab_container .show:hover::after {
  top: 40px;
}

.landcomp__breakdowns .tab_container .tab_content {
  display: none;
  flex-wrap: wrap;
}

.landcomp__breakdowns .tab_container .tab_content:first-child {
  display: flex;
}

.landcomp__breakdowns .tab_container .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 380px;
  height: 600px;
  padding: 30px 40px 50px 40px;
  margin-right: 53px;
  margin-top: 40px;
  background: #ffffff;
}

.landcomp__breakdowns .tab_container .item:nth-child(3n) {
  margin-right: 0;
}

.landcomp__breakdowns .tab_container .item .for_img {
  width: 120px;
  height: 128px;
  min-height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.landcomp__breakdowns .tab_container .item .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 106%;
  width: 300px;
  color: #333333;
  margin: 0 auto 0 0;
  margin-bottom: 15px;
}

.landcomp__breakdowns .tab_container .item .text {
  font-weight: 300;
  font-size: 16px;
  line-height: 143%;
  color: #000000;
}

.landcomp__breakdowns .tab_container .item .text.many {
  font-size: 14px;
}

.landcomp__breakdowns .tab_container .item .text p {
  margin-bottom: 15px;
}

.landcomp__breakdowns .tab_container .item .text p:last-child {
  margin-bottom: 0;
}

.landcomp__breakdowns .tab_container .item button {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 50px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin: auto 0 0 0;
}

.landcomp__breakdowns .tab_container .item button:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__breakdowns .tab_container .item button::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__breakdowns .tab_container .item button:after {
  background: url(../img/cost/highlight.png) no-repeat;
}

.landcomp__breakdowns__mob {
  display: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

.landcomp__breakdowns__mob .wrapper {
  padding: 0;
}

.landcomp__breakdowns__mob .flickity-viewport {
  width: 100%;
}

.landcomp__breakdowns__mob .carousel-cell {
  width: 100%;
}

.landcomp__breakdowns__mob .flickity-prev-next-button {
  top: 100%;
  z-index: 300;
}

.landcomp__breakdowns__mob .flickity-prev-next-button.previous {
  left: 15px;
}

.landcomp__breakdowns__mob .flickity-prev-next-button.next {
  right: 25px;
}

.landcomp__breakdowns__mob h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 15px;
}

.landcomp__breakdowns__mob h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__breakdowns__mob .desc {
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #333333;
  margin-bottom: 60px;
  padding: 0 15px;
}

.landcomp__breakdowns__mob .counter {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
  display: block;
  transform: translateY(-40px);
}

.landcomp__breakdowns__mob .counter .now,
.landcomp__breakdowns__mob .counter .all {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
}

.landcomp__breakdowns__mob .item {
  background: transparent;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.landcomp__breakdowns__mob .item:not(:first-child) {
  margin-top: 20px;
}

.landcomp__breakdowns__mob .item .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 126%;
  color: #FF833D;
  text-align: center;
  position: relative;
}

.landcomp__breakdowns__mob .item .slider .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 620px;
  padding: 0 15px;
  margin-right: 53px;
  margin-top: 10px;
  background: #ffffff;
}

.landcomp__breakdowns__mob .item .slider .item .for_img {
  width: 120px;
  height: 128px;
  min-height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.landcomp__breakdowns__mob .item .slider .item .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 106%;
  width: 300px;
  color: #333333;
  margin: 0 auto 25px;
  margin-bottom: 15px;
  box-shadow: none;
  background: none;
}

.landcomp__breakdowns__mob .item .slider .item .text {
  font-weight: 300;
  font-size: 16px;
  line-height: 143%;
  color: #000000;
}

.landcomp__breakdowns__mob .item .slider .item .text.many {
  font-size: 14px;
}

.landcomp__breakdowns__mob .item .slider .item .text p {
  margin-bottom: 15px;
}

.landcomp__breakdowns__mob .item .slider .item .text p:last-child {
  margin-bottom: 0;
}

.landcomp__breakdowns__mob .item .slider .item button {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 290px;
  height: 55px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin: auto 0 0 0;
  margin-bottom: 40px;
}

.landcomp__breakdowns__mob .item .slider .item button:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__breakdowns__mob .item .slider .item button::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__breakdowns__mob .item .slider .item button:after {
  background: url(../img/cost/highlight.png) no-repeat;
}

.landcomp__mac {
  padding-top: 120px;
  padding-bottom: 80px;
}

.landcomp__mac h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  margin-bottom: 90px;
}

.landcomp__mac h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__mac .counter_1,
.landcomp__mac .counter_2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
  display: none;
  transform: translateY(-80px);
}

.landcomp__mac .counter_1 .now,
.landcomp__mac .counter_2 .now,
.landcomp__mac .counter_1 .all,
.landcomp__mac .counter_2 .all {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
}

.landcomp__mac .counter_2 {
  transform: translateY(-40px);
}

.landcomp__mac .models {
  margin-bottom: 90px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.landcomp__mac .models::after {
  content: '';
  position: absolute;
  width: 450px;
  height: 366px;
  background: url(../img/mac/pseudo.png) 0 0 / cover no-repeat;
  top: -325px;
  right: -70px;
}

.landcomp__mac .models .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.landcomp__mac .models .item:hover .text {
  transition: 0.2s;
  color: #FF833D;
}

.landcomp__mac .models .item .for_img {
  width: 200px;
  height: 150px;
}

.landcomp__mac .models .item .text {
  font-weight: 700;
  font-size: 18px;
  line-height: 126%;
  color: #333333;
  text-align: center;
}

.landcomp__mac .malfunctions {
  position: relative;
}

.landcomp__mac .malfunctions::before {
  content: '';
  position: absolute;
  width: 234px;
  height: 255px;
  background: url(../img/mac/malfunctions/pseudo.png) 0 0 / cover no-repeat;
  top: -105px;
  left: 155px;
  z-index: -1;
}

.landcomp__mac .malfunctions .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  text-align: center;
  margin-bottom: 15px;
}

.landcomp__mac .malfunctions .title br {
  display: none;
}

.landcomp__mac .malfunctions .desc {
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  margin-bottom: 50px;
  text-align: center;
}

.landcomp__mac .malfunctions_block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.landcomp__mac .malfunctions_block .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 95px;
  cursor: pointer;
}

.landcomp__mac .malfunctions_block .item:hover .for_img {
  background: #FF833D;
  transition: 0.3s;
}

.landcomp__mac .malfunctions_block .item:hover .for_img::after {
  animation-name: pulse_circle_mac;
  animation-duration: 0.4s;
}

.landcomp__mac .malfunctions_block .item:hover .text {
  transition: color 0.2s;
  color: #FF833D;
}

.landcomp__mac .malfunctions_block .item:nth-child(5n+5) {
  margin-right: 0;
}

.landcomp__mac .malfunctions_block .item:nth-child(-n+5) {
  margin-bottom: 40px;
}

.landcomp__mac .malfunctions_block .item .for_img {
  width: 120px;
  height: 120px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F4F4;
  border-radius: 50%;
  position: relative;
}

.landcomp__mac .malfunctions_block .item .for_img::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  border-radius: 50%;
  left: calc(60 / 2);
  top: 60px;
  z-index: -1;
}

.landcomp__mac .malfunctions_block .item .text {
  width: 196px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 126%;
  color: #333333;
}

@keyframes pulse_circle_mac {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 131, 61, 0.4);
  }

  70% {
    box-shadow: 0 0 0 110px rgba(255, 131, 61, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 131, 61, 0);
  }
}

.landcomp__order {
  margin-bottom: -148px;
  padding-top: 60px;
}

.landcomp__order h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
}

.landcomp__order h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp__order h2::before {
  position: absolute;
  content: '';
  height: 1px;
  width: 150px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  left: 0;
  top: 22px;
}

.landcomp__order h2::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 150px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  right: 0;
  top: 22px;
}

.landcomp__order .one {
  display: flex;
  margin-bottom: -100px;
}

.landcomp__order .one .left img {
  min-width: 1020px;
  transform: translateX(-250px) translateY(-125px);
}

.landcomp__order .one .left img.lg {
  min-width: auto;
  min-width: 821px;
  transform: translateX(-226px) translateY(-90px);
}

.landcomp__order .one .left img.md {
  min-width: auto;
  min-width: 633px;
  transform: translateX(-185px) translateY(-70px);
}

.landcomp__order .one .left img.xs {
  min-width: auto;
  min-width: 290px;
}

.landcomp__order .one .left .for_order {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  width: 286px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-238px) translateX(160px);
}

.landcomp__order .one .left .for_order span {
  color: #FF833D;
  text-transform: uppercase;
}

.landcomp__order .one .left .for_order button {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 370px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 25px;
}

.landcomp__order .one .left .for_order button:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__order .one .left .for_order button::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__order .one .left .for_order::after {
  content: '';
  position: absolute;
  width: 292px;
  height: 242px;
  background: url(../img/order/one/line.png) 0 0 / cover no-repeat;
  left: 204px;
  top: 25px;
  z-index: 2;
}

.landcomp__order .one .right {
  margin-left: -320px;
  padding-top: 80px;
}

.landcomp__order .one .right .title {
  display: flex;
  align-items: center;
  margin-left: -200px;
  margin-bottom: 55px;
}

.landcomp__order .one .right .title .num {
  font-weight: 700;
  font-size: 56px;
  line-height: 126%;
  color: #FF833D;
  margin-right: 30px;
}

.landcomp__order .one .right .title .text {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #333333;
}

.landcomp__order .one .right .main_text {
  position: relative;
}

.landcomp__order .one .right .main_text li {
  font-weight: 300;
  font-size: 18px;
  line-height: 220%;
  color: #000000;
  position: relative;
}

.landcomp__order .one .right .main_text li::before {
  position: absolute;
  content: '';
  width: 13px;
  height: 1px;
  top: 18px;
  left: -40px;
  background: #000000;
}

.landcomp__order .one .right .main_text::before {
  content: '';
  position: absolute;
  width: 828px;
  height: 668px;
  background: url(../img/order/one/back_1.png) 0 0 / cover no-repeat;
  left: -184px;
  top: -235px;
  z-index: -2;
}

.landcomp__order .one .right .optional {
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #000000;
  width: 649px;
  height: 422px;
  background: url(../img/order/one/back_2.png) no-repeat;
  background-size: cover;
  padding-top: 115px;
  padding-left: 173px;
}

.landcomp__order .one .right .optional p {
  width: 327px;
  text-align: center;
}

.landcomp__order .one .right .for_order {
  display: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  width: 286px;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.landcomp__order .one .right .for_order span {
  color: #FF833D;
  text-transform: uppercase;
}

.landcomp__order .one .right .for_order button {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 290px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 25px !important;
  flex-direction: row !important;
}

.landcomp__order .one .right .for_order button:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp__order .one .right .for_order button::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp__order .two {
  display: flex;
  justify-content: space-between;
  margin-bottom: -110px;
}

.landcomp__order .two .left .title {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.landcomp__order .two .left .title .num {
  font-weight: 700;
  font-size: 56px;
  line-height: 126%;
  color: #FF833D;
  margin-right: 30px;
}

.landcomp__order .two .left .title .text {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #333333;
}

.landcomp__order .two .left .main_text {
  position: relative;
}

.landcomp__order .two .left .main_text li {
  font-weight: 300;
  font-size: 18px;
  line-height: 220%;
  color: #000000;
  position: relative;
}

.landcomp__order .two .left .main_text li::before {
  position: absolute;
  content: '';
  width: 13px;
  height: 1px;
  top: 18px;
  left: -40px;
  background: #000000;
}

.landcomp__order .two .right img {
  min-width: 648px;
  height: 430px;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
}

.landcomp__order .two .right .optional {
  z-index: -1;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #000000;
  text-align: left;
  width: 630px;
  height: 356px;
  background: url(../img/order/two/back.png) no-repeat;
  background-size: cover;
  padding-top: 175px;
  padding-left: 185px;
  transform: translateY(-140px) translateX(-185px);
}

.landcomp__order .two .right .optional span {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #919191;
}

.landcomp__order .three {
  display: flex;
  margin-bottom: 70px;
}

.landcomp__order .three .left .desc {
  padding-left: 80px;
}

.landcomp__order .three .left .desc .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin-bottom: 25px;
}

.landcomp__order .three .left .desc .text {
  width: 454px;
}

.landcomp__order .three .left .desc .text p {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}

.landcomp__order .three .left .desc .text p:first-child {
  margin-bottom: 45px;
}

.landcomp__order .three .left .desc .text p span {
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  color: #FF833D;
  margin-right: 15px;
}

.landcomp__order .three .left .optional {
  z-index: -1;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #000000;
  text-align: center;
  width: 665px;
  height: 408px;
  background: url(../img/order/three/back.png) no-repeat;
  background-size: cover;
  padding-top: 155px;
  padding-left: 174px;
  transform: translateX(-35px);
}

.landcomp__order .three .left .optional p {
  width: 400px;
  text-align: center;
}

.landcomp__order .three .left .optional::after {
  content: '';
  position: absolute;
  width: 207px;
  height: 227px;
  background: url(../img/order/three/line.png) 0 0 / cover no-repeat;
  right: -300px;
  top: 265px;
  z-index: 2;
}

.landcomp__order .three .right {
  margin: 0 0 0 auto;
}

.landcomp__order .three .right .block {
  display: flex;
}

.landcomp__order .three .right .block .for_img {
  width: 310px;
  min-width: 310px;
  height: 438px;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
}

.landcomp__order .three .right .block .for_img:first-child {
  margin-right: 30px;
}

.landcomp__order .four {
  display: flex;
}

.landcomp__order .four .left {
  min-width: 469px;
  transform: translateY(100px);
}

.landcomp__order .four .left img.md {
  min-width: 290px;
  width: 290px;
}

.landcomp__order .four .right {
  margin-top: 20px;
}

.landcomp__order .four .right .title {
  display: flex;
  align-items: center;
  transform: translateX(-171px);
}

.landcomp__order .four .right .title .num {
  font-weight: 700;
  font-size: 56px;
  line-height: 126%;
  color: #FF833D;
  margin-right: 30px;
}

.landcomp__order .four .right .title .text {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #333333;
}

.landcomp__order .four .right .main_text {
  position: relative;
  margin-left: 100px;
  margin-top: 50px;
}

.landcomp__order .four .right .main_text li {
  font-weight: 300;
  font-size: 18px;
  line-height: 220%;
  color: #000000;
  position: relative;
}

.landcomp__order .four .right .main_text li::before {
  position: absolute;
  content: '';
  width: 13px;
  height: 1px;
  top: 18px;
  left: -40px;
  background: #000000;
}

.landcomp__order .four .right .main_text::before {
  content: '';
  position: absolute;
  width: 828px;
  height: 668px;
  background: url(../img/order/four/back_1.png) 0 0 / cover no-repeat;
  left: -572px;
  top: -180px;
  z-index: -2;
}

.landcomp__order .four .right .optional {
  z-index: -1;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #000000;
  text-align: center;
  width: 497px;
  height: 403px;
  background: url(../img/order/four/back_2.png) no-repeat;
  background-size: cover;
  padding-top: 89px;
  padding-left: 20px;
  transform: translateX(250px) translateY(-72px);
  z-index: 3;
}

.landcomp__order .four .right .book {
  display: none;
  position: relative;
  z-index: 5;
  width: 320px;
  margin: 0 auto !important;
  margin-bottom: -150px !important;
}

.landcomp_bid {
  width: 100%;
  height: 800px;
  background: url(../img/bid/back.jpg) no-repeat;
  background-size: cover;
}

.landcomp_bid .wrapper {
  display: flex;
  justify-content: space-between;
}

.landcomp_bid__left {
  padding-top: 130px;
  padding-left: 60px;
  margin-right: 135px;
  position: relative;
}

.landcomp_bid__left::after {
  content: "";
  position: absolute;
  background: url(../img/bid/clock.png) no-repeat;
  background-size: cover;
  width: 288px;
  height: 288px;
  top: 13px;
  right: -244px;
  z-index: 2;
}

.landcomp_bid__left .desc {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FAFAFA;
  margin-bottom: 60px;
}

.landcomp_bid__left .landcomp_bid__form .error_name {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

.landcomp_bid__left .landcomp_bid__form .error_name.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

.landcomp_bid__left .landcomp_bid__form .error_tel {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

.landcomp_bid__left .landcomp_bid__form .error_tel.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

.landcomp_bid__left .landcomp_bid__form .input_item:first-child {
  margin-bottom: 40px;
}

.landcomp_bid__left .landcomp_bid__form .input_item input {
  box-sizing: border-box;
  width: 452px;
  height: 50px;
  padding-left: 0;
  outline: none;
  border: 1px solid #EDEDED;
  border-radius: 29px;
  transition: 0.2s;
  color: #000000;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.landcomp_bid__left .landcomp_bid__form .input_item input::placeholder {
  color: #8A8A8A;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
}

.landcomp_bid__left .landcomp_bid__form .action {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 452px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 60px;
}

.landcomp_bid__left .landcomp_bid__form .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp_bid__left .landcomp_bid__form .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp_bid__right {
  margin-top: 90px;
  width: 681px;
  height: 600px;
  background: rgba(34, 34, 34, 0.6);
  border-radius: 38px;
  position: relative;
  padding-top: 56px;
  padding-left: 74px;
}

.landcomp_bid__right::before {
  content: "";
  position: absolute;
  background: url(../img/bid/back.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  filter: blur(7px) brightness(0.6);
}

.landcomp_bid__right::after {
  content: "";
  position: absolute;
  background: url(../img/bid/border.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  left: -45px;
  top: 35px;
}

.landcomp_bid__right .title {
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #ffffff;
  padding-bottom: 60px;
  width: 410px;
}

.landcomp_bid__right ul {
  position: relative;
  z-index: 2;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  width: 487px;
}

.landcomp_bid__right ul li {
  position: relative;
  margin-bottom: 30px;
  padding-left: 68px;
}

.landcomp_bid__right ul li:last-child {
  margin-bottom: 0;
}

.landcomp_bid__right ul li:nth-child(2)::before {
  background: url(../img/bid/2.svg) no-repeat;
  background-size: cover;
}

.landcomp_bid__right ul li:nth-child(3)::before {
  background: url(../img/bid/3.svg) no-repeat;
  background-size: cover;
}

.landcomp_bid__right ul li:nth-child(4)::before {
  background: url(../img/bid/4.svg) no-repeat;
  background-size: cover;
}

.landcomp_bid__right ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: url(../img/bid/1.svg) no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.landcomp_repair .wrapper {
  display: flex;
  justify-content: space-between;
}

.landcomp_repair__left {
  padding-top: 72px;
}

.landcomp_repair__left h3 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  text-align: left;
  margin-bottom: 30px;
}

.landcomp_repair__left h3 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp_repair__left .desc {
  font-weight: 300;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin-bottom: 100px;
}

.landcomp_repair__left .text {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  width: calc(475px + 120px);
  padding-left: 120px;
  position: relative;
}

.landcomp_repair__left .text::before {
  content: "";
  position: absolute;
  background: url(../img/repair/back_left.png) no-repeat;
  background-size: cover;
  width: 898px;
  height: 633px;
  top: -90px;
  left: 0;
  z-index: -1;
}

.landcomp_repair__left .text::after {
  display: none;
  content: "";
  position: absolute;
  background: url(../img/repair/right_quot.png) no-repeat;
  background-size: cover;
  width: 49px;
  height: 33px;
  right: 0;
  bottom: -53px;
}

.landcomp_repair__left .text p:first-child {
  margin-bottom: 15px;
}

.landcomp_repair__left .text p:nth-child(2) {
  margin-bottom: 30px;
}

.landcomp_repair__left .text p:nth-child(3) {
  margin-bottom: 40px;
}

.landcomp_repair__left .name {
  font-size: 16px;
  line-height: 180%;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.landcomp_repair__left .desc_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #919191;
  width: 308px;
}

.landcomp_repair__right {
  padding-top: 34px;
  position: relative;
}

.landcomp_repair__right img {
  min-width: auto;
}

.landcomp_repair__right img.lg {
  min-width: 441px;
  transform: translateY(170px);
}

.landcomp_repair__right img.md {
  min-width: 293px;
  height: 510px;
  transform: translateY(187px) translateX(30px);
}

.landcomp_repair__right img.xs {
  min-width: auto;
  margin: 0 auto;
}

.landcomp_repair__right::before {
  content: "";
  position: absolute;
  background: url(../img/repair/pseudo_1.png) no-repeat;
  background-size: cover;
  width: 312px;
  height: 253px;
  top: -70px;
  left: -170px;
  z-index: 1;
}

.landcomp_repair__right::after {
  content: "";
  position: absolute;
  background: url(../img/repair/pseudo_2.png) no-repeat;
  background-size: cover;
  width: 267px;
  height: 316px;
  top: 70px;
  right: -120px;
  z-index: 1;
}

.landcomp_repair__right.other::before {
  content: "";
  position: absolute;
  background: url(../img/repair/pseudo_1_2.png) no-repeat;
  background-size: cover;
  width: 312px;
  height: 253px;
  top: -70px;
  left: -170px;
  z-index: 1;
}

.landcomp_repair__right.other::after {
  content: "";
  position: absolute;
  background: url(../img/repair/pseudo_2_2.png) no-repeat;
  background-size: cover;
  width: 267px;
  height: 316px;
  top: 70px;
  right: -120px;
  z-index: 1;
}

.landcomp_repair_opti {
  background: #FAFAFA;
}

.landcomp_repair_opti .wrapper {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 50px 115px;
}

.landcomp_repair_opti .item {
  width: 300px;
  position: relative;
}

.landcomp_repair_opti .item::before {
  content: "";
  position: absolute;
  background: url(../img/repair_opti/back.png) no-repeat;
  background-size: cover;
  width: 249px;
  height: 148px;
  top: -27px;
  left: -10px;
}

.landcomp_repair_opti .item p {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  position: relative;
}

.landcomp_repair_opti .item p::before {
  content: "";
  position: absolute;
  background: url(../img/repair_opti/badge.png) no-repeat;
  background-size: cover;
  width: 47px;
  height: 64px;
  top: -13px;
  left: calc(-47px - 20px);
}

.landcomp_other_tasks {
  padding-top: 80px;
  padding-bottom: 60px;
}

.landcomp_other_tasks .counter {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
  transform: translateY(0);
  display: none;
}

.landcomp_other_tasks .counter .now,
.landcomp_other_tasks .counter .all {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
}

.landcomp_other_tasks h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
}

.landcomp_other_tasks h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp_other_tasks h2::before {
  position: absolute;
  content: '';
  height: 1px;
  width: 290px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  left: 0;
  top: 22px;
}

.landcomp_other_tasks h2::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 290px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  right: 0;
  top: 22px;
}

.landcomp_other_tasks__block .item {
  display: flex;
  width: 100%;
}

.landcomp_other_tasks__block .item .left {
  margin-right: 80px;
  margin-left: 85px;
}

.landcomp_other_tasks__block .item .left .for_img {
  min-width: 450px;
  height: 369px;
  margin-bottom: 40px;
}

.landcomp_other_tasks__block .item .left .data {
  padding-left: 30px;
}

.landcomp_other_tasks__block .item .left .data .tittle_md {
  display: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 126%;
  color: #333333;
  margin-bottom: 13px;
  width: 256px;
}

.landcomp_other_tasks__block .item .left .data .action {
  display: none;
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 55px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 25px;
}

.landcomp_other_tasks__block .item .left .data .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp_other_tasks__block .item .left .data .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp_other_tasks__block .item .left .data li {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 20px;
  position: relative;
}

.landcomp_other_tasks__block .item .left .data li:last-child {
  margin-bottom: 0;
}

.landcomp_other_tasks__block .item .left .data li::before {
  content: "";
  position: absolute;
  background: #FF833D;
  border-radius: 50%;
  background-size: cover;
  width: 6px;
  height: 6px;
  top: 6px;
  left: -30px;
}

.landcomp_other_tasks__block .item .left .data li span {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

.landcomp_other_tasks__block .item .right {
  height: 369px;
}

.landcomp_other_tasks__block .item .right .text {
  height: 100%;
  width: 690px;
}

.landcomp_other_tasks__block .item .right .text .title {
  font-weight: 600;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin-bottom: 25px;
}

.landcomp_other_tasks__block .item .right .text ul {
  margin: 0 0 auto 0;
}

.landcomp_other_tasks__block .item .right .text ul li {
  font-weight: 300;
  font-size: 16px;
  line-height: 170%;
  color: #000000;
}

.landcomp_other_tasks__block .item .right .text p {
  font-weight: 300;
  font-size: 16px;
  line-height: 170%;
  color: #000000;
  margin-top: 20px;
}

.landcomp_other_tasks__block .item .right .action {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 452px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 65px;
}

.landcomp_other_tasks__block .item .right .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp_other_tasks__block .item .right .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.flickity-button {
  background: none;
  width: unset;
  height: unset;
}

.flickity-button:hover {
  background: none;
}

.flickity-button:hover svg circle {
  fill: #FFF859;
  transition: 0.3s;
}

.flickity-button:hover.flickity-prev-next-button {
  filter: drop-shadow(0px 15px 24px rgba(255, 248, 89, 0.4));
}

.flickity-prev-next-button {
  border-radius: 0;
  width: 66px;
  height: 50px;
  top: 40%;
  transition: 0.3s;
  filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.2));
}

.flickity-button-icon {
  display: none;
}

.flickity-prev-next-button.previous {
  left: -95px;
}

.flickity-prev-next-button.next {
  right: -95px;
}

.landcomp_questions {
  padding-top: 60px;
  padding-bottom: 100px;
}

.landcomp_questions h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
  text-align: left;
  margin-bottom: 60px;
}

.landcomp_questions h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp_questions__block {
  display: flex;
}

.landcomp_questions__block .left .items {
  width: 758px;
  min-width: 758px;
  margin-right: 30px;
}

.landcomp_questions__block .left .items .item {
  background: transparent;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.landcomp_questions__block .left .items .item:not(:first-child) {
  margin-top: 20px;
}

.landcomp_questions__block .left .items .item .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 126%;
  color: #404040;
  background: #FFFFFF url(../img/questions/quest_back.png) no-repeat;
  background-size: cover;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
  border-radius: 65px;
  padding: 0 45px;
  height: 80px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.landcomp_questions__block .left .items .item .title:hover {
  background: #FF833D;
  background-size: cover;
  color: #FFFFFF;
  box-shadow: 0px 30px 40px rgba(255, 131, 61, 0.2);
  transition: background 0.3s, color 0.1s;
}

.landcomp_questions__block .left .items .item .title.active {
  color: #FFFFFF;
  background: #FF833D;
  background-size: cover;
  box-shadow: none;
}

.landcomp_questions__block .left .items .item .title.active::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/questions/quest_back_active.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

.landcomp_questions__block .left .items .item .text {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  overflow-y: auto;
  display: none;
  padding: 30px 40px;
}

.landcomp_questions__block .right {
  margin-left: 135px;
  position: relative;
}

.landcomp_questions__block .right::before {
  content: "";
  position: absolute;
  background: url(../img/questions/back.png) no-repeat;
  background-size: cover;
  width: 1202px;
  height: 1167px;
  top: -291px;
  left: -281px;
  z-index: -1;
}

.landcomp_questions__block .right .block .title {
  font-weight: 300;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin-bottom: 33px;
}

.landcomp_questions__block .right .block .master {
  display: flex;
  align-items: flex-end;
}

.landcomp_questions__block .right .block .master .for_img {
  width: 237px;
  height: 237px;
  margin-right: 20px;
}

.landcomp_questions__block .right .block .master .text {
  padding-bottom: 20px;
}

.landcomp_questions__block .right .block .master .text .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #000000;
  margin-bottom: 15px;
}

.landcomp_questions__block .right .block .master .text .desc {
  width: 218px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #919191;
}

.landcomp_questions__block .right .block .action {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 452px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 80px;
}

.landcomp_questions__block .right .block .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp_questions__block .right .block .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

.landcomp_certificates {
  padding-bottom: 100px;
}

.landcomp_certificates .counter {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  margin: 0 auto;
  text-align: center;
  margin-top: 28px;
  transform: translateY(-10px);
  display: none;
}

.landcomp_certificates .counter .now,
.landcomp_certificates .counter .all {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
}

.landcomp_certificates h2 {
  color: #333333;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  position: relative;
  margin-bottom: 50px;
}

.landcomp_certificates h2 span {
  font-weight: 700;
  font-size: 36px;
  line-height: 126%;
  color: #FF833D;
}

.landcomp_certificates h2::before {
  position: absolute;
  content: '';
  height: 1px;
  width: 210px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  left: 0;
  top: 22px;
}

.landcomp_certificates h2::after {
  position: absolute;
  content: '';
  height: 1px;
  width: 210px;
  background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
  border-radius: 50px;
  right: 0;
  top: 22px;
}

.landcomp_certificates__block {
  position: relative;
}

.landcomp_certificates__block::before {
  content: "";
  position: absolute;
  background: url(../img/certificates/back_1.png) no-repeat;
  background-size: cover;
  width: 375px;
  height: 887px;
  top: 0;
  left: -150px;
  z-index: -1;
}

.landcomp_certificates__block::after {
  content: "";
  position: absolute;
  background: url(../img/certificates/back_2.png) no-repeat;
  background-size: cover;
  width: 422px;
  height: 242px;
  top: 0;
  right: -135px;
  z-index: -1;
}

.landcomp_certificates__block .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 934px;
  margin: 0 auto;
}

.landcomp_certificates__block .items .item {
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.14);
  width: 292px;
  height: 418px;
  margin-right: 29px;
  position: relative;
  z-index: 2;
}

.landcomp_certificates__block .items .item:nth-child(3n) {
  margin-right: 0;
}

.landcomp_certificates__block .items .item:nth-child(-n+3) {
  margin-bottom: 30px;
}

.landcomp_footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -380px;
  background: #FAFAFA;
  height: 380px;
}

.landcomp_footer__block {
  display: flex;
  align-items: flex-start;
  padding: 24px 0;
}

.landcomp_footer__block .logo {
  display: flex;
  align-items: center;
  color: #333333;
  cursor: default;
}

.landcomp_footer__block .logo img {
  width: 83px;
  height: 72px;
  margin-right: 12px;
}

.landcomp_footer__block .logo .title {
  font-weight: 900;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0.01em;
}

.landcomp_footer__block .logo .desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.03em;
  display: block;
  width: 244px;
}

.landcomp_footer__block .other_specializations {
  margin: 0 auto;
  padding-top: 15px;
}

.landcomp_footer__block .other_specializations .links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ffffff;
  border-radius: 30px 0 0 30px;
  padding: 10px 20px 10px;
}

.landcomp_footer__block .other_specializations .links a {
  color: #FF833D;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 292%;
}

.landcomp_footer__block .other_specializations .links a:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.landcomp_footer__block .time {
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  margin-right: 150px;
  margin: 0 50px 0 auto;
  padding-top: 5px;
}

.landcomp_footer__block .time img {
  margin-right: 27px;
}

.landcomp_footer__block .tel {
  display: none;
  align-items: center;
  padding: 0;
  margin: 0;
  padding-top: 10px;
  margin-right: 50px;
  display: flex;
}

.landcomp_footer__block .tel:hover .title {
  transition: 0.3s;
  color: #FF833D;
}

.landcomp_footer__block .tel img {
  margin-right: 20px;
}

.landcomp_footer__block .tel .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
}

.landcomp_footer__block .tel:hover .title {
  transition: 0.3s;
  color: #FF833D;
}

.landcomp_footer__block .tel img {
  margin-right: 20px;
}

.landcomp_footer__block .tel .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #333333;
}

.landcomp_footer__block .tel::before {
  display: none;
}

#modal_accepted {
  background: url(../img/optional/accepted.jpg) no-repeat;
  background-size: cover;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
  width: 482px;
  height: 286px;
  overflow: hidden;
  position: relative;
  padding: 61px 94px 0 !important;
  display: none;
}

#modal_accepted .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  text-align: center;
  margin-bottom: 15px;
}

#modal_accepted .desc {
  font-weight: 300;
  font-size: 20px;
  line-height: 126%;
  color: #333333;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 290px;
}

#modal_accepted.fancybox-content {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 30px;
}

#modal_accepted .fancybox-button svg {
  margin-top: 15px;
  margin-right: 15px;
  width: 16px;
  height: 16px;
}

#modal_accepted .fancybox-button svg * {
  fill: #333333;
}

#modal_call {
  background: url(../img/optional/call.jpg) no-repeat;
  background-size: cover;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
  width: 482px;
  height: 581px;
  overflow: hidden;
  position: relative;
  padding: 61px 94px 0 !important;
  display: none;
}

#modal_call .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  text-align: center;
  margin-bottom: 48px;
}

#modal_call form .error_name {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

#modal_call form .error_name.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

#modal_call form .error_tel {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

#modal_call form .error_tel.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

#modal_call form .input_item:first-child {
  margin-bottom: 30px;
}

#modal_call form .input_item input {
  box-sizing: border-box;
  width: 290px;
  height: 50px;
  padding-left: 0;
  outline: none;
  border: 1px solid #EDEDED;
  border-radius: 29px;
  transition: 0.2s;
  color: #000000;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

#modal_call form .input_item input::placeholder {
  color: #8A8A8A;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
}

#modal_call form .action {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 290px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 50px;
}

#modal_call form .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

#modal_call form .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

#modal_call.fancybox-content {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 30px;
}

#modal_call .fancybox-button svg {
  margin-top: 15px;
  margin-right: 15px;
  width: 16px;
  height: 16px;
}

#modal_call .fancybox-button svg * {
  fill: #333333;
}

#modal_call_sale {
  background: url(../img/optional/call_sale.jpg) no-repeat;
  background-size: cover;
  box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
  width: 482px;
  height: 581px;
  overflow: hidden;
  position: relative;
  padding: 61px 94px 0 !important;
  display: none;
}

#modal_call_sale .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 126%;
  color: #333333;
  text-align: center;
  margin-bottom: 48px;
}

#modal_call_sale form .error_name {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

#modal_call_sale form .error_name.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

#modal_call_sale form .error_tel {
  display: none;
  opacity: 0;
  position: absolute;
  color: #FF5A5A;
  font-size: 13px;
}

#modal_call_sale form .error_tel.active {
  display: flex;
  opacity: 1;
  transition: 0.3s;
}

#modal_call_sale form .input_item:first-child {
  margin-bottom: 30px;
}

#modal_call_sale form .input_item input {
  box-sizing: border-box;
  width: 290px;
  height: 50px;
  padding-left: 0;
  outline: none;
  border: 1px solid #EDEDED;
  border-radius: 29px;
  transition: 0.2s;
  color: #000000;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

#modal_call_sale form .input_item input::placeholder {
  color: #8A8A8A;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
}

#modal_call_sale form .action {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 290px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin-top: 50px;
}

#modal_call_sale form .action:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

#modal_call_sale form .action::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

#modal_call_sale.fancybox-content {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 30px;
}

#modal_call_sale .fancybox-button svg {
  margin-top: 15px;
  margin-right: 15px;
  width: 16px;
  height: 16px;
}

#modal_call_sale .fancybox-button svg * {
  fill: #333333;
}

.landcomp_404 {
  height: 100vh;
  background: url(../img/404/back.jpg) no-repeat;
  background-size: cover;
  padding-top: 15vh;
}

.landcomp_404 img {
  display: none;
  margin: 0 auto;
}

.landcomp_404 img.lg_404 {
  display: block;
}

.landcomp_404 .text {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 145%;
  color: #4F4F4F;
  text-align: center;
  margin-top: 30px;
}

.landcomp_404 a {
  font-family: Museo Cyrl, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #FFF859;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  width: 290px;
  height: 70px;
  box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  margin: 0 auto;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landcomp_404 a:hover {
  background: rgba(255, 248, 89, 0.8);
  animation-name: pulse;
  animation-duration: 1s;
  box-shadow: none;
}

.landcomp_404 a::after {
  display: block;
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/main/highlight.png) no-repeat;
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0.7;
  transition: all 0.1s;
  animation-name: slideme;
  animation-duration: 3s;
  animation-delay: 0.05s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -229px;
  }

  30% {
    left: 110%;
  }

  100% {
    left: 110%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {
    padding-left: 34px;
    padding-right: 34px;
  }

  .wrapper {
    max-width: 1080px;
  }

  .debug > div {
    padding-left: 34px;
    padding-right: 34px;
  }

  .debug > div {
    padding-left: 34px;
    padding-right: 34px;
  }

  .debug > div {
    max-width: 1080px;
  }

  .landcomp__header nav a {
    font-size: 13px;
    line-height: 16px;
    margin-right: 27px;
    padding: 13px 0 12px 0;
  }

  .landcomp__banner {
    background: none;
  }

  .landcomp__banner::after {
    content: '';
    position: absolute;
    width: 1093px;
    height: 624px;
    background: url(../img/main/left_back_lg.png) no-repeat;
    background-size: cover;
    left: -400px;
    bottom: 0;
    z-index: -1;
  }

  .landcomp__banner::before {
    content: '';
    position: absolute;
    width: 813px;
    height: 557px;
    background: url(../img/main/right_back_lg.jpg) no-repeat;
    background-size: cover;
    right: -200px;
    bottom: 0;
    z-index: -1;
  }

  .landcomp__banner.other {
    background: url(../img/main/back_main_other_lg.jpg) no-repeat;
    background-size: cover;
  }

  .landcomp__banner.other::before,
  .landcomp__banner.other::after {
    display: none;
  }

  .landcomp__banner_top .time {
    margin-right: 30px;
  }

  .landcomp__banner_top .time img {
    margin-right: 10px;
  }

  .landcomp__banner_top .addr {
    width: 240px;
  }

  .landcomp__banner_top .tel img {
    margin-right: 10px;
  }

  .landcomp__banner_top .tel .title {
    font-size: 16px;
  }

  .landcomp__banner_main h1 {
    font-size: 30px;
  }

  .landcomp__banner_main h1 span {
    font-size: 30px;
  }

  .landcomp__banner_territory {
    left: 450px;
    bottom: 180px;
  }

  .landcomp__banner_territory .ter_text .ter_desc {
    line-height: 180%;
  }

  .landcomp__banner_territory .ter_text .ter_title {
    line-height: 180%;
  }

  .landcomp__banner_territory .ter_text .ter_title br {
    display: block;
  }

  .landcomp__advantages_block .item .for_img {
    width: 230px;
    height: 200px;
  }

  .landcomp__advantages_block .item .text {
    font-size: 16px;
    width: 230px;
  }

  .landcomp__brands h3 {
    font-size: 28px;
  }

  .landcomp__brands h3 span {
    font-size: 28px;
  }

  .landcomp__brands h3::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 110px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp__brands h3::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 110px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp__cost {
    padding-bottom: 65px;
  }

  .landcomp__cost::before {
    left: -50px;
    bottom: 20px;
  }

  .landcomp__cost::after {
    background: url(../img/cost/left_circle.png) 0 0 / cover no-repeat;
    width: 107px;
    height: 107px;
    right: 0;
    bottom: 40px;
  }

  .landcomp__cost .wrapper::before {
    background: url(../img/cost/left_lg.png) no-repeat;
    background-size: cover;
    width: 228px;
    height: 276px;
    left: -80px;
  }

  .landcomp__cost .wrapper::after {
    background: url(../img/cost/right_lg.png) no-repeat;
    background-size: cover;
    width: 256px;
    height: 273px;
    right: -40px;
    top: -30px;
  }

  .landcomp__cost h2 {
    font-size: 28px;
  }

  .landcomp__cost h2 span {
    font-size: 28px;
  }

  .landcomp__cost h2 {
    margin-bottom: 70px;
  }

  .landcomp__cost_block .item {
    width: 496px;
    min-width: 496px;
    height: 285px;
  }

  .landcomp__cost_block .item:nth-child(odd) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .landcomp__cost_block .item .for_img {
    width: 145px;
    min-width: 145px;
    height: 190px;
  }

  .landcomp__cost_block .item .desc .title {
    font-size: 20px;
    line-height: 22px;
  }

  .landcomp__cost_block .item .desc .text {
    font-size: 14px;
  }

  .landcomp__cost_block .item .desc button {
    width: 205px;
    height: 40px;
  }

  .landcomp__diagnostics {
    padding-top: 65px;
  }

  .landcomp__diagnostics .wrapper::after {
    top: 39px;
    right: -22px;
  }

  .landcomp__diagnostics h3 {
    font-size: 28px;
  }

  .landcomp__diagnostics h3 span {
    font-size: 28px;
  }

  .landcomp__diagnostics_block {
    background: url(../img/diagnostics/back_lg.png) no-repeat;
    background-size: cover;
    width: 1012px;
  }

  .landcomp__diagnostics_block::after {
    top: -235px;
    right: -97px;
  }

  .landcomp__diagnostics_block .start .item_start {
    width: 170px;
    height: 170px;
  }

  .landcomp__diagnostics_block .start .item_start::before {
    width: 170px;
    height: 85px;
  }

  .landcomp__diagnostics_block .start .item_start::after {
    left: calc(170px / 2);
    top: 85px;
  }

  .landcomp__diagnostics_block .start .item_start .for_img {
    width: 160px;
    height: 120px;
  }

  .landcomp__diagnostics_block .start .item .title {
    font-size: 16px;
    width: 160px;
  }

  .landcomp__breakdowns .wrapper::before {
    top: 62px;
    left: -99px;
  }

  .landcomp__breakdowns .wrapper::after {
    background: url(../img/breakdowns/pseudo_after_lg.png) 0 0 / cover no-repeat;
    width: 341px;
    height: 328px;
    right: -72px;
  }

  .landcomp__breakdowns h2 {
    font-size: 28px;
  }

  .landcomp__breakdowns h2 span {
    font-size: 28px;
  }

  .landcomp__breakdowns .tab_container .item {
    width: 456px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .landcomp__breakdowns .tab_container .item:nth-child(odd),
  .landcomp__breakdowns .tab_container .item:nth-child(even) {
    margin-right: 20px;
  }

  .landcomp__breakdowns .tab_container .item:nth-child(-n+2) {
    margin-top: 30px;
  }

  .landcomp__breakdowns .tab_container .item:nth-child(2n) {
    margin-right: 0;
  }

  .landcomp__breakdowns__mob h2 {
    font-size: 28px;
  }

  .landcomp__breakdowns__mob h2 span {
    font-size: 28px;
  }

  .landcomp__mac {
    padding-top: 70px;
    padding-bottom: 60px;
  }

  .landcomp__mac h2 {
    font-size: 28px;
  }

  .landcomp__mac h2 span {
    font-size: 28px;
  }

  .landcomp__mac .models::after {
    background: url(../img/mac/pseudo_lg.png) 0 0 / cover no-repeat;
    width: 305px;
    height: 254px;
    top: -220px;
    right: -40px;
  }

  .landcomp__mac .models .item .for_img {
    width: 160px;
    height: 110px;
  }

  .landcomp__mac .models .item .text {
    font-size: 16px;
  }

  .landcomp__mac .malfunctions::before {
    left: -10px;
  }

  .landcomp__mac .malfunctions_block .item {
    margin-right: 23px;
  }

  .landcomp__mac .malfunctions_block .item .for_img {
    width: 100px;
    height: 100px;
  }

  .landcomp__mac .malfunctions_block .item .for_img::after {
    top: 50px;
  }

  .landcomp__mac .malfunctions_block .item .text {
    width: 150px;
    font-size: 16px;
    line-height: 126%;
  }

  .landcomp__order {
    padding-top: 50px;
  }

  .landcomp__order h2 {
    font-size: 28px;
  }

  .landcomp__order h2 span {
    font-size: 28px;
  }

  .landcomp__order h2::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 110px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp__order h2::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 110px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp__order .one {
    margin-bottom: -10px;
  }

  .landcomp__order .one .left .for_order {
    transform: translateY(-180px) translateX(80px);
  }

  .landcomp__order .one .left .for_order::after {
    left: 105px;
    top: 64px;
  }

  .landcomp__order .one .right {
    padding-top: 30px;
  }

  .landcomp__order .one .right .title {
    margin-bottom: 20px;
  }

  .landcomp__order .one .right .main_text li {
    line-height: 200%;
  }

  .landcomp__order .one .right .optional {
    transform: translateX(-70px) translateY(-40px);
  }

  .landcomp__order .two .left .main_text li {
    line-height: 200%;
  }

  .landcomp__order .two .left .main_text {
    transform: translateX(40px);
  }

  .landcomp__order .two .left .main_text br {
    display: none;
  }

  .landcomp__order .two .right img {
    min-width: 520px;
    height: 345px;
    display: block;
    margin: 0 0 0 auto;
  }

  .landcomp__order .two .right .optional {
    transform: translateY(-145px) translateX(14px);
    padding-left: 165px;
  }

  .landcomp__order .three {
    margin-bottom: 0;
  }

  .landcomp__order .three .left .desc {
    padding-left: 0;
  }

  .landcomp__order .three .left .optional::after {
    top: 140px;
    right: -220px;
  }

  .landcomp__order .three .right {
    margin-left: -173px;
  }

  .landcomp__order .three .right .block .for_img {
    width: 247px;
    min-width: 247px;
    height: 349px;
  }

  .landcomp__order .three .right .block .for_img:first-child {
    margin-right: 23px;
  }

  .landcomp__order .four {
    padding-bottom: 65px;
  }

  .landcomp__order .four .right .title {
    width: 630px;
  }

  .landcomp__order .four .right .main_text li {
    line-height: 200%;
  }

  .landcomp__order .four .right .main_text {
    margin-left: 80px;
  }

  .landcomp__order .four .right .optional {
    transform: translateX(23px) translateY(-42px);
  }

  .landcomp_bid__left {
    padding-left: 0;
    margin-right: 60px;
  }

  .landcomp_bid__left::after {
    background: url(../img/bid/clock_lg.png) no-repeat;
    background-size: cover;
    width: 248px;
    height: 248px;
    top: 20px;
    right: -140px;
  }

  .landcomp_bid__left .desc {
    font-size: 28px;
    margin-bottom: 50px;
  }

  .landcomp_bid__left .landcomp_bid__form .input_item input {
    box-sizing: border-box;
    width: 400px;
    height: 50px;
    padding-left: 0;
    outline: none;
    border: 1px solid #EDEDED;
    border-radius: 29px;
    transition: 0.2s;
    color: #000000;
    font-weight: 500;
    line-height: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
  }

  .landcomp_bid__left .landcomp_bid__form .input_item input::placeholder {
    color: #8A8A8A;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
  }

  .landcomp_bid__left .landcomp_bid__form .action {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 70px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  }

  .landcomp_bid__left .landcomp_bid__form .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp_bid__left .landcomp_bid__form .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp_bid__right {
    width: 552px;
    padding-left: 30px;
  }

  .landcomp_bid__right::after {
    background: url(../img/bid/border_lg.png) no-repeat;
    background-size: cover;
    top: 25px;
    left: -35px;
  }

  .landcomp_bid__right ul {
    width: 407px;
  }

  .landcomp_bid__right ul li {
    padding-left: 48px;
  }

  .landcomp_repair {
    padding-bottom: 170px;
  }

  .landcomp_repair__left {
    padding-top: 60px;
  }

  .landcomp_repair__left h3 {
    font-size: 28px;
  }

  .landcomp_repair__left h3 span {
    font-size: 28px;
  }

  .landcomp_repair__left .text {
    font-size: 16px;
    padding-left: 80px;
    width: calc(420px + 80px);
  }

  .landcomp_repair__left .text::before {
    background: url(../img/repair/back_left_lg.png) no-repeat;
    background-size: cover;
    width: 802px;
    height: 640px;
    left: -100px;
  }

  .landcomp_repair__right {
    display: flex;
  }

  .landcomp_repair__right::before {
    left: -70px;
  }

  .landcomp_repair__right::after {
    top: 15px;
    right: -130px;
  }

  .landcomp_repair__right.other::before {
    left: -70px;
  }

  .landcomp_repair__right.other::after {
    top: 15px;
    right: -130px;
  }

  .landcomp_repair_opti .item {
    width: 270px;
  }

  .landcomp_repair_opti .item::before {
    top: -35px;
  }

  .landcomp_repair_opti .item p {
    font-size: 16px;
  }

  .landcomp_other_tasks h2 {
    font-size: 28px;
  }

  .landcomp_other_tasks h2 span {
    font-size: 28px;
  }

  .landcomp_other_tasks h2::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 220px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp_other_tasks h2::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 220px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp_other_tasks__block .item .left {
    margin-right: 40px;
    margin-left: 40px;
  }

  .landcomp_other_tasks__block .item .left .for_img {
    min-width: 380px;
    height: 300px;
  }

  .landcomp_other_tasks__block .item .right .text {
    width: 500px;
  }

  .landcomp_other_tasks__block .item .right .text .title {
    font-size: 22px;
  }

  .landcomp_other_tasks__block .item .right .text ul li {
    font-size: 14px;
  }

  .landcomp_other_tasks__block .item .right .text p {
    font-size: 14px;
  }

  .landcomp_other_tasks__block .item .right .action {
    margin-top: 15px;
  }

  .flickity-prev-next-button.previous {
    left: -30px;
  }

  .flickity-prev-next-button.next {
    right: -10px;
  }

  .landcomp_questions {
    padding-top: 30px;
  }

  .landcomp_questions h2 {
    font-size: 28px;
  }

  .landcomp_questions h2 span {
    font-size: 28px;
  }

  .landcomp_questions__block .left .items {
    width: 600px;
    min-width: 600px;
  }

  .landcomp_questions__block .left .items .item .title {
    font-size: 16px;
    height: 90px;
  }

  .landcomp_questions__block .left .items .item .text {
    padding: 20px 44px;
  }

  .landcomp_questions__block .right {
    margin-left: 55px;
  }

  .landcomp_questions__block .right::before {
    background: url(../img/questions/back_lg.png) no-repeat;
    background-size: cover;
    top: -240px;
    left: -261px;
  }

  .landcomp_questions__block .right .block .title {
    font-size: 20px;
    text-align: center;
    width: 334px;
  }

  .landcomp_questions__block .right .block .master {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(0);
  }

  .landcomp_questions__block .right .block .master .text {
    text-align: center;
  }

  .landcomp_questions__block .right .block .master .text .name {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .landcomp_questions__block .right .block .action {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 324px;
    height: 70px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
    margin-top: 20px;
  }

  .landcomp_questions__block .right .block .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp_questions__block .right .block .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp_certificates {
    padding-bottom: 60px;
  }

  .landcomp_certificates h2 {
    font-size: 28px;
  }

  .landcomp_certificates h2 span {
    font-size: 28px;
  }

  .landcomp_certificates h2::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 160px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp_certificates h2::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 160px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
  }

  .landcomp_certificates__block .items .item {
    width: 238px;
    height: 340px;
    margin-right: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
  }

  .landcomp_footer {
    bottom: -610px;
    height: 610px;
  }

  .landcomp_footer__block {
    flex-direction: column;
    align-items: center;
  }

  .landcomp_footer__block .other_specializations {
    flex-shrink: 0;
  }

  .landcomp_footer__block .other_specializations .links a {
    font-size: 14px;
    line-height: 290%;
    text-align: center;
  }

  .landcomp_footer__block .time {
    margin-right: 30px;
  }

  .landcomp_footer__block .time img {
    margin-right: 10px;
  }

  .landcomp_footer__block .time {
    margin: 0 auto;
    margin-top: 20px;
  }

  .landcomp_footer__block .tel img {
    margin-right: 10px;
  }

  .landcomp_footer__block .tel .title {
    font-size: 16px;
  }

  .landcomp_footer__block .tel {
    margin: 0 auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1400px) and screen and (max-width: 768px) {
  .landcomp_bid__left .landcomp_bid__form .action::after {
    display: none;
  }

  .landcomp_questions__block .right .block .action::after {
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .wrapper {
    padding-left: 19px;
    padding-right: 19px;
  }

  .wrapper {
    max-width: 768px;
  }

  .debug > div {
    padding-left: 19px;
    padding-right: 19px;
  }

  .debug > div {
    padding-left: 19px;
    padding-right: 19px;
  }

  .debug > div {
    max-width: 768px;
  }

  .landcomp__header {
    padding: 6px 0;
  }

  .landcomp__header .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .landcomp__header .mob_menu_action {
    display: block;
  }

  .landcomp__header nav {
    display: none;
  }

  .landcomp__header nav .tel {
    display: flex;
  }

  .landcomp__header .addr {
    display: flex;
  }

  .landcomp__header .tel {
    display: flex;
  }

  .landcomp__header .mob_menu_action {
    display: flex;
  }

  .landcomp__banner::after {
    width: 1020px;
    height: 583px;
    background: url(../img/main/left_back_md.png) no-repeat;
    background-size: cover;
    left: -500px;
    bottom: 70px;
  }

  .landcomp__banner::before {
    width: 590px;
    height: 401px;
    background: url(../img/main/right_back_md.jpg) no-repeat;
    background-size: cover;
    bottom: 70px;
  }

  .landcomp__banner.other::before,
  .landcomp__banner.other::after {
    display: none;
  }

  .landcomp__banner.other .logo,
  .landcomp__banner.other .time,
  .landcomp__banner.other .tel span,
  .landcomp__banner.other .tel span,
  .landcomp__banner.other .time,
  .landcomp__banner.other h1,
  .landcomp__banner.other .ter_text {
    color: #000000;
  }

  .landcomp__banner_top {
    margin-bottom: 50px;
  }

  .landcomp__banner_top .logo img {
    width: 66px;
    min-width: 66px;
    height: 57px;
    margin-right: 10px;
  }

  .landcomp__banner_top .logo .title {
    font-size: 24px;
  }

  .landcomp__banner_top .time {
    font-size: 16px;
    margin-right: 17px;
  }

  .landcomp__banner_top .addr {
    display: none;
  }

  .landcomp__banner_top .tel img {
    margin-right: 10px;
  }

  .landcomp__banner_top .tel .title {
    font-size: 18px;
  }

  .landcomp__banner_main h1 {
    font-size: 26px;
    width: 700px;
    margin-bottom: 20px;
  }

  .landcomp__banner_main h1 span {
    font-size: 26px;
  }

  .landcomp__banner_main .desc {
    font-size: 20px;
    width: 433px;
  }

  .landcomp__banner_territory {
    left: 400px;
    bottom: 160px;
  }

  .landcomp__banner_territory .ter_text .ter_desc {
    font-size: 12px;
  }

  .landcomp__banner_territory .ter_text .ter_title {
    font-size: 12px;
  }

  .landcomp__advantages {
    padding-top: 0;
    padding-bottom: 60px;
  }

  .landcomp__advantages_block .item .for_img {
    width: 170px;
    height: 140px;
  }

  .landcomp__advantages_block .item .text {
    font-size: 14px;
    width: 168px;
  }

  .landcomp__advantages_block .item .text br {
    display: none;
  }

  .landcomp__brands {
    padding-bottom: 25px;
  }

  .landcomp__brands h3 {
    font-size: 24px;
  }

  .landcomp__brands h3 span {
    font-size: 24px;
  }

  .landcomp__brands h3 {
    margin-bottom: 25px;
  }

  .landcomp__brands h3::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 50px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp__brands h3::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 50px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp__brands .slider .item {
    margin-right: 50px;
  }

  .landcomp__cost {
    padding-bottom: 90px;
  }

  .landcomp__cost .flickity-viewport {
    width: 100%;
  }

  .landcomp__cost .carousel-cell {
    width: 100%;
  }

  .landcomp__cost .flickity-prev-next-button {
    top: calc(100% + 40px);
  }

  .landcomp__cost .flickity-prev-next-button.previous {
    left: 220px;
  }

  .landcomp__cost .flickity-prev-next-button.next {
    right: 220px;
  }

  .landcomp__cost .wrapper .counter {
    display: block;
  }

  .landcomp__cost h2 {
    font-size: 24px;
  }

  .landcomp__cost h2 span {
    font-size: 24px;
  }

  .landcomp__cost h2 {
    margin-bottom: 30px;
  }

  .landcomp__cost_block .item {
    width: 606px;
    min-width: 606px;
    height: 290px;
    margin: 0 65px;
    padding: 0;
  }

  .landcomp__cost_block .item:nth-child(odd) {
    margin: 0 65px;
  }

  .landcomp__cost_block .item:nth-child(4) {
    padding: 0;
  }

  .landcomp__cost_block .item .for_img {
    width: 290px;
    min-width: 290px;
    max-width: 290px;
    height: 290px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landcomp__cost_block .item .desc {
    padding-top: 22px;
    padding-right: 22px;
    padding-bottom: 44px;
  }

  .landcomp__diagnostics {
    padding-top: 40px;
  }

  .landcomp__diagnostics .wrapper::before {
    bottom: 100px;
  }

  .landcomp__diagnostics .wrapper::after {
    top: 28px;
    right: -32px;
    width: 210px;
    height: 210px;
    background: url(../img/diagnostics/circle_right.png) 0 0 / cover no-repeat;
  }

  .landcomp__diagnostics h3 {
    font-size: 24px;
  }

  .landcomp__diagnostics h3 span {
    font-size: 24px;
  }

  .landcomp__diagnostics_block {
    background: url(../img/diagnostics/back_md.png) no-repeat;
    background-size: cover;
    width: 730px;
    height: 780px;
    padding: 0 40px;
    padding-top: 90px;
  }

  .landcomp__diagnostics_block .img_count {
    left: 61px;
    top: -65px;
    width: 129px;
    height: 129px;
  }

  .landcomp__diagnostics_block .img_count.num_1 {
    background: url(../img/diagnostics/counter_1_md.png) 0 0 / cover no-repeat;
  }

  .landcomp__diagnostics_block .img_count.num_2 {
    background: url(../img/diagnostics/counter_2_md.png) 0 0 / cover no-repeat;
  }

  .landcomp__diagnostics_block .img_count.num_3 {
    background: url(../img/diagnostics/counter_3_md.png) 0 0 / cover no-repeat;
  }

  .landcomp__diagnostics_block .img_count.num_4 {
    background: url(../img/diagnostics/counter_4_md.png) 0 0 / cover no-repeat;
  }

  .landcomp__diagnostics_block::after {
    background: url(../img/diagnostics/ammeter_md.png) 0 0 / cover no-repeat;
    width: 240px;
    height: 262px;
    top: -170px;
    right: -80px;
  }

  .landcomp__diagnostics_block .title {
    margin-bottom: 90px;
  }

  .landcomp__diagnostics_block .start {
    flex-wrap: wrap;
    height: 500px;
    justify-content: space-around;
  }

  .landcomp__diagnostics_block .start.opti {
    margin-top: 25px;
  }

  .landcomp__diagnostics_block .start .item_start {
    width: 220px;
    height: 220px;
  }

  .landcomp__diagnostics_block .start .item_start::before {
    width: 220px;
    height: 110px;
  }

  .landcomp__diagnostics_block .start .item_start::after {
    left: calc(220px / 2);
    top: 110px;
  }

  .landcomp__diagnostics_block .start .item {
    width: 33%;
  }

  .landcomp__breakdowns::before {
    display: block;
  }

  .landcomp__breakdowns .wrapper::before {
    top: -32px;
    left: -56px;
  }

  .landcomp__breakdowns .wrapper::after {
    background: url(../img/breakdowns/pseudo_after_md.png) 0 0 / cover no-repeat;
    width: 264px;
    height: 241px;
    right: -27px;
    top: -114px;
  }

  .landcomp__breakdowns h2 {
    font-size: 24px;
  }

  .landcomp__breakdowns h2 span {
    font-size: 24px;
  }

  .landcomp__breakdowns .desc {
    margin-bottom: 60px;
  }

  .landcomp__breakdowns .tab_container {
    padding: 0 0 55px;
  }

  .landcomp__breakdowns .tab_container .item {
    width: 355px;
    margin-right: 14px;
    margin-top: 14px;
  }

  .landcomp__breakdowns .tab_container .item:nth-child(odd),
  .landcomp__breakdowns .tab_container .item:nth-child(even) {
    margin-right: 20px;
  }

  .landcomp__breakdowns .tab_container .item:nth-child(-n+2) {
    margin-top: 45px;
  }

  .landcomp__breakdowns .tab_container .item:nth-child(2n) {
    margin-right: 0;
  }

  .landcomp__breakdowns__mob h2 {
    font-size: 24px;
  }

  .landcomp__breakdowns__mob h2 span {
    font-size: 24px;
  }

  .landcomp__mac {
    padding-bottom: 20px;
  }

  .landcomp__mac h2 {
    font-size: 24px;
  }

  .landcomp__mac h2 span {
    font-size: 24px;
  }

  .landcomp__mac h2 {
    margin-bottom: 50px;
  }

  .landcomp__mac .models {
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 70px;
  }

  .landcomp__mac .models::after {
    background: url(../img/mac/pseudo_md.png) 0 0 / cover no-repeat;
    width: 246px;
    height: 209px;
    top: -195px;
    right: -80px;
  }

  .landcomp__mac .models .item:nth-child(-n+3) {
    margin-bottom: 40px;
  }

  .landcomp__mac .models .item .for_img {
    width: 183px;
    height: 130px;
  }

  .landcomp__mac .malfunctions::before {
    top: -120px;
    left: -90px;
  }

  .landcomp__mac .malfunctions .title {
    font-size: 24px;
  }

  .landcomp__mac .malfunctions_block .item {
    margin: 0;
    width: 25%;
    justify-content: flex-start;
  }

  .landcomp__mac .malfunctions_block .item:nth-child(odd),
  .landcomp__mac .malfunctions_block .item:nth-child(even) {
    margin: 0;
  }

  .landcomp__mac .malfunctions_block .item:nth-child(-n+4) {
    margin-bottom: 50px;
  }

  .landcomp__mac .malfunctions_block .item:nth-child(n+4):nth-child(-n+8) {
    margin-bottom: 50px;
  }

  .landcomp__mac .malfunctions_block .item:nth-child(9) {
    margin-left: 25%;
  }

  .landcomp__mac .malfunctions_block .item:nth-child(10) {
    margin-right: 25%;
  }

  .landcomp__order h2 {
    font-size: 24px;
  }

  .landcomp__order h2 span {
    font-size: 24px;
  }

  .landcomp__order h2::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 23px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp__order h2::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 23px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp__order .one {
    margin-bottom: 170px;
  }

  .landcomp__order .one .left .for_order {
    transform: translateY(150px) translateX(242px);
  }

  .landcomp__order .one .left .for_order::after {
    background: url(../img/order/one/line.png) 0 0 / cover no-repeat;
    left: 190px;
    top: -31px;
  }

  .landcomp__order .one .right {
    margin-left: -257px;
    width: 350px;
  }

  .landcomp__order .one .right .title .num {
    font-size: 46px;
    margin-right: 20px;
  }

  .landcomp__order .one .right .title .text {
    font-size: 24px;
  }

  .landcomp__order .one .right .title {
    margin-left: -128px;
  }

  .landcomp__order .one .right .main_text li {
    font-size: 16px;
    line-height: 150%;
  }

  .landcomp__order .one .right .main_text::before {
    left: -150px;
    top: -183px;
  }

  .landcomp__order .one .right .optional {
    background: url(../img/order/one/back_2_md.png) no-repeat;
    background-size: cover;
    transform: translateX(-360px) translateY(-35px);
    padding-left: 220px;
  }

  .landcomp__order .two .left {
    padding-left: 30px;
  }

  .landcomp__order .two .left .title .num {
    font-size: 46px;
    margin-right: 20px;
  }

  .landcomp__order .two .left .title .text {
    font-size: 24px;
  }

  .landcomp__order .two .left .main_text li {
    font-size: 16px;
    line-height: 150%;
  }

  .landcomp__order .two .left .main_text {
    width: 298px;
  }

  .landcomp__order .two .left .main_text li {
    line-height: 200%;
  }

  .landcomp__order .two .right img {
    min-width: 386px;
    height: 256px;
    margin: 0;
    margin-left: 50px;
  }

  .landcomp__order .two .right .optional {
    transform: translateY(-145px) translateX(-81px);
  }

  .landcomp__order .three .left {
    padding-left: 25px;
  }

  .landcomp__order .three .left .desc .text {
    width: 383px;
  }

  .landcomp__order .three .left .desc .text p {
    font-size: 16px;
  }

  .landcomp__order .three .left .optional {
    transform: translateX(-35px) translateY(-25px);
  }

  .landcomp__order .three .left .optional::after {
    top: 154px;
    right: -79px;
    transform: rotate(23deg);
  }

  .landcomp__order .three .right {
    margin-left: -250px;
  }

  .landcomp__order .three .right .block .for_img {
    width: 148px;
    min-width: 148px;
    height: 210px;
  }

  .landcomp__order .three .right .block .for_img:first-child {
    margin-right: 14px;
  }

  .landcomp__order .four {
    padding-bottom: 100px;
  }

  .landcomp__order .four .right .title .num {
    font-size: 46px;
    margin-right: 20px;
  }

  .landcomp__order .four .right .title .text {
    font-size: 24px;
  }

  .landcomp__order .four .right .title {
    transform: translateX(-410px);
  }

  .landcomp__order .four .right .main_text li {
    font-size: 16px;
    line-height: 150%;
  }

  .landcomp__order .four .right .main_text {
    margin-left: -134px;
    width: 361px;
  }

  .landcomp__order .four .right .main_text br {
    display: none;
  }

  .landcomp__order .four .right .main_text li {
    line-height: 200%;
  }

  .landcomp__order .four .right .main_text::before {
    top: -125px;
  }

  .landcomp__order .four .right .optional {
    transform: translateX(-323px) translateY(-29px);
  }

  .landcomp_bid {
    height: 1200px;
    background-position: 61% 100%;
  }

  .landcomp_bid .wrapper {
    flex-direction: column;
  }

  .landcomp_bid__left {
    padding-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .landcomp_bid__left::after {
    background: url(../img/bid/clock_md.png) no-repeat;
    background-size: cover;
    width: 222px;
    height: 223px;
    top: 10px;
    right: -60px;
  }

  .landcomp_bid__left .desc {
    font-size: 26px;
    width: 460px;
    text-align: center;
  }

  .landcomp_bid__left .desc br {
    display: none;
  }

  .landcomp_bid__right {
    margin: 0 auto;
    margin-top: 60px;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landcomp_bid__right::before {
    background-position: 61% 100%;
  }

  .landcomp_bid__right .title {
    font-size: 20px;
    padding-bottom: 40px;
  }

  .landcomp_repair {
    padding-bottom: 50px;
  }

  .landcomp_repair__left h3 {
    font-size: 24px;
  }

  .landcomp_repair__left h3 span {
    font-size: 24px;
  }

  .landcomp_repair__left .desc {
    font-size: 20px;
    text-align: center;
  }

  .landcomp_repair__left .text {
    padding-left: 0;
    width: 417px;
  }

  .landcomp_repair__left .text::before {
    background: url(../img/repair/back_left_md.png) no-repeat;
    background-size: cover;
    width: 720px;
    height: 560px;
    left: -125px;
  }

  .landcomp_repair__left .text p:nth-child(3) {
    margin-bottom: 20px;
  }

  .landcomp_repair__left .name {
    margin-bottom: 5px;
  }

  .landcomp_repair__right::before {
    top: -80px;
    left: -25px;
  }

  .landcomp_repair__right::after {
    background: url(../img/repair/pseudo_2_md.png) no-repeat;
    background-size: cover;
    width: 134px;
    height: 272px;
    top: 50px;
    left: 250px;
  }

  .landcomp_repair__right.other::before {
    top: -80px;
    left: -25px;
  }

  .landcomp_repair__right.other::after {
    background: url(../img/repair/pseudo_2_md_2.png) no-repeat;
    background-size: cover;
    width: 134px;
    height: 272px;
    top: 50px;
    left: 250px;
  }

  .landcomp_repair_opti .wrapper {
    padding: 10px 0 10px 15px;
  }

  .landcomp_repair_opti .item {
    height: 200px;
    width: 220px;
    display: flex;
    align-items: center;
  }

  .landcomp_repair_opti .item:last-child {
    width: 242px;
  }

  .landcomp_repair_opti .item::before {
    background: url(../img/repair_opti/back_md.png) no-repeat;
    background-size: cover;
    width: 236px;
    height: 178px;
    top: unset;
    left: unset;
  }

  .landcomp_repair_opti .item p {
    font-size: 14px;
    transform: translateX(20px) translateY(15px);
  }

  .landcomp_repair_opti .item p::before {
    display: none;
  }

  .landcomp_other_tasks {
    padding-top: 60px;
  }

  .landcomp_other_tasks h2 {
    font-size: 24px;
  }

  .landcomp_other_tasks h2 span {
    font-size: 24px;
  }

  .landcomp_other_tasks h2::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 140px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp_other_tasks h2::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 140px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp_other_tasks__block {
    padding: 0 60px;
  }

  .landcomp_other_tasks__block .item {
    flex-direction: column;
    margin-right: 40px;
    margin-left: 40px;
  }

  .landcomp_other_tasks__block .item .left {
    display: flex;
    margin: 0;
    height: 290px;
  }

  .landcomp_other_tasks__block .item .left .for_img {
    min-width: 320px;
    height: 290px;
  }

  .landcomp_other_tasks__block .item .left .data {
    display: flex;
    flex-direction: column;
  }

  .landcomp_other_tasks__block .item .left .data .tittle_md {
    display: block;
  }

  .landcomp_other_tasks__block .item .left .data .action {
    display: block;
  }

  .landcomp_other_tasks__block .item .left .data li::before {
    left: -15px;
  }

  .landcomp_other_tasks__block .item .left .data li span {
    font-size: 16px;
  }

  .landcomp_other_tasks__block .item .right {
    margin-top: 25px;
    height: 280px;
  }

  .landcomp_other_tasks__block .item .right .text .title {
    display: none;
  }

  .landcomp_other_tasks__block .item .right .text ul li {
    width: 580px;
  }

  .landcomp_other_tasks__block .item .right .text p {
    width: 580px;
  }

  .landcomp_other_tasks__block .item .right .action {
    display: none;
  }

  .flickity-prev-next-button.previous {
    left: -10px;
  }

  .flickity-prev-next-button.next {
    right: 10px;
  }

  .landcomp_questions {
    padding-top: 0;
  }

  .landcomp_questions h2 {
    font-size: 24px;
  }

  .landcomp_questions h2 span {
    font-size: 24px;
  }

  .landcomp_questions h2 {
    font-size: 36px;
    text-align: center;
  }

  .landcomp_questions h2 span {
    font-size: 36px;
  }

  .landcomp_questions__block {
    flex-direction: column;
  }

  .landcomp_questions__block .left .items {
    width: 730px;
  }

  .landcomp_questions__block .right {
    margin: 0;
  }

  .landcomp_questions__block .right::before {
    background: url(../img/questions/back_md.png) no-repeat;
    background-size: cover;
  }

  .landcomp_questions__block .right .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .landcomp_questions__block .right .block .title {
    font-size: 26px;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .landcomp_questions__block .right .block .master {
    flex-direction: row;
    margin-bottom: 45px;
  }

  .landcomp_questions__block .right .block .master .text {
    text-align: left;
  }

  .landcomp_questions__block .right .block .action {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 450px;
    height: 70px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
    margin: 0;
  }

  .landcomp_questions__block .right .block .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp_questions__block .right .block .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp_certificates h2 {
    font-size: 24px;
  }

  .landcomp_certificates h2 span {
    font-size: 24px;
  }

  .landcomp_certificates h2::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 70px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp_certificates h2::after {
    position: absolute;
    content: '';
    height: 1px;
    width: 70px;
    background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
    border-radius: 50px;
    top: 16px;
  }

  .landcomp_certificates__block::before {
    background: url(../img/certificates/back_1_md.png) no-repeat;
    background-size: cover;
    width: 309px;
    height: 730px;
  }

  .landcomp_certificates__block::after {
    background: url(../img/certificates/back_2_md.png) no-repeat;
    background-size: cover;
    width: 269px;
    height: 154px;
  }

  .landcomp_certificates__block .items {
    width: 634px;
  }

  .landcomp_certificates__block .items .item {
    width: 200px;
    height: 285px;
    margin-right: 17px;
  }

  .landcomp_certificates__block .items .item:nth-child(-n+3) {
    margin-bottom: 17px;
  }

  .landcomp_footer {
    bottom: -600px;
    height: 600px;
  }

  .landcomp_footer__block .logo img {
    width: 66px;
    min-width: 66px;
    height: 57px;
    margin-right: 10px;
  }

  .landcomp_footer__block .logo .title {
    font-size: 24px;
  }

  .landcomp_footer__block .other_specializations .links {
    margin-top: 10px;
  }

  .landcomp_footer__block .other_specializations .links a {
    font-size: 13px;
    line-height: 270%;
  }

  .landcomp_footer__block .time {
    font-size: 16px;
    margin-right: 17px;
  }

  .landcomp_footer__block .time {
    margin: 0 auto;
    margin-top: 20px;
  }

  .landcomp_footer__block .tel img {
    margin-right: 10px;
  }

  .landcomp_footer__block .tel .title {
    font-size: 18px;
  }

  .landcomp_404 {
    background: url(../img/404/back_md.jpg) no-repeat;
    background-size: cover;
  }

  .landcomp_404 img.error {
    display: none;
  }

  .landcomp_404 img.md_404 {
    display: block;
    width: 600px;
    height: auto;
  }

  .landcomp_404 .text {
    font-size: 18px;
    line-height: 145%;
    margin-top: 0;
  }
}

@media screen and (max-width: 1080px) and screen and (max-width: 768px) {
  .landcomp_questions__block .right .block .action::after {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .wrapper {
    max-width: 560px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    max-width: 560px;
  }

  body > * {
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .landcomp__header .addr {
    display: none;
  }

  .landcomp__mob_menu {
    width: 100%;
    height: 100%;
  }

  .landcomp__mob_menu__block .action::after {
    display: none;
  }

  .landcomp__mob_menu__block .action {
    display: flex;
  }

  .landcomp__banner {
    background: url(../img/main/back_mob.jpg) no-repeat;
    background-size: cover;
    height: 600px;
  }

  .landcomp__banner.other {
    background: #FAFAFA;
    height: 600px;
  }

  .landcomp__banner.other::after,
  .landcomp__banner.other::before {
    display: none;
  }

  .landcomp__banner::after {
    display: none;
  }

  .landcomp__banner::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: none;
    z-index: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .landcomp__banner_top {
    flex-direction: column;
  }

  .landcomp__banner_top .logo img {
    width: 63px;
    min-width: 63px;
    height: 58px;
  }

  .landcomp__banner_top .logo .title {
    font-size: 22px;
  }

  .landcomp__banner_top .logo .desc {
    font-size: 10px;
    width: 201px;
  }

  .landcomp__banner_top .logo {
    color: #ffffff;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .landcomp__banner_top .logo:hover {
    transition: 0.2s;
    color: #FF833D;
  }

  .landcomp__banner_top .time {
    width: 290px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .landcomp__banner_top .time .text {
    display: flex;
  }

  .landcomp__banner_top .time .text * {
    font-size: 16px;
    line-height: 18px;
  }

  .landcomp__banner_top .time .text .number {
    margin-right: 5px;
  }

  .landcomp__banner_top .time {
    color: #ffffff;
  }

  .landcomp__banner_top .addr {
    display: flex;
    height: 70px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .landcomp__banner_top .tel {
    width: 290px;
    margin: 0 auto;
  }

  .landcomp__banner_top .tel img {
    margin-right: 17px;
  }

  .landcomp__banner_top .tel .title {
    font-size: 22px;
  }

  .landcomp__banner_top .tel {
    display: flex;
    margin: 0 auto;
  }

  .landcomp__banner_top .tel .title {
    color: #fff;
  }

  .landcomp__banner_main h1 {
    z-index: 5;
    width: 100%;
    color: #ffffff;
    margin-right: 0;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }

  .landcomp__banner_main h1 span {
    font-size: 20px;
  }

  .landcomp__banner_main h1 br {
    display: none;
  }

  .landcomp__banner_main .desc {
    display: none;
  }

  .landcomp__banner_main_form {
    display: flex;
    justify-content: center;
  }

  .landcomp__banner_main_form .input_item {
    display: none;
  }

  .landcomp__banner_main_form .action::after {
    display: none;
  }

  .landcomp__banner_main_form .action {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 290px;
    height: 55px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
    margin-top: 35px;
  }

  .landcomp__banner_main_form .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp__banner_main_form .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp__banner_territory {
    display: none;
  }

  .landcomp__banner_territory .ter_text {
    color: #ffffff;
  }

  .landcomp__banner_territory__mob {
    display: flex;
  }

  .landcomp__advantages {
    padding-top: 40px;
  }

  .landcomp__advantages .flickity-viewport {
    width: 100%;
  }

  .landcomp__advantages .carousel-cell {
    width: 100%;
  }

  .landcomp__advantages .flickity-prev-next-button {
    top: calc(100% + 40px);
  }

  .landcomp__advantages_block .item {
    width: 100%;
  }

  .landcomp__advantages_block .item .for_img {
    width: 230px;
    height: 200px;
    margin: 0 auto;
  }

  .landcomp__advantages_block .item .text {
    font-size: 18px;
    width: 290px;
    margin: 0 auto;
  }

  .landcomp__advantages .counter {
    display: block;
  }

  .landcomp__brands h3::before,
  .landcomp__brands h3::after {
    display: none;
  }

  .landcomp__brands .slider .item {
    width: 140px;
    height: 70px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .landcomp__cost {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .landcomp__cost .flickity-prev-next-button.previous {
    left: 10px;
  }

  .landcomp__cost .flickity-prev-next-button.next {
    right: 20px;
  }

  .landcomp__cost::before {
    display: none;
  }

  .landcomp__cost::after {
    display: none;
  }

  .landcomp__cost .wrapper {
    padding: 0;
  }

  .landcomp__cost .wrapper::before {
    display: none;
  }

  .landcomp__cost .wrapper::after {
    display: none;
  }

  .landcomp__cost h2::before,
  .landcomp__cost h2::after {
    display: none;
  }

  .landcomp__cost_block .item {
    min-width: 100%;
    width: 100%;
    height: 650px;
    flex-direction: column;
    align-items: center;
  }

  .landcomp__cost_block .item .for_img {
    margin: 0;
    margin-bottom: 15px;
  }

  .landcomp__cost_block .item .desc {
    align-items: center;
    padding: 0 15px;
    height: calc(100% - 305px);
  }

  .landcomp__cost_block .item .desc .title {
    font-size: 22px;
    margin: 0 auto 0 0;
    margin-bottom: 25px;
  }

  .landcomp__cost_block .item .desc .text {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .landcomp__cost_block .item .desc button::after {
    display: none;
  }

  .landcomp__cost_block .item .desc button {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 290px;
    height: 55px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
    margin-bottom: 50px;
  }

  .landcomp__cost_block .item .desc button:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp__cost_block .item .desc button::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp__diagnostics {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .landcomp__diagnostics .wrapper {
    padding: 0;
  }

  .landcomp__diagnostics .wrapper::before {
    display: none;
  }

  .landcomp__diagnostics .wrapper::after {
    display: none;
  }

  .landcomp__diagnostics h3::before,
  .landcomp__diagnostics h3::after {
    display: none;
  }

  .landcomp__diagnostics h3 {
    padding: 0 15px !important;
  }

  .landcomp__diagnostics .desc {
    font-size: 18px;
    width: 180px;
    margin: 0 auto;
  }

  .landcomp__diagnostics_block {
    background: url(../img/diagnostics/back_xs.png) no-repeat;
    background-size: cover;
    width: 320px;
    height: auto;
    padding: 90px 40px 400px 40px;
    padding-top: 90px;
    margin-top: 100px;
  }

  .landcomp__diagnostics_block .img_count {
    left: 100px;
  }

  .landcomp__diagnostics_block::after {
    display: none;
  }

  .landcomp__diagnostics_block .title {
    font-size: 22px;
    margin-bottom: 50px;
    width: 250px;
  }

  .landcomp__diagnostics_block .start.opti {
    height: auto;
  }

  .landcomp__diagnostics_block .start .item {
    width: 100%;
    margin-bottom: 50px;
  }

  .landcomp__diagnostics_block .start .item:last-child {
    display: none;
  }

  .landcomp__breakdowns {
    display: none;
  }

  .landcomp__breakdowns h2::before,
  .landcomp__breakdowns h2::after {
    display: none;
  }

  .landcomp__breakdowns .tabs li::after {
    display: none;
  }

  .landcomp__breakdowns .tabs li.active::after {
    display: none;
  }

  .landcomp__breakdowns .tab_container .item button::after {
    display: none;
  }

  .landcomp__breakdowns__mob {
    display: block;
  }

  .landcomp__breakdowns__mob h2::before,
  .landcomp__breakdowns__mob h2::after {
    display: none;
  }

  .landcomp__breakdowns__mob .item:not(:first-child) {
    margin-top: 60px;
  }

  .landcomp__breakdowns__mob .item .slider .item button::after {
    display: none;
  }

  .landcomp__mac {
    padding-bottom: 50px;
  }

  .landcomp__mac .flickity-viewport {
    width: 100%;
  }

  .landcomp__mac .carousel-cell {
    width: 100%;
  }

  .landcomp__mac .flickity-prev-next-button {
    top: 100%;
    z-index: 300;
  }

  .landcomp__mac h2::before,
  .landcomp__mac h2::after {
    display: none;
  }

  .landcomp__mac .counter_1,
  .landcomp__mac .counter_2 {
    display: block;
  }

  .landcomp__mac .models::after {
    display: none;
  }

  .landcomp__mac .models .item {
    width: 100%;
  }

  .landcomp__mac .models .item .text {
    font-size: 18px;
  }

  .landcomp__mac .malfunctions::before {
    display: none;
  }

  .landcomp__mac .malfunctions .title {
    font-size: 21px;
  }

  .landcomp__mac .malfunctions .title br {
    display: block;
  }

  .landcomp__mac .malfunctions .desc {
    font-size: 18px;
  }

  .landcomp__mac .malfunctions_block .item {
    width: 100%;
  }

  .landcomp__mac .malfunctions_block .item .text {
    font-size: 18px;
  }

  .landcomp__order {
    margin-bottom: -100px;
  }

  .landcomp__order * {
    transform: unset !important;
    margin: 0 auto !important;
    padding: 0 !important;
    flex-direction: column !important;
  }

  .landcomp__order h2::before,
  .landcomp__order h2::after {
    display: none;
  }

  .landcomp__order h2 {
    padding: 0 15px !important;
  }

  .landcomp__order .one .left .for_order {
    display: none;
  }

  .landcomp__order .one .left .for_order button::after {
    display: none;
  }

  .landcomp__order .one .left .for_order::after {
    display: none;
  }

  .landcomp__order .one .right {
    width: 100%;
    padding: 0 15px !important;
    margin: 0 auto !important;
  }

  .landcomp__order .one .right .title .num {
    font-size: 56px;
    margin: 0;
  }

  .landcomp__order .one .right .title .text {
    font-size: 26px;
    margin: 0;
    margin-bottom: 25px !important;
  }

  .landcomp__order .one .right .main_text {
    margin-bottom: 15px !important;
  }

  .landcomp__order .one .right .main_text li {
    padding-left: 27px !important;
  }

  .landcomp__order .one .right .main_text li::before {
    top: 12px;
    left: 0;
  }

  .landcomp__order .one .right .main_text::before {
    display: none;
  }

  .landcomp__order .one .right .optional {
    font-size: 14px;
    background: url(../img/order/one/back_2_xs.png) no-repeat;
    background-size: cover;
    transform: translateX(-171px) !important;
    padding-left: 170px!important;
    padding-top: 135px!important;
  }

  .landcomp__order .one .right .optional p {
    width: 290px;
    margin: 0 !important;
  }

  .landcomp__order .one .right .for_order button::after {
    display: none;
  }

  .landcomp__order .one .right .for_order {
    display: flex;
  }

  .landcomp__order .two {
    margin-top: 60px !important;
  }

  .landcomp__order .two .left .title .num {
    font-size: 56px;
    margin: 0;
  }

  .landcomp__order .two .left .title .text {
    font-size: 26px;
    margin: 0;
    margin-bottom: 25px !important;
  }

  .landcomp__order .two .left .main_text {
    margin-bottom: 15px !important;
  }

  .landcomp__order .two .left .main_text li {
    padding-left: 27px !important;
  }

  .landcomp__order .two .left .main_text li::before {
    top: 12px;
    left: 0;
  }

  .landcomp__order .two .left .main_text {
    width: 100%;
    padding: 0 15px!important;
  }

  .landcomp__order .two .right {
    width: 320px;
  }

  .landcomp__order .two .right img {
    width: 320px;
    min-width: 320px;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
  }

  .landcomp__order .two .right .optional {
    transform: translateX(-145px) translateY(-168px) !important;
    padding-left: 160px !important;
    padding-top: 168px !important;
  }

  .landcomp__order .two .right .optional span {
    width: 290px !important;
    margin: 0 !important;
    text-align: center;
  }

  .landcomp__order .two .right .optional p {
    width: 301px !important;
    margin: 0 !important;
    text-align: center;
    font-size: 14px;
  }

  .landcomp__order .three {
    margin-top: -130px !important;
  }

  .landcomp__order .three .left {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 320px;
    padding: 0 15px !important;
  }

  .landcomp__order .three .left .desc .title {
    width: 290px;
    font-size: 23px;
  }

  .landcomp__order .three .left .desc .text {
    width: 100%;
  }

  .landcomp__order .three .left .desc .text p span {
    margin-right: 15px !important;
  }

  .landcomp__order .three .left .optional {
    width: 630px;
    height: 356px;
    background: url(../img/order/three/back_xs.png) no-repeat;
    background-size: cover;
    transform: translateX(-178px) translateY(-22px) !important;
    padding-left: 15px !important;
    padding-top: 140px !important;
  }

  .landcomp__order .three .left .optional p {
    width: 290px;
    font-size: 14px;
  }

  .landcomp__order .three .left .optional::after {
    display: none;
  }

  .landcomp__order .three .right .block {
    flex-direction: row !important;
    width: 290px;
  }

  .landcomp__order .three .right .block .for_img {
    width: 140px;
    min-width: 140px;
    height: 200px;
  }

  .landcomp__order .four {
    margin-top: 40px !important;
    margin-bottom: 120px !important;
  }

  .landcomp__order .four .left {
    display: none;
  }

  .landcomp__order .four .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
  }

  .landcomp__order .four .right .title .num {
    font-size: 56px;
    margin: 0;
  }

  .landcomp__order .four .right .title .text {
    font-size: 26px;
    margin: 0;
    margin-bottom: 25px !important;
  }

  .landcomp__order .four .right .title {
    width: 100%;
    text-align: center;
    margin: 0 auto !important;
  }

  .landcomp__order .four .right .main_text {
    margin-bottom: 15px !important;
  }

  .landcomp__order .four .right .main_text li {
    padding-left: 27px !important;
  }

  .landcomp__order .four .right .main_text li::before {
    top: 12px;
    left: 0;
  }

  .landcomp__order .four .right .main_text {
    width: 100%;
    margin: 0 !important;
    padding: 0 15px !important;
  }

  .landcomp__order .four .right .main_text::before {
    display: none;
  }

  .landcomp__order .four .right .optional {
    transform: translateX(-90px) translateY(110px) !important;
    padding-top: 90px !important;
  }

  .landcomp__order .four .right .book {
    display: block;
  }

  .landcomp_bid {
    height: auto;
  }

  .landcomp_bid .wrapper {
    padding: 0;
  }

  .landcomp_bid__left::after {
    background: url(../img/bid/clock_xs.png) no-repeat;
    background-size: cover;
    width: 153px;
    height: 152px;
    top: 150px;
    right: -60px;
  }

  .landcomp_bid__left .desc {
    width: 290px;
  }

  .landcomp_bid__left .landcomp_bid__form .input_item input {
    box-sizing: border-box;
    width: 290px;
    height: 50px;
    padding-left: 0;
    outline: none;
    border: 1px solid #EDEDED;
    border-radius: 29px;
    transition: 0.2s;
    color: #000000;
    font-weight: 500;
    line-height: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
  }

  .landcomp_bid__left .landcomp_bid__form .input_item input::placeholder {
    color: #8A8A8A;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
  }

  .landcomp_bid__left .landcomp_bid__form .action::after {
    display: none;
  }

  .landcomp_bid__left .landcomp_bid__form .action {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 290px;
    height: 70px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
  }

  .landcomp_bid__left .landcomp_bid__form .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp_bid__left .landcomp_bid__form .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp_bid__right {
    width: 100%;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
    border-radius: 0;
  }

  .landcomp_bid__right::after {
    display: none;
  }

  .landcomp_bid__right .title {
    width: 290px;
  }

  .landcomp_bid__right ul {
    width: 100%;
    padding: 0 15px;
  }

  .landcomp_repair {
    padding-bottom: 0;
  }

  .landcomp_repair .wrapper {
    padding: 0;
    flex-direction: column;
  }

  .landcomp_repair__left {
    width: 100%;
    padding: 0 15px;
    padding-top: 50px;
  }

  .landcomp_repair__left h3::before,
  .landcomp_repair__left h3::after {
    display: none;
  }

  .landcomp_repair__left h3 {
    padding: 0 15px;
    text-align: center;
  }

  .landcomp_repair__left .text {
    width: 100%;
  }

  .landcomp_repair__left .text::before {
    background: url(../img/repair/left_quot.png) no-repeat;
    background-size: cover;
    width: 49px;
    height: 33px;
    top: -53px;
    left: 0;
  }

  .landcomp_repair__left .text::after {
    display: block;
  }

  .landcomp_repair__left .desc_name {
    width: 100%;
  }

  .landcomp_repair__right::before {
    display: none;
  }

  .landcomp_repair__right::after {
    display: none;
  }

  .landcomp_repair__right.other::before {
    display: none;
  }

  .landcomp_repair__right.other::after {
    display: none;
  }

  .landcomp_repair_opti .wrapper {
    flex-direction: column;
    align-items: center;
  }

  .landcomp_repair_opti .item::before {
    background: url(../img/repair_opti/back_xs.png) no-repeat;
    background-size: cover;
    width: 249px;
    height: 148px;
    top: unset;
    left: -20px;
  }

  .landcomp_other_tasks {
    padding-bottom: 100px;
  }

  .landcomp_other_tasks .flickity-prev-next-button {
    top: calc(100% + 40px);
    z-index: 300;
  }

  .landcomp_other_tasks .flickity-viewport {
    transition: height 0.2s;
  }

  .landcomp_other_tasks .counter {
    display: block;
  }

  .landcomp_other_tasks h2::before,
  .landcomp_other_tasks h2::after {
    display: none;
  }

  .landcomp_other_tasks__block {
    padding: 0;
  }

  .landcomp_other_tasks__block .item .left {
    height: auto;
  }

  .landcomp_other_tasks__block .item .left .for_img {
    display: none;
  }

  .landcomp_other_tasks__block .item .left .data {
    padding-left: 20px;
    width: 100%;
  }

  .landcomp_other_tasks__block .item .left .data .tittle_md {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
  }

  .landcomp_other_tasks__block .item .left .data .action::after {
    display: none;
  }

  .landcomp_other_tasks__block .item .left .data .action {
    display: none;
  }

  .landcomp_other_tasks__block .item .right {
    height: auto;
    margin-top: 10px;
  }

  .landcomp_other_tasks__block .item .right .text {
    width: 100%;
  }

  .landcomp_other_tasks__block .item .right .text ul li {
    width: 100%;
  }

  .landcomp_other_tasks__block .item .right .text p {
    width: 100%;
  }

  .landcomp_other_tasks__block .item .right .action::after {
    display: none;
  }

  .landcomp_other_tasks__block .item .right .action {
    display: block;
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 290px;
    height: 70px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .landcomp_other_tasks__block .item .right .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp_other_tasks__block .item .right .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp_questions .wrapper {
    padding: 0;
  }

  .landcomp_questions h2::before,
  .landcomp_questions h2::after {
    display: none;
  }

  .landcomp_questions h2 {
    font-size: 26px;
  }

  .landcomp_questions h2 span {
    font-size: 26px;
  }

  .landcomp_questions__block .left .items {
    width: 100%;
    min-width: 100%;
  }

  .landcomp_questions__block .left .items .item:not(:first-child) {
    margin-top: 15px;
  }

  .landcomp_questions__block .left .items .item .title {
    border-radius: 0;
    height: auto;
  }

  .landcomp_questions__block .left .items .item .title {
    padding: 20px 15px;
  }

  .landcomp_questions__block .left .items .item .text {
    padding: 20px 15px;
  }

  .landcomp_questions__block .right {
    margin-top: 50px;
  }

  .landcomp_questions__block .right::before {
    background: url(../img/questions/back_xs.png) no-repeat;
    background-size: cover;
    width: 1094px;
    height: 1061px;
    top: -200px;
    left: -330px;
  }

  .landcomp_questions__block .right .block .title {
    width: 100%;
    font-size: 20px;
    padding: 0 15px;
  }

  .landcomp_questions__block .right .block .master {
    flex-direction: column;
  }

  .landcomp_questions__block .right .block .master .for_img {
    margin-right: 0;
  }

  .landcomp_questions__block .right .block .action::after {
    display: none;
  }

  .landcomp_questions__block .right .block .action {
    font-family: Museo Cyrl, Arial, sans-serif;
    outline: none;
    background: none;
    border: none;
    background: #FFF859;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    line-height: 130%;
    color: #000000;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    width: 290px;
    height: 70px;
    box-shadow: 0px 8px 24px rgba(255, 248, 89, 0.4);
    margin: 0 auto;
  }

  .landcomp_questions__block .right .block .action:hover {
    background: rgba(255, 248, 89, 0.8);
    animation-name: pulse;
    animation-duration: 1s;
    box-shadow: none;
  }

  .landcomp_questions__block .right .block .action::after {
    display: block;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(../img/main/highlight.png) no-repeat;
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 0.7;
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

@keyframes slideme {
    0% {
      left: -229px;
    }

    30% {
      left: 110%;
    }

    100% {
      left: 110%;
    }
}

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0.4);
    }

    70% {
      box-shadow: 0 0 0 2rem rgba(255, 248, 89, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 248, 89, 0);
    }
}

  .landcomp_certificates .flickity-viewport {
    width: 100%;
  }

  .landcomp_certificates .carousel-cell {
    width: 100%;
  }

  .landcomp_certificates .flickity-prev-next-button {
    top: calc(100% + 30px);
    z-index: 300;
  }

  .landcomp_certificates .counter {
    display: block;
  }

  .landcomp_certificates h2::before,
  .landcomp_certificates h2::after {
    display: none;
  }

  .landcomp_certificates__block::before {
    display: none;
  }

  .landcomp_certificates__block::after {
    display: none;
  }

  .landcomp_certificates__block .items {
    width: 100%;
  }

  .landcomp_certificates__block .items .item:nth-child(odd),
  .landcomp_certificates__block .items .item:nth-child(even) {
    margin: 0 40px;
  }

  .landcomp_footer {
    bottom: -630px;
    height: 630px;
  }

  .landcomp_footer__block {
    flex-direction: column;
    align-items: center;
  }

  .landcomp_footer__block .logo img {
    width: 63px;
    min-width: 63px;
    height: 58px;
  }

  .landcomp_footer__block .logo .title {
    font-size: 22px;
  }

  .landcomp_footer__block .logo .desc {
    font-size: 10px;
    width: 201px;
  }

  .landcomp_footer__block .logo {
    margin-bottom: 20px;
    transform: translateX(-20px);
  }

  .landcomp_footer__block .other_specializations .title {
    text-align: center;
  }

  .landcomp_footer__block .other_specializations .links a {
    line-height: 310%;
  }

  .landcomp_footer__block .time {
    width: 290px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .landcomp_footer__block .time .text {
    display: flex;
  }

  .landcomp_footer__block .time .text * {
    font-size: 16px;
    line-height: 18px;
  }

  .landcomp_footer__block .time .text .number {
    margin-right: 5px;
  }

  .landcomp_footer__block .time {
    margin-top: 30px;
  }

  .landcomp_footer__block .tel {
    width: 290px;
    margin: 0 auto;
  }

  .landcomp_footer__block .tel img {
    margin-right: 17px;
  }

  .landcomp_footer__block .tel .title {
    font-size: 22px;
  }

  #modal_accepted {
    width: 320px;
    background: url(../img/optional/accepted_xs.jpg) no-repeat;
    background-size: cover;
    padding: 70px 15px 0!important;
  }

  #modal_accepted .title {
    font-size: 20px;
  }

  #modal_call {
    width: 320px;
    background: url(../img/optional/call_xs.jpg) no-repeat;
    background-size: cover;
    padding: 100px 15px 0!important;
  }

  #modal_call .title {
    font-size: 20px;
  }

  #modal_call form .action::after {
    display: none;
  }

  #modal_call_sale {
    width: 320px;
    background: url(../img/optional/call_sale_xs.jpg) no-repeat;
    background-size: cover;
    padding: 100px 15px 0!important;
  }

  #modal_call_sale .title {
    font-size: 20px;
  }

  #modal_call_sale form .action::after {
    display: none;
  }

  .landcomp_404 {
    background: url(../img/404/back_xs.jpg) no-repeat;
    background-size: cover;
  }

  .landcomp_404 img.error {
    display: none;
  }

  .landcomp_404 img.xs_404 {
    display: block;
  }

  .landcomp_404 .text {
    font-size: 14px;
    line-height: 145%;
  }

  .landcomp_404 a::after {
    display: none;
  }
}

@media screen and (max-width: 768px) and screen and (max-width: 768px) {
  .landcomp__banner_main_form .action::after {
    display: none;
  }

  .landcomp__cost_block .item .desc button::after {
    display: none;
  }

  .landcomp_bid__left .landcomp_bid__form .action::after {
    display: none;
  }

  .landcomp_other_tasks__block .item .right .action::after {
    display: none;
  }

  .landcomp_questions__block .right .block .action::after {
    display: none;
  }
}