@import "entry";

.landcomp__breakdowns {
	padding-top: 50px;
	position: relative;
	.xs-block({
		display: none;
	});
	&::before {
		display: none;
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		top: 248px;
		width: 100%;
		height: calc(100% - 248px);
		background: @mainBack;
		.md-block({
			display: block;
		});
	}
	.wrapper {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			width: 246px;
			height: 191px;
			background: url(../img/breakdowns/pseudo_before.png) 0 0/cover no-repeat;
			top: 105px;
			left: -155px;
			z-index: -1;
			.lg-block({
				top: 62px;
				left: -99px;
			});
			.md-block({
				top: -32px;
				left: -56px;
			});
		}
		&::after {
			content: '';
			position: absolute;
			width: 323px;
			height: 328px;
			background: url(../img/breakdowns/pseudo_after.png) 0 0/cover no-repeat;
			top: -85px;
			right: 30px;
			z-index: -1;
			.lg-block({
				background: url(../img/breakdowns/pseudo_after_lg.png) 0 0/cover no-repeat;
				width: 341px;
				height: 328px;
				right: -72px;
			});
			.md-block({
				background: url(../img/breakdowns/pseudo_after_md.png) 0 0/cover no-repeat;
				width: 264px;
				height: 241px;
				right: -27px;
				top: -114px;
			});
		}
	}
	h2 {
		.h2();
		width: 455px;
		margin: 0 auto;
		margin-bottom: 20px;

		.pseudo() {
			position: absolute;
			content: '';
			height: 1px;
			width: 100px;
			background: linear-gradient(164.24deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
			border-radius: 50px;
			top: 25px;			
		}
		&::before {
			.pseudo();
			left: -130px;
		}
		&::after {
			.pseudo();
			right: -130px;
		}
	}
	.desc {
		font-weight: 300;
		font-size: 22px;
		line-height: 130%;
		text-align: center;
		color: @twoColor;
		margin-bottom: 80px;
		.md-block({
			margin-bottom: 60px;
		});
	}
	.tabs {
		display: flex;
		li {
			.color() {
				font-weight: 700;
				font-size: 18px;
				line-height: 130%;
				border-radius: 100px 100px 0 0;
				&:hover {
					animation: unset;
				}
			}

			.button_std(50%; 60px; @optiColor; .5;);
			color: #ffffff;
			.color();
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:after {
				animation-duration: 2.5s;
				background: url(../img/breakdowns/highlight.png) no-repeat;
			}

			&.active {
				.button_std(50%; 60px; @button; .7;);
				color: @fontColor;
				.color();
				&:after {
					animation-delay: 2s;
					background: url(../img/breakdowns/highlight.png) no-repeat;
				}
			}
		}
	}
	.tab_container {
		background: @mainBack;
		padding: 0 40px 55px 40px;
		.md-block({
			padding: 0 0 55px;
		});

		.show {
			.button_unset();
			margin: 0 auto;
			margin-top: 63px;
			font-weight: 700;
			font-size: 16px;
			line-height: 126%;
			color: @twoColor;
			z-index: 1;
			height: 60px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;
			position: relative;
			transition: .3s;

			&.less {
				&::before {
					top: 10px;
				}
				&::after {
					background: url(../img/breakdowns/arrow_up.svg) no-repeat;
					background-size: contain;
					top: -20px;
				}
				&:hover {
					color: @optiColor;
					&::before {
						top: 0;
					}
					&::after {
						top: -30px;
					}
				}
			}

			&::before {
				content: '';
				position: absolute;
				width: 115px;
				height: 20px;
				background: #FFFFFF;
				border-radius: 50px;
				z-index: -1;
				top: -10px;
				transition: .3s;
			}
			&::after {
				content: '';
				position: absolute;
				background: url(../img/breakdowns/arrow_down.svg) no-repeat;
				background-size: contain;
				width: 22px;
				height: 11px;
				z-index: -1;
				top: 30px;
				transition: .3s;
			}
			&:hover {
				color: @optiColor;
				&::before {
					top: 0;
				}
				&::after {
					top: 40px;
				}
			}
		}

		.tab_content {
			display: none;
			flex-wrap: wrap;
		}
		.tab_content:first-child {
			display: flex;
		}
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 380px;
			height: 600px;
			padding: 30px 40px 50px 40px;
			margin-right: 53px;
			margin-top: 40px;
			background: #ffffff;
			.lg-block({
				&:nth-child(odd), &:nth-child(even) {
					margin-right: 20px;
				}
				width: 456px;
				margin-right: 20px;
				margin-top: 20px;
				&:nth-child(-n+2) {
					margin-top: 30px;
				}
				&:nth-child(2n) {
					margin-right: 0;
				}
			});
			.md-block({
				&:nth-child(odd), &:nth-child(even) {
					margin-right: 20px;
				}
				width: 355px;
				margin-right: 14px;
				margin-top: 14px;
				&:nth-child(-n+2) {
					margin-top: 45px;
				}
				&:nth-child(2n) {
					margin-right: 0;
				}
			});
			&:nth-child(3n) {
				margin-right: 0;
			}
			.for_img {
				width: 120px;
				height: 128px;
				min-height: 128px;
				display: flex;
				justify-content: center;
				align-items: center;
			    margin-bottom: 15px;
			}
			.title {
				font-weight: 700;
				font-size: 26px;
				line-height: 106%;
				width: 300px;
				color: @twoColor;
				margin: 0 auto 0 0;
				margin-bottom: 15px;
			}
			.text {
				font-weight: 300;
				font-size: 16px;
				line-height: 143%;
				color: @fontColor;
				&.many {
					font-size: 14px;
				}
				p {
					margin-bottom: 15px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			button {
				.button_std(300px; 50px);
				margin: auto 0 0 0;
				&:after {
					background: url(../img/cost/highlight.png) no-repeat;
				}
			}
		}
	}
}







.landcomp__breakdowns__mob {
	display: none;
	padding-top: 50px;
	padding-bottom: 50px;
	.xs-block({
		display: block;
	});
	.wrapper {
		padding: 0;
	}
	.flickity-viewport {
		width: 100%;
	}
	.carousel-cell {
		width: 100%;
	}
	.flickity-prev-next-button {
		top: 100%;
		z-index: 300;
	}
	.flickity-prev-next-button.previous {
	    left: 15px;
	}
	.flickity-prev-next-button.next {
	    right: 25px;
	}
	h2 {
		.h2();
		width: 100%;
		margin: 0 auto;
		margin-bottom: 20px;
		padding: 0 15px;
	}
	.desc {
		font-weight: 300;
		font-size: 22px;
		line-height: 130%;
		text-align: center;
		color: @twoColor;
		margin-bottom: 60px;
		padding: 0 15px;
	}
	.counter {
		.counter();
		display: block;
		transform: translateY(-40px);
	}
	.item {
		background: transparent;
		transition: .3s;
		cursor: pointer;
		position: relative;
		&:not(:first-child) {
			margin-top: 20px;
			.xs-block({
				margin-top: 60px;
			});
		}
		.title {
			font-weight: 700;
			font-size: 24px;
			line-height: 126%;
			color: @optiColor;
			text-align: center;
			position: relative;
		}
		.slider {
			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 620px;
				padding: 0 15px;
				margin-right: 53px;
				margin-top: 10px;
				background: #ffffff;
				.for_img {
					width: 120px;
					height: 128px;
					min-height: 128px;
					display: flex;
					justify-content: center;
					align-items: center;
				    margin-bottom: 15px;
				}
				.title {
					font-weight: 700;
					font-size: 26px;
					line-height: 106%;
					width: 300px;
					color: @twoColor;
					margin: 0 auto 25px;
					margin-bottom: 15px;
					box-shadow: none;
					background: none;
				}
				.text {
					font-weight: 300;
					font-size: 16px;
					line-height: 143%;
					color: @fontColor;
					&.many {
						font-size: 14px;
					}
					p {
						margin-bottom: 15px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				button {
					.button_std(290px; 55px);
					margin: auto 0 0 0;
					margin-bottom: 40px;
					&:after {
						background: url(../img/cost/highlight.png) no-repeat;
					}
				}
			}
		}
	}
}