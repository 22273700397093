@import "entry";

.landcomp__brands {
	padding-bottom: 60px;
	.md-block({
		padding-bottom: 25px;
	});
	h3 {
		.h2();
		.md-block({
			margin-bottom: 25px;
		});
		&::before {
			.pseudo_lines();
			left: 0;
			top: 22px;
			.lg-block({
				.pseudo_lines(110px);	
			});	
			.md-block({
				.pseudo_lines(50px);	
				top: 16px;	
			});	
			// .xs-block({
				// display: none;
			// });
		}
		&::after {
			.pseudo_lines();
			right: 0;
			top: 22px;
			.lg-block({
				.pseudo_lines(110px);		
			});		
			.md-block({
				.pseudo_lines(50px);	
				top: 16px;	
			});	
			// .xs-block({
			// 	display: none;
			// });
		}
	}


	.slider {
		.item {
			width: 145px;
			height: 85px;
			margin-right: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px 10px;
			filter: grayscale(100%);
			opacity: .7;
			transition: .3s;
			&:hover {
				filter: grayscale(0);
				opacity: 1;
			}
			.md-block({
				margin-right: 50px;
				});
			.xs-block({
				width: 140px;
				height: 70px;
				margin-right: 10px;
				margin-left: 10px;
			});
		}
	}
}