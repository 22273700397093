@import "entry";

.landcomp_certificates {
	padding-bottom: 100px;
	.lg-block({
		padding-bottom: 60px;
	});
	.xs-block({
		.flickity-viewport {
			width: 100%;
		}
		.carousel-cell {
			width: 100%;
		}
		.flickity-prev-next-button {
			top: calc(100% + 30px);
			z-index: 300;
		}
	});
	.counter {
		.counter();
		transform: translateY(-10px);
		display: none;
		.xs-block({
			display: block;
		});
	}
	h2 {
		.h2();
		&::before {
			.pseudo_lines(210px);
			left: 0;
			top: 22px;	
			.lg-block({
				.pseudo_lines(160px);	
			});
			.md-block({
				.pseudo_lines(70px);	
				top: 16px;	
			});
		}
		&::after {
			.pseudo_lines(210px);
			right: 0;
			top: 22px;
			.lg-block({
				.pseudo_lines(160px);	
			});
			.md-block({
				.pseudo_lines(70px);	
				top: 16px;	
			});
		}
	}
	&__block {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			background: url(../img/certificates/back_1.png) no-repeat;
			background-size: cover;
			width: 375px;
			height: 887px;
			top: 0;
			left: -150px;
			z-index: -1;
			.md-block({
				background: url(../img/certificates/back_1_md.png) no-repeat;
				background-size: cover;
				width: 309px;
				height: 730px;
			});
			.xs-block({
				display: none;
			});
		}
		&::after {
			content: "";
			position: absolute;
			background: url(../img/certificates/back_2.png) no-repeat;
			background-size: cover;
			width: 422px;
			height: 242px;
			top: 0;
			right: -135px;
			z-index: -1;
			.md-block({
				background: url(../img/certificates/back_2_md.png) no-repeat;
				background-size: cover;
				width: 269px;
				height: 154px;
			});
			.xs-block({
				display: none;
			});
		}
		.items {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: 934px;
			margin: 0 auto;
			.md-block({
				width: 634px;
			});
			.xs-block({
				width: 100%;
			});
			.item {
				box-shadow: 0 30px 40px rgba(0,0,0,.14);
				width: 292px;
				height: 418px;
				margin-right: 29px;
				position: relative;
				z-index: 2;
				.lg-block({
					width: 238px;
					height: 340px;
					margin-right: 20px;
					box-shadow: 0 10px 20px rgba(0,0,0,.14);
				});
				.md-block({
					width: 200px;
					height: 285px;
					margin-right: 17px;
				});
				&:nth-child(3n) {
					margin-right: 0;
				}
				&:nth-child(-n+3) {
					margin-bottom: 30px;
					.md-block({
						margin-bottom: 17px;
					});
				}
				.xs-block({
					&:nth-child(odd), &:nth-child(even) {
						margin: 0 40px;
					}
				});
			}
		}
	}
}