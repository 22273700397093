@import "entry";

.landcomp__diagnostics {
	padding-top: 100px;
	padding-bottom: 95px;
	.lg-block({
		padding-top: 65px;
	});
	.md-block({
		padding-top: 40px;
	});
	.xs-block({
		padding-top: 60px;
		padding-bottom: 40px;
	});
	.wrapper {
		position: relative;
		.xs-block({
			padding: 0;
		});
		&::before {
			content: '';
			position: absolute;
			width: 431px;
			height: 431px;
			background: url(../img/diagnostics/circle_left.png) 0 0/cover no-repeat;
			left: -168px;
			bottom: -81px;
			z-index: -1;
			.md-block({
				bottom: 100px;
			});
			.xs-block({
				display: none;
			});
		}
		&::after {
			content: '';
			position: absolute;
			width: 323px;
			height: 323px;
			background: url(../img/diagnostics/circle_right.png) 0 0/cover no-repeat;
			top: 35px;
			right: -125px;
			z-index: -1;
			.lg-block({
				top: 39px;
				right: -22px;
			});
			.md-block({
				top: 28px;
				right: -32px;
				width: 210px;
				height: 210px;
				background: url(../img/diagnostics/circle_right.png) 0 0/cover no-repeat;	
			});
			.xs-block({
				display: none;
			});
		}
	}
	h3 {
		.h2();
		margin-bottom: 20px;
		.xs-block({
			padding: 0 15px !important;
		});
	}
	.desc {
		font-weight: 300;
		font-size: 22px;
		line-height: 130%;
		text-align: center;
		color: @twoColor;
		.xs-block({
			font-size: 18px;
			width: 180px;
			margin: 0 auto;
		});
	}
	&_block {
		background: url(../img/diagnostics/back.png) no-repeat;
		background-size: cover;
		width: 1326px;
		height: 593px;
	    filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.05));

	    margin: 0 auto;
	    margin-top: 60px;
		position: relative;
		padding: 72px 90px 50px 90px;
		.lg-block({
			background: url(../img/diagnostics/back_lg.png) no-repeat;
			background-size: cover;
			width: 1012px;
		});
		.md-block({
			background: url(../img/diagnostics/back_md.png) no-repeat;
			background-size: cover;
			width: 730px;
			height: 780px;
			padding: 0 40px;
			padding-top: 90px;
		});
		.xs-block({
			background: url(../img/diagnostics/back_xs.png) no-repeat;
			background-size: cover;
			width: 320px;
			height: auto;
			padding: 90px 40px 400px 40px;
			padding-top: 90px;
			margin-top: 100px;
		});
		.img_count {
			position: absolute;
			width: 250px;
			height: 250px;
			left: 48px;
			top: -125px;
			z-index: -1;
			transition: .3s;
			.md-block({
				left: 61px;
				top: -65px;
				width: 129px;
				height: 129px;
			});
			.xs-block({
				left: 100px;
			});

			&.num_1 {
				background: url(../img/diagnostics/counter_1.png) 0 0/cover no-repeat;
				.md-block({
					background: url(../img/diagnostics/counter_1_md.png) 0 0/cover no-repeat;
				});
				// .xs-block({
				// 	background: url(../img/diagnostics/counter_1_xs.png) 0 0/cover no-repeat;
				// });
			}
			&.num_2 {
				background: url(../img/diagnostics/counter_2.png) 0 0/cover no-repeat;
				.md-block({
					background: url(../img/diagnostics/counter_2_md.png) 0 0/cover no-repeat;
				});
				// .xs-block({
				// 	background: url(../img/diagnostics/counter_2_xs.png) 0 0/cover no-repeat;
				// });
			}
			&.num_3 {
				background: url(../img/diagnostics/counter_3.png) 0 0/cover no-repeat;
				.md-block({
					background: url(../img/diagnostics/counter_3_md.png) 0 0/cover no-repeat;
				});
				// .xs-block({
				// 	background: url(../img/diagnostics/counter_3_xs.png) 0 0/cover no-repeat;
				// });
			}
			&.num_4 {
				background: url(../img/diagnostics/counter_4.png) 0 0/cover no-repeat;
				.md-block({
					background: url(../img/diagnostics/counter_4_md.png) 0 0/cover no-repeat;
				});
				// .xs-block({
				// 	background: url(../img/diagnostics/counter_4_xs.png) 0 0/cover no-repeat;
				// });
			}
		}
		&::after {
			content: '';
			position: absolute;
			width: 333px;
			height: 361px;
			background: url(../img/diagnostics/ammeter.png) 0 0/cover no-repeat;
			top: -250px;
			right: -30px;
			z-index: -1;
			.lg-block({
				top: -235px;
				right: -97px;
			});
			.md-block({
				background: url(../img/diagnostics/ammeter_md.png) 0 0/cover no-repeat;
				width: 240px;
				height: 262px;
				top: -170px;
				right: -80px;
			});
			.xs-block({
				display: none;
			});
		}
		.title {
			text-align: center;
			font-weight: 700;
			font-size: 26px;
			line-height: 126%;
			color: @twoColor;
			margin-bottom: 125px;
			.md-block({
				margin-bottom: 90px;
			});
			.xs-block({
				font-size: 22px;
				margin-bottom: 50px;
				width: 250px;
			});
		}
		.start {
			display: flex;
			justify-content: space-between;

			.md-block({
				flex-wrap: wrap;
				height: 500px;	
				justify-content: space-around;
			});
			&.opti {
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: flex-start;
				height: 360px;
				margin-top: -40px;
				transition: .3s;
				.md-block({
					margin-top: 25px;
				});
				.xs-block({
					height: auto;
				});
			}

			.item_start {
				display: flex;
				flex-direction: column;
			    justify-content: space-around;
				align-items: center;
				position: relative;
				width: 220px;
				height: 220px;
				cursor: pointer;
				.lg-block({
					width: 170px;
					height: 170px;
				});
				.md-block({
					width: 220px;
					height: 220px;
				});

				&:hover {
					.title {
						transition: color .2s;
						color: @optiColor;
					}
					&::before {
						transition: .3s;
						background: url(../img/diagnostics/pseudo_before_active.png) 0 0/cover no-repeat;
					}
					.for_img {
					    filter: drop-shadow(0 20px 25px rgba(0, 0, 0, .2));
					}
					&::after {
						animation-name: pulse_circle;
						animation-duration: .4s;
					}
				}
				&::before {
					content: '';
					position: absolute;
					width: 220px;
					height: 110px;
					background: url(../img/diagnostics/pseudo_before.png) 0 0/cover no-repeat;
					left: 0;
					top: 0;
					z-index: -1;
					.lg-block({
						width: 170px;
						height: 85px;
					});
					.md-block({
						width: 220px;
						height: 110px;
					});
				}
				&::after {
					content: '';
					position: absolute;
					width: 1px;
					height: 1px;
					background: red;
					border-radius: 50%;
					left: calc(220px / 2);
					top: 110px;
					z-index: -1;
					.lg-block({
						left: calc(170px / 2);
						top: 85px;
					});
					.md-block({
						left: calc(220px / 2);
						top: 110px;
					});
				}
				.for_img {
					width: 196px;
					height: 140px;
					display: flex;
					justify-content: center;
					align-items: center;
					.lg-block({
						width: 160px;
						height: 120px;
					});
				}
				.title {
					font-weight: 700;
					font-size: 18px;
					line-height: 126%;
					color: @twoColor;
					margin: 0;
				}
			}
			.item {
				width: 25%;
				cursor: pointer;
				list-style-type: none;
				padding: 0;
				margin: 0;
				padding-left: 38px;
				position: relative;
				.md-block({
					width: 33%;
				});
				.xs-block({
					width: 100%;
					margin-bottom: 50px;
					&:last-child {
						display: none;
					}
				});
				.title {
					font-weight: 500;
					font-size: 18px;
					line-height: 126%;
					color: @twoColor;
					padding: 0;
					margin: 0;
					width: 200px;
					text-align: left;
					.lg-block({
						font-size: 16px;
						width: 160px;
					});
				}
				&:hover {
					.title {
						transition: color .2s;
						color: @optiColor;
					}
					&::before {
						background: url(../img/diagnostics/item_active.png) no-repeat;
						background-size: cover;
						transition: .3s;
					}
				}
				&::before {
					content: '';
					position: absolute;
					width: 25px;
					height: 25px;
					background: url(../img/diagnostics/item_std.png) no-repeat;
					background-size: cover;
					left: 0;
					top: 0;
				}
			}
		}
	}


	.fade-enter{
	    opacity: 0;
	}

	.fade-enter-active{
	    transition: .2s;
	}

	.fade-enter-to{
	    
	}

	.fade-leave{
	    
	}

	.fade-leave-active{
	    transition: .2s;
	}

	.fade-leave-to{
	    opacity: 0;
	}

}



@keyframes pulse_circle {
	0% {
		box-shadow: 0 0 0 0 fade(@optiColor, 40%);
	}
	70% {
		box-shadow: 0 0 0 150px fade(@optiColor, 0%);
	}
	100% {
		box-shadow: 0 0 0 0 fade(@optiColor, 0%);
	}		
}