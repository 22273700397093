@import "entry";

.landcomp__advantages {
	padding-top: 50px;
	padding-bottom: 100px;
	.md-block({
		padding-top: 0;
		padding-bottom: 60px;
	});
	.xs-block({
		padding-top: 40px;

		.flickity-viewport {
			width: 100%;
		}
		.carousel-cell {
			width: 100%;
		}
		.flickity-prev-next-button {
			top: calc(100% + 40px);
		}
	});
	&_block {
		display: flex;
		justify-content: space-between;
		.item {
			.xs-block({
				width: 100%;
			});
			.for_img {
				width: 304px;
				height: 274px;
				display: flex;
				justify-content: center;
				align-items: center;
				.lg-block({
					width: 230px;
					height: 200px;
				});
				.md-block({
					width: 170px;
					height: 140px;
				});
				.xs-block({
					width: 230px;
					height: 200px;
					margin: 0 auto;
				});
			}
			.text {
				color: @fontColor;
				font-weight: 500;
				font-size: 18px;
				line-height: 130%;
				text-align: center;
				width: 315px;
				.lg-block({
					font-size: 16px;
					width: 230px;
				});
				.md-block({
					font-size: 14px;
					width: 168px;
					br {
						display: none;
					}
				});
				.xs-block({
					font-size: 18px;
					width: 290px;
					margin: 0 auto;
				});
			}
		}
	}
	.counter {
		.counter();
		display: none;
		.xs-block({
			display: block;
		});
	}
}