@import "entry";

@keyframes fixed {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.tel() {
    display: none;
    align-items: center;
    padding: 0;
    margin: 0;
    &:hover {
        .title {
            transition: .3s;
            color: @optiColor;
        }
    }
    img {
        margin-right: 20px;
        .lg-block({
            margin-right: 10px;
        });
    }
    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: @twoColor;
        .lg-block({
            font-size: 16px;
        });
    }
    &::before {
        display: none;
    }
}

.landcomp__header {
	border-bottom: 1px solid #EEF1F8;
    &.fixed {
        animation: fixed .5s ease;
        transition: .5s;
        position: fixed;
        background: #ffffff;
        z-index: 600;
        width: 100%;

        .back_fixed {
            animation: fixed .5s ease;
            transition: .5s;
            display: block;
        }
    }
    .md-block({
        padding: 6px 0;
    });
    .wrapper {
        .md-block({
            display: flex;
            justify-content: space-between;
        });
    }
    .back_fixed {
        display: none;
        box-shadow: 0 5px 10px rgba(0,0,0,.10);
        height: 42px;
        width: 100%;
        background: #ffffff;
        position: fixed;
        z-index: -1;
        left: 0;
        right: 0;
    }
    .mob_menu_action {
        display: none;
        .md-block({
            display: block;
        });
    }
    nav {
    	display: flex;
        .md-block({
            display: none;
        });
        &.fixed {
            animation: fixed .5s ease;
            transition: .5s;
            position: fixed;
            background: #ffffff;
            z-index: 300;

            .back_fixed {
                animation: fixed .5s ease;
                transition: .5s;
                display: block;
            }
            .tel {
                display: flex;
            }
        }
        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: @twoColor;
            margin-right: 60px;
            padding: 12px 0;
            position: relative;

            .lg-block({
                font-size: 13px;
                line-height: 16px;
                margin-right: 27px;
                padding: 13px 0 12px 0;
            });

            &::before {
            	content: '';
            	position: absolute;
            	width: 100%;
                height: 3px;
                background: @optiColor;
                bottom: -1px;
                opacity: 0;
            }
            &:hover {
            	transition: color .2s;
            	color: @optiColor;

            	&::before {
            		transition: .2s;
            		opacity: 1;
            	}	
            }
        }
        .tel {
            .tel();
        }
    }

    .addr {
        display: none;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        .md-block({
            display: flex;
        });
        .xs-block({
            display: none;
        });
    }

    .tel {
        display: none;
        align-items: center;
        padding: 0;
        margin: 0;
        .md-block({
            display: flex;
        });
        &:hover {
            .title {
                transition: .3s;
                color: @optiColor;
            }
        }
        img {
            margin-right: 10px;
        }
        .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 29px;
            color: @twoColor;
        }
    }

    .mob_menu_action {
        display: none;
        align-items: center;
        cursor: pointer;
        img {
            margin-right: 15px;
            display: none;
            &.show {
                display: block;
            }
        }
        .md-block({
            display: flex;
        });
    }
}