@import "entry";

.landcomp_repair {
	.lg-block({
		padding-bottom: 170px;
	});
	.md-block({
		padding-bottom: 50px;
	});
	.xs-block({
		padding-bottom: 0;
	});
	.wrapper {
		display: flex;
		justify-content: space-between;
		.xs-block({
			padding: 0;
			flex-direction: column;
		});
	}
	&__left {
		padding-top: 72px;
		.lg-block({
			padding-top: 60px;	
		});
		.xs-block({
			width: 100%;
			padding: 0 15px;
			padding-top: 50px;
		});
		h3 {
			.h2();
			text-align: left;
			margin-bottom: 30px;
			.xs-block({
				padding: 0 15px;
				text-align: center;
			});
		}
		.desc {
			font-weight: 300;
			font-size: 26px;
			line-height: 126%;
			color: @twoColor;
			margin-bottom: 100px;
			.md-block({
				font-size: 20px;
				text-align: center;
			});
		}
		.text {
			font-weight: 300;
			font-size: 18px;
			line-height: 150%;
			color: @fontColor;
			width: calc(475px + 120px);
			padding-left: 120px;
			position: relative;
			.lg-block({
				font-size: 16px;
				padding-left: 80px;
				width: calc(420px + 80px);
			});
			.md-block({
				padding-left: 0;
				width: 417px;
			});
			.xs-block({
				width: 100%;
			});
			&::before {
				content: "";
				position: absolute;
				background: url(../img/repair/back_left.png) no-repeat;
				background-size: cover;
				width: 898px;
				height: 633px;
				top: -90px;
				left: 0;
				z-index: -1;
				.lg-block({
					background: url(../img/repair/back_left_lg.png) no-repeat;
					background-size: cover;
					width: 802px;
					height: 640px;
					left: -100px;
				});
				.md-block({
					background: url(../img/repair/back_left_md.png) no-repeat;
					background-size: cover;
					width: 720px;
					height: 560px;
					left: -125px;
				});
				.xs-block({
					background: url(../img/repair/left_quot.png) no-repeat;
					background-size: cover;
					width: 49px;
					height: 33px;
					top: -53px;
					left: 0;
				});
			}
			&::after {
				display: none;
				content: "";
				position: absolute;
				background: url(../img/repair/right_quot.png) no-repeat;
				background-size: cover;
				width: 49px;
				height: 33px;
				right: 0;
				bottom: -53px;
				.xs-block({
					display: block;
				});
			}
			p {
				&:first-child {
					margin-bottom: 15px;
				}
				&:nth-child(2) {
					margin-bottom: 30px;
				}
				&:nth-child(3) {
					margin-bottom: 40px;
					.md-block({
						margin-bottom: 20px;
					});
				} 
			}
		}
		.name {
			font-size: 16px;
			line-height: 180%;
			font-weight: 500;
			color: @fontColor;
			margin-bottom: 15px;
			.md-block({
				margin-bottom: 5px;
			});
		}
		.desc_name {
			font-weight: 500;
			font-size: 14px;
			line-height: 160%;
			color: #919191;
			width: 308px;
			.xs-block({
				width: 100%;
			});
		}
	}
	&__right {
		padding-top: 34px;		
		position: relative;
		.lg-block({
			display: flex;
		});
		img {
			min-width: auto;
			&.lg {
				min-width: 441px;
				transform: translateY(170px);
			}
			&.md {
				min-width: 293px;
				height: 510px;
				transform: translateY(187px) translateX(30px);
			}
			&.xs {
				min-width: auto;			
				margin: 0 auto;
			}
		}
		&::before {
			content: "";
			position: absolute;
			background: url(../img/repair/pseudo_1.png) no-repeat;
			background-size: cover;
			width: 312px;
			height: 253px;
			top: -70px;
			left: -170px;
			z-index: 1;
			.lg-block({
				left: -70px;
			});
			.md-block({
				top: -80px;
				left: -25px;
			});
			.xs-block({
				display: none;
			});
		}
		&::after {
			content: "";
			position: absolute;
			background: url(../img/repair/pseudo_2.png) no-repeat;
			background-size: cover;
			width: 267px;
			height: 316px;
			top: 70px;
			right: -120px;
			z-index: 1;
			.lg-block({
				top: 15px;
				right: -130px;
			});
			.md-block({
				background: url(../img/repair/pseudo_2_md.png) no-repeat;
				background-size: cover;
				width: 134px;
				height: 272px;
				top: 50px;
				left: 250px;
			});
			.xs-block({
				display: none;
			});
		}
		&.other {
			&::before {
				content: "";
				position: absolute;
				background: url(../img/repair/pseudo_1_2.png) no-repeat;
				background-size: cover;
				width: 312px;
				height: 253px;
				top: -70px;
				left: -170px;
				z-index: 1;
				.lg-block({
					left: -70px;
				});
				.md-block({
					top: -80px;
					left: -25px;
				});
				.xs-block({
					display: none;
				});
			}
			&::after {
				content: "";
				position: absolute;
				background: url(../img/repair/pseudo_2_2.png) no-repeat;
				background-size: cover;
				width: 267px;
				height: 316px;
				top: 70px;
				right: -120px;
				z-index: 1;
				.lg-block({
					top: 15px;
					right: -130px;
				});
				.md-block({
					background: url(../img/repair/pseudo_2_md_2.png) no-repeat;
					background-size: cover;
					width: 134px;
					height: 272px;
					top: 50px;
					left: 250px;
				});
				.xs-block({
					display: none;
				});
			}
		}
	}
}