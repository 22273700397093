@import "entry";

.landcomp_questions {
	padding-top: 60px;
	padding-bottom: 100px;
	.lg-block({
		padding-top: 30px;
	});
	.md-block({
		padding-top: 0;
	});
	.wrapper {
		.xs-block({
			padding: 0;
		});
	}
	h2 {
		.h2();
		text-align: left;
		margin-bottom: 60px;
		.md-block({
			font-size: 36px;
			text-align: center;
			span {
				font-size: 36px;
			}
		});
		.xs-block({
			font-size: 26px;
			span {
				font-size: 26px;
			}
		});
	}
	&__block {
		display: flex;
		.md-block({
			flex-direction: column;
		});
		.left {
			.items {
				width: 758px;
				min-width: 758px;
				margin-right: 30px;
				.lg-block({
					width: 600px;
					min-width: 600px;
				});
				.md-block({
					width: 730px;
				});
				.xs-block({
					width: 100%;
					min-width: 100%;
				});
				.item {
					background: transparent;
					transition: .3s;
					cursor: pointer;
					position: relative;
					&:not(:first-child) {
						margin-top: 20px;
						.xs-block({
							margin-top: 15px;
						});
					}
					.title {
						font-weight: 500;
						font-size: 18px;
						line-height: 126%;
						color: #404040;
						background: #FFFFFF url(../img/questions/quest_back.png) no-repeat;
						background-size: cover;
						box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
						border-radius: 65px;
						padding: 0 45px;
						height: 80px;
						display: flex;
						align-items: center;
						overflow: hidden;
						position: relative;
						.lg-block({
							font-size: 16px;
							height: 90px;
						});
						.xs-block({
							border-radius: 0;
							height: auto;
						});
						.xs-block({
							padding: 20px 15px;
						});
						&:hover {
							background: @optiColor;
							background-size: cover;
							color: #FFFFFF;
							box-shadow: 0px 30px 40px rgba(255, 131, 61, 0.2);
							transition: background .3s, color .1s;
						}
						&.active {
							color: #FFFFFF;
							background: @optiColor;
							background-size: cover;
							box-shadow: none;

							&::after {
								display: block;
								content: "";
								z-index: 1;
								width: 100%;
								height: 100%;
								background: url(../img/questions/quest_back_active.png) no-repeat;
								position: absolute;
								top: 0;
								left: -40px;

								transition: all 0.1s;
								animation-name: slideme;
								animation-duration: 3s;
								animation-delay: 0.05s;
								animation-timing-function: ease-in-out;
								animation-iteration-count: infinite;
							}
							@keyframes slideme {
								0% {left: -229px;}
								30% {left: 110%;}
								100% {left: 110%;} 
							}
						}
					}
					.text {
						font-weight: 300;
						font-size: 16px;
						line-height: 150%;
						color: @fontColor;
						overflow-y: auto;
						display: none;
						padding: 30px 40px;
						.lg-block({
							padding: 20px 44px;
						});
						.xs-block({
							padding: 20px 15px;
						});
					}
				}
			}
		}
		.right {
			margin-left: 135px;
			position: relative;
			.lg-block({
				margin-left: 55px;
			});
			.md-block({
				margin: 0;
			});
			.xs-block({
				margin-top: 50px;
			});
			&::before {
				content: "";
				position: absolute;
				background: url(../img/questions/back.png) no-repeat;
				background-size: cover;
				width: 1202px;
				height: 1167px;
				top: -291px;
				left: -281px;
				z-index: -1;
				.lg-block({
					background: url(../img/questions/back_lg.png) no-repeat;
					background-size: cover;
					top: -240px;
					left: -261px;
				});
				.md-block({
					background: url(../img/questions/back_md.png) no-repeat;
					background-size: cover;
				});
				.xs-block({
					background: url(../img/questions/back_xs.png) no-repeat;
					background-size: cover;
					width: 1094px;
					height: 1061px;
					top: -200px;
					left: -330px;
				});
			}
			.block {
				.md-block({
					display: flex;
					flex-direction: column;
					justify-content: center;
				});
				.title {
					font-weight: 300;
					font-size: 26px;
					line-height: 126%;
					color: @twoColor;
					margin-bottom: 33px;
					.lg-block({
						font-size: 20px;
						text-align: center;
						width: 334px;
					});
					.md-block({
						font-size: 26px;
						width: 500px;
						margin: 0 auto;
						margin-bottom: 25px;	
					});
					.xs-block({
						width: 100%;
						font-size: 20px;
						padding: 0 15px;
					});
				}
				.master {
					display: flex;
					align-items: flex-end;
					.lg-block({
						flex-direction: column;
						align-items: center;
						justify-content: center;
						transform: translateX(0);
					});
					.md-block({
						flex-direction: row;
						margin-bottom: 45px;
					});
					.xs-block({
						flex-direction: column;
					});
					.for_img {
						width: 237px;
						height: 237px;
						margin-right: 20px;
						.xs-block({
							margin-right: 0;
						});
					}
					.text {
						padding-bottom: 20px;
						.lg-block({
							text-align: center;
						});
						.md-block({
							text-align: left;
						});
						.name {
							font-weight: 500;
							font-size: 16px;
							line-height: 180%;
							color: @fontColor;
							margin-bottom: 15px;
							.lg-block({
								margin-top: 15px;
								margin-bottom: 10px;
							});
						}
						.desc {
							width: 218px;
							font-weight: 500;
							font-size: 14px;
							line-height: 160%;
							color: #919191;
						}
					}
				}
				.action	 {
					.button_std(452px; 70px);
					margin-top: 80px;
					.lg-block({
						.button_std(324px; 70px);
						margin-top: 20px;
					});
					.md-block({
						.button_std(450px; 70px);
						margin: 0;
					});
					.xs-block({
						.button_std(290px; 70px);
						margin: 0 auto;
					});
				}
			}
		}
	}
}