@import "entry";

.landcomp__banner {
	height: 720px;
	padding-top: 14px;
	background: url(../img/main/back_main.jpg) no-repeat;
	background-size: cover;
	position: relative;
	&.other {
		background: url(../img/main/back_main_other.png) no-repeat;
		background-size: cover;
	}
	.lg-block({
		background: none;

		&::after {
			content: '';
			position: absolute;
			width: 1093px;
			height: 624px;
			background: url(../img/main/left_back_lg.png) no-repeat;
			background-size: cover;
			left: -400px;
			bottom: 0;
			z-index: -1;
		}
		&::before {
			content: '';
			position: absolute;
			width: 813px;
			height: 557px;
			background: url(../img/main/right_back_lg.jpg) no-repeat;
			background-size: cover;
			right: -200px;
			bottom: 0;
			z-index: -1;
		}
		&.other {
			&::before, &::after {
				display: none;
			}
			background: url(../img/main/back_main_other_lg.jpg) no-repeat;
			background-size: cover;
		}
	});
	.md-block({
		&::after {
			width: 1020px;
			height: 583px;
			background: url(../img/main/left_back_md.png) no-repeat;
			background-size: cover;
			left: -500px;
			bottom: 70px;
		}
		&::before {
			width: 590px;
			height: 401px;
			background: url(../img/main/right_back_md.jpg) no-repeat;
			background-size: cover;
			bottom: 70px;
		}
		&.other {
			&::before, &::after {
				display: none;
			}
			.logo,
			.time, .tel span,
			.tel span,
			.time,
			h1,
			.ter_text {
				color: #000000;
			}
		}
	});
	.xs-block({
		background: url(../img/main/back_mob.jpg) no-repeat;
		background-size: cover;
		height: 600px;
		&.other {
			background: #FAFAFA;
			height: 600px;

			&::after, &::before {
				display: none;
			}
		}

		&::after {
			display: none;
		}
		&::before {
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background: none;
			z-index: 0;
			background: rgba(0,0,0,0.5);
		}

	});
	.wrapper {
		position: relative;
	}
	&_top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 67px;
		z-index: 2;
		.md-block({
			margin-bottom: 50px;
		});
		.xs-block({
			flex-direction: column;
		});
		.logo {
			.logo();
			.xs-block({
				color: #ffffff;
				&:hover {
					transition: .2s;
					color: @optiColor;
				}
				margin: 0 auto;
				margin-bottom: 30px;
			});
		}
		.time {
			.time();
			.xs-block({
				color: #ffffff;
			});
		}
		.addr {
			.addr();
		}
		.tel {
			.tel();
			display: flex;
			.xs-block({
				display: flex;
				margin: 0 auto;
				.title {
					color: #fff;
				}
			});
		}
	}
	&_main {
		h1 {
			font-weight: 600;
			font-size: 36px;
			line-height: 126%;
			width: 890px;
			margin-bottom: 25px;
			.lg-block({
				font-size: 30px;
			});
			.md-block({
				font-size: 26px;
				width: 700px;
				margin-bottom: 20px;
			});
			.xs-block({
				z-index: 5;
				width: 100%;
				color: #ffffff;
				margin-right: 0;
				font-size: 20px;
				text-align: center;
				margin-bottom: 30px;
				span {
					font-size: 20px;
				}
				br {
					display: none;
				}
			});

			span {
				font-weight: 600;
				font-size: 36px;
				line-height: 126%;
				color: @optiColor;
				.lg-block({
					font-size: 30px;
				});
				.md-block({
					font-size: 26px;
				});
			}
		}
		.desc {
			font-weight: 500;
			font-size: 22px;
			line-height: 130%;
			width: 480px;
			margin-bottom: 54px;
			.md-block({
				font-size: 20px;
				width: 433px;
			});
			.xs-block({
				display: none;
			});
		}
		&_form {
			.xs-block({
				display: flex;
				justify-content: center;
			});
			.error_name {
				.error();
			}
			.error_tel {
				.error();
			}
			.input_item {
				&:first-child {
					margin-bottom: 30px;
				}
				input {
					.input(370px; 50px);
				}
				.xs-block({
					display: none;
				});
			}
			.action {
				.button_std();
				margin-top: 40px;
				.xs-block({
					.button_std(290px; 55px);
					margin-top: 35px;
				});
			}
		}
	}
	&_territory {
		position: absolute;
		bottom: 210px;
		left: 520px;
		display: flex;
		.lg-block({
			left: 450px;
			bottom: 180px;
		});
		.md-block({
			left: 400px;
			bottom: 160px;
		});
		.xs-block({
			display: none;
		});
		img {
			width: 12px;
			height: 16px;
			margin-right: 8px;
			margin-top: 1px;
		}
		.ter_text {
			color: @twoColor;
			line-height: 130%;
			.xs-block({
				color: #ffffff;
			});
			.ter_desc {
				font-weight: 500;
				font-size: 16px;
				.lg-block({
					line-height: 180%;
				});
				.md-block({
					font-size: 12px;
				});
			}
			.ter_title {
				font-weight: 900;
				font-size: 16px;
				.lg-block({
					line-height: 180%;
				});
				.md-block({
					font-size: 12px;
				});
				br {
					display: none;
					.lg-block({
						display: block;
					});
				}
			}
		}
	}

	&_territory__mob {
		display: none;
		width: 290px;
		margin: 0 auto;
		.xs-block({
			display: flex;

		});

		img {
			width: 12px;
			height: 16px;
			margin-right: 8px;
			margin-top: 1px;
		}
		.ter_text {
			color: #ffffff;
			line-height: 130%;
			.ter_desc {
				font-weight: 500;
				font-size: 16px;
			}
			.ter_title {
				font-weight: 900;
				font-size: 16px;
			}
		}
	}

}