@import "entry";

.landcomp_other_tasks {
	padding-top: 80px;
	padding-bottom: 60px;
	.md-block({
		padding-top: 60px;
	});
	.xs-block({
		.flickity-prev-next-button {
		    top: calc(100% + 40px);
		    z-index: 300;
		}
		.flickity-viewport {
		  transition: height 0.2s;
		}
		padding-bottom: 100px;
	});
	.counter {
		.counter();
		transform: translateY(0);
		display: none;
		.xs-block({
			display: block;
		});
	}
	h2 {
		.h2();
		&::before {
			.pseudo_lines(290px);
			left: 0;
			top: 22px;			
			.lg-block({
				.pseudo_lines(220px);
			});
			.md-block({
				.pseudo_lines(140px);
				top: 16px;	
			});
		}
		&::after {
			.pseudo_lines(290px);
			right: 0;
			top: 22px;
			.lg-block({
				.pseudo_lines(220px);
			});
			.md-block({
				.pseudo_lines(140px);
				top: 16px;	
			});
		}
	}
	&__block {
		.md-block({
			padding: 0 60px;
		});
		.xs-block({
			padding: 0;
		});
		.item {
			display: flex;
			width: 100%;
			.md-block({
				flex-direction: column;
				margin-right: 40px;
				margin-left: 40px;
			});
			.left {
				margin-right: 80px;
				margin-left: 85px;
				.lg-block({
					margin-right: 40px;
					margin-left: 40px;
				});
				.md-block({
					display: flex;
					margin: 0;
					height: 290px;
				});
				.xs-block({
					height: auto;
				});
				.for_img {
					min-width: 450px;
					height: 369px;
					margin-bottom: 40px;
					.lg-block({
						min-width: 380px;
						height: 300px;
					});
					.md-block({
						min-width: 320px;
						height: 290px;
					});
					.xs-block({
						display: none;
					});
				}
				.data {
					padding-left: 30px;
					.md-block({
						display: flex;
						flex-direction: column;
					});
					.xs-block({
						padding-left: 20px;
						width: 100%;
					});
					.tittle_md {
						display: none;
						font-weight: 700;
						font-size: 18px;
						line-height: 126%;
						color: @twoColor;
						margin-bottom: 13px;
						width: 256px;
						.md-block({
							display: block;
						});
						.xs-block({
							text-align: center;
							margin-bottom: 40px;
							width: 100%;
						});
					}
					.action {
						display: none;
						.button_std(260px; 55px);
						margin-top: 25px;
						.md-block({
							display: block;
						});
						.xs-block({
							display: none;
						});
					}
					li {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						color: @fontColor;
						margin-bottom: 20px;
						position: relative;
						&:last-child {
							margin-bottom: 0;
						}
						&::before {
							content: "";
							position: absolute;
							background: @optiColor;
							border-radius: 50%;
							background-size: cover;
							width: 6px;
							height: 6px;
							top: 6px;
							left: -30px;
							.md-block({
								left: -15px;
							});
						}
						span {
							font-weight: 700;
							font-size: 20px;
							line-height: 20px;
							color: @fontColor;
							.md-block({
								font-size: 16px;
							});
						}
					}
				}
			}
			.right {
				height: 369px;
				.md-block({
					margin-top: 25px;
					height: 280px;
				});
				.xs-block({
					height: auto;
					margin-top: 10px;
				});
				.text {
					height: 100%;
					width: 690px;
					.lg-block({
						width: 500px;
					});
					.xs-block({
						width: 100%;
					});
					.title {
						font-weight: 600;
						font-size: 26px;
						line-height: 126%;
						color: @twoColor;
						margin-bottom: 25px;
						.lg-block({
							font-size: 22px;
						});
						.md-block({
							display: none;
						});
					}
					.text() {
						font-weight: 300;
						font-size: 16px;
						line-height: 170%;
						color: @fontColor;
						.lg-block({
							font-size: 14px;
						});
						.md-block({
							width: 580px;
						});
						.xs-block({
							width: 100%;
						});
					}
					ul {
						margin: 0 0 auto 0;
						li {
							.text();
						}
					}
					p {
						.text();
						margin-top: 20px;
					}
				}
				.action {
					.button_std(452px; 70px);
					margin-top: 65px;
					.lg-block({
						margin-top: 15px;
					});
					.md-block({
						display: none;
					});
					.xs-block({
						display: block;
						.button_std(290px; 70px);
						margin: 0 auto;
						margin-top: 20px;
						margin-bottom: 25px;
					});
				}
			}
		}
	}
}



// flickity======================================================
.flickity-button {
    background: none;
    width: unset;
    height: unset;
}
.flickity-button:hover {
    background: none;
    svg circle {
    	fill: #FFF859;
    	transition: .3s;
    }
    &.flickity-prev-next-button {
    	filter: drop-shadow(0px 15px 24px rgba(255, 248, 89, 0.4));
    }
}
.flickity-prev-next-button {
    border-radius: 0;
    // width: 50px;
    width: 66px;
    height: 50px;
    top: 40%;
    transition: .3s;
    filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.2));

    // .lg-block({
    //     width: 60px;
    //     height: 66px;
    // });
}
.flickity-button-icon {
    display: none;
}
.flickity-prev-next-button.previous {
    left: -95px;

    .lg-block({
        left: -30px;
    });
    .md-block({
    	left: -10px;
    });
}
.flickity-prev-next-button.next {
    right: -95px;

    .lg-block({
        right: -10px;
    });
    .md-block({
    	right: 10px;
    });
}
// flickity======================================================