@import "smart-grid";

.reset();
.wrapper {
	.wrapper();
	box-sizing: border-box;
	.lg-block({
		max-width: 1080px;
	});
	.md-block({
		max-width: 768px;
	});
	.xs-block({
		max-width: 560px;
	});
}

.debug{
    .debug(rgba(0, 0, 0, 0.5), 1px solid #ff0);
    z-index: 999999999;
}

@font-face {
	font-family: Museo Cyrl; 
	src: url("../fonts/Museo Cyrl 300.otf") format("opentype");
	font-style: normal; 
	font-weight: 300; 
	font-display: fallback;
}
@font-face {
	font-family: Museo Cyrl; 
	src: url("../fonts/Museo Cyrl 500.otf") format("opentype");
	font-style: normal; 
	font-weight: 500; 
	font-display: fallback;
}
@font-face {
	font-family: Museo Cyrl; 
	src: url("../fonts/Museo Cyrl 700.otf") format("opentype");
	font-style: normal; 
	font-weight: 700; 
	font-display: fallback;
}
@font-face {
	font-family: Museo Cyrl; 
	src: url("../fonts/Museo Cyrl 900.otf") format("opentype");
	font-style: normal; 
	font-weight: 900; 
	font-display: fallback;
}

* {
	font-size: 16px;
	font-family: Museo Cyrl,Arial,sans-serif;
	box-sizing: border-box;
}
body {
	background: #fff;
	color: #000;
	font-family: Museo Cyrl, Arial, sans-serif;
	font-weight: bold;
	height: 100%;
	min-height: auto;
	position: relative;
	word-wrap: break-word;
	color: @fontColor;
	box-sizing: border-box;
	overflow-x: hidden;
	letter-spacing: 0.05em;
	// height: 17000px;
}


body > * {
	.xs-block({
		overflow: hidden;
	})
}

strong {
	font-weight: bold;
}

ul, li, a {
	font-family: Museo Cyrl; 
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none;
	color: #fff;
}
.wrapper h1, .wrapper h2, .wrapper h3, .wrapper h4, .wrapper h5 {  // ?
	padding: 0 !important;
	border-bottom: none !important;
}
.fancybox-slide--html {
	padding: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

[disabled] {
	cursor: not-allowed;	
}

// scrollbar===================================================================
::-webkit-scrollbar {
	width: 12px;
	.xs-block({
		width: 6px;
	});
} 
::-webkit-scrollbar-track {
	background: #fff;
	box-shadow: none; 
}
::-webkit-scrollbar-thumb {
	background: @optiColor;
	box-shadow: none;
}
// scrollbar===================================================================

.button_unset() {
	font-family: Museo Cyrl,Arial,sans-serif;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
}

.button_std(@width: 370px; @height: 70px; @color: @button; @opacity: .7;) {
	.button_unset();
	background: @color;
	cursor: pointer;
	text-align: center;
	transition: .3s;
	font-size: 18px;
	line-height: 130%;
	color: @fontColor;
	border-radius: 50px;
	position: relative;
	overflow: hidden;

	width: @width;
	height: @height;
	box-shadow: 0px 8px 24px fade(@color, 40%);;

	&:hover {
		background: fade(@color, 80%);
	    animation-name: pulse;
    	animation-duration: 1s;
    	box-shadow: none;
	}

	&::after {
		display: block;
		content: "";
		z-index: 1;
		width: 100%;
		height: 100%;
		background: url(../img/main/highlight.png) no-repeat;
		position: absolute;
		top: 0;
		left: -40px;
		opacity: @opacity;

		transition: all 0.1s;
		animation-name: slideme;
		animation-duration: 3s;
		animation-delay: 0.05s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		.xs-block({
			display: none;
		});
	}

	@keyframes slideme {
		0% {left: -229px;}
		30% {left: 110%;}
		100% {left: 110%;} 
	}

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0 fade(@color, 40%);
		}
		70% {
			box-shadow: 0 0 0 2rem fade(@color, 0%);
		}
		100% {
			box-shadow: 0 0 0 0 fade(@color, 0%);
		}		
	}

}



// VARIABLES****************************************************
@fontColor: #000000;
@twoColor: #333333;
@optiColor: #FF833D;
@button: #FFF859;
@mainBack: #FAFAFA;
@backCircle: #F4F4F4;
@error: #FF5A5A;

// VARIABLES****************************************************





// Elements=====================================================
// =============================================================
.h2(@color: @twoColor) {
	color: @color;
	text-align: center;
	font-weight: 700;
	font-size: 36px;
	line-height: 126%;
	position: relative;
	margin-bottom: 50px;
	.lg-block({
		font-size: 28px;
		span {
			font-size: 28px;
		}
	});
	.md-block({
		font-size: 24px;
		span {
			font-size: 24px;
		}
	});
	.xs-block({
		&::before, &::after {
			display: none;
		}
	});
	span {
		font-weight: 700;
		font-size: 36px;
		line-height: 126%;
		color: @optiColor;
	}
}

.logo() {
	display: flex;
	align-items: center;
	color: @twoColor;
	cursor: default;
	// &:hover {
	// 	transition: .2s;
	// 	color: @optiColor;
	// }
	img {
		width: 83px;
		height: 72px;
		margin-right: 12px;
		.md-block({
			width: 66px;
			min-width: 66px;
			height: 57px;
			margin-right: 10px;
		});
		.xs-block({
			width: 63px;
			min-width: 63px;
			height: 58px;
		});
	}
	.title {
		font-weight: 900;
		font-size: 27px;
		line-height: 32px;
		letter-spacing: 0.01em;
		.md-block({
			font-size: 24px;
		});
		.xs-block({
			font-size: 22px;
		});
	}
	.desc {
		font-weight: 500;
		font-size: 12px;
		line-height: 140%;
		letter-spacing: -0.03em;
		display: block;
		width: 244px;
		.xs-block({
			font-size: 10px;
			width: 201px;
		});
	}
}


.addr() {
		font-weight: 500;
		color: @twoColor;
		display: flex;
		align-items: center;
	    margin: 0 0 0 auto;
	    flex-wrap: wrap;
	    .title {
			width: 100%;
			margin-bottom: -30px;
	    }
	    .text {
			width: 100%;
	    }
	    .lg-block({
	    	width: 240px;
	    });
	    .md-block({
	    	display: none;
	    });
	    .xs-block({
	    	display: flex;
	    	height: 70px;
	    	margin: 0 auto;
	    	margin-bottom: 10px;
	    });
}

.time() {
	font-weight: 500;
	color: @twoColor;
	display: flex;
	align-items: center;
    margin: 0 0 0 auto;
	margin-right: 150px;
	.lg-block({
		margin-right: 30px;
	});
	.md-block({
		font-size: 16px;
		margin-right: 17px;
	});
	.xs-block({
		width: 290px;
		margin: 0 auto;
		margin-bottom: 20px;
	});
	img {
		margin-right: 27px;
		.lg-block({
			margin-right: 10px;
		});
		// .md-block({
		// 	margin-right: 17px;
		// });
	}
	.text {
		.xs-block({
			display: flex;
			* {
				font-size: 16px;
				line-height: 18px;
			}
			.number {
				margin-right: 5px;
			}
		});
	}
}


.tel() {
	display: flex;
	align-items: center;
	.xs-block({
		width: 290px;
		margin: 0 auto;
	});
	&:hover {
		.title {
			transition: .3s;
			color: @optiColor;
		}
	}
	img {
		margin-right: 20px;
		.md-block({
			margin-right: 10px;
		});
		.xs-block({
			margin-right: 17px;
		});
	}
	.title {
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		color: @twoColor;
		.md-block({
			font-size: 18px;
		});
		.xs-block({
			font-size: 22px;
		});
	}
}


.counter() {
	.text() {
		font-weight: 700;
		font-size: 26px;
		line-height: 126%;
		color: @twoColor;
	}
	.text();
	margin: 0 auto;
	text-align: center;
	margin-top: 28px;
	.now, .all {
		.text();
	}
}

// input______________________________________________
.input(@width: 265px; @height: 45px) {
	box-sizing: border-box;
	width: @width;
	height: @height;
	padding-left: 0;
	outline: none;
	border: 1px solid #EDEDED;
	border-radius: 29px;
	transition: .2s;
	
	color: @fontColor;
	font-weight: 500;
	line-height: normal;
	font-size: 16px;
	letter-spacing: 0.02em;

	&::placeholder {
		color: #8A8A8A;
		font-weight: 300;
		font-size: 18px;
		line-height: 130%;
		text-align: center;
	}
}
// input______________________________________________



.pseudo_lines(@width: 150px) {
	position: absolute;
	content: '';
	height: 1px;
	width: @width;
	background: linear-gradient(169.34deg, rgba(255, 130, 61, 0.4) 15.44%, rgba(166, 171, 184, 0.4) 97.7%);
	border-radius: 50px;
}


.error() {
	display: none;
	opacity: 0;
	position: absolute;
	color: @error;
	font-size: 13px;
	&.active {
	    display: flex;
	    opacity: 1;
	    transition: .3s;
	}
}


// end__Elements=====================================================



/*--------------------------------------------------------------*/
.fade-enter{
  opacity: 0;
}

.fade-enter-active{
  transition: .2s;
}

.fade-enter-to{

}

.fade-leave{

}

.fade-leave-active{
  transition: .2s;
}

.fade-leave-to{
  opacity: 0;
}
/*--------------------------------------------------------------*/


// lazyload------------------------------------------------------------------
.lazy_show {
	opacity: 1;
	transition: .5s;
}

img.lazy {
	opacity: 0;
}
// lazyload------------------------------------------------------------------