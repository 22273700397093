@import "entry";

.landcomp__cost {
	background: @mainBack;
	padding-top: 60px;
	padding-bottom: 105px;
	position: relative;
	z-index: 0;
	.lg-block({
		padding-bottom: 65px;
	});
	.md-block({
		padding-bottom: 90px;

		.flickity-viewport {
			width: 100%;
		}
		.carousel-cell {
			width: 100%;
		}
		.flickity-prev-next-button {
			top: calc(100% + 40px);
		}
		.flickity-prev-next-button.previous {
		    left: 220px;
		}
		.flickity-prev-next-button.next {
		    right: 220px;
		}
	});
	.xs-block({
		padding-top: 30px;
		padding-bottom: 30px;
		.flickity-prev-next-button.previous {
		    left: 10px;
		}
		.flickity-prev-next-button.next {
		    right: 20px;
		}
	});
	&::before {
		content: '';
		position: absolute;
		width: 187px;
		height: 187px;
		background: url(../img/cost/left_circle.png) 0 0/cover no-repeat;
		left: 155px;
		bottom: 30px;
		z-index: -1;
		.lg-block({
			left: -50px;
			bottom: 20px;
		});
		.xs-block({
			display: none;
		});
	}
	&::after {
		content: '';
		position: absolute;
		width: 107px;
		height: 64px;
		background: url(../img/cost/right_circle.png) 0 0/cover no-repeat;
		right: 455px;
		bottom: 0;
		z-index: -1;
		.lg-block({
			background: url(../img/cost/left_circle.png) 0 0/cover no-repeat;
			width: 107px;
			height: 107px;
			right: 0;
			bottom: 40px;
		});
		.xs-block({
			display: none;
		});
	}
	.wrapper {
		position: relative;
		z-index: 0;
		.xs-block({
			padding: 0;
		});

		&::before {
			content: '';
			position: absolute;
			width: 310px;
			height: 320px;
			background: url(../img/cost/left.png) 0 0/cover no-repeat;
			left: -155px;
			top: -55px;
			z-index: -1;
			.lg-block({
				background: url(../img/cost/left_lg.png) no-repeat;
				background-size: cover;
				width: 228px;
				height: 276px;
				left: -80px;
			});
			.xs-block({
				display: none;
			});
		}
		&::after {
			content: '';
			position: absolute;
			width: 233px;
			height: 210px;
			background: url(../img/cost/right.png) no-repeat;
			background-size: cover;
			right: -65px;
			top: -10px;
			z-index: -1;
			.lg-block({
				background: url(../img/cost/right_lg.png) no-repeat;
				background-size: cover;
				width: 256px;
				height: 273px;
				right: -40px;
				top: -30px;
			});
			.xs-block({
				display: none;
			});
		}
		.counter {
			.counter();
			display: none;
			.md-block({
				display: block;
			});
		}
	}

	h2 {
		.h2();
		margin-bottom: 100px;
		.lg-block({
			margin-bottom: 70px;
		});
		.md-block({
			margin-bottom: 30px;
		});
	}
	&_block {
		display: flex;
	    flex-wrap: wrap;
		.item {
			width: 648px;
		    min-width: 648px;
			height: 310px;
			background: #ffffff;
			padding: 30px 50px 30px 30px; 
			display: flex;
			.lg-block({
				width: 496px;
				min-width: 496px;
				height: 285px;
			});
			.md-block({
				width: 606px;
				min-width: 606px;
				height: 290px;

				margin: 0 65px;
				padding: 0;
			});
			.xs-block({
				min-width: 100%;
				width: 100%;
				height: 650px;
				flex-direction: column;
				align-items: center;
			});
			&:nth-child(odd) {
				margin-right: 30px;
				margin-bottom: 30px;
				.lg-block({
					margin-right: 20px;
					margin-bottom: 20px;
				});
				.md-block({
					margin: 0 65px;
				});
			}
			&:nth-child(4) {
				padding: 30px 20px 30px 30px; 
				.md-block({
					padding: 0;
				});
			}
			.for_img {
				width: 190px;
				min-width: 190px;
				height: 250px;
				margin-right: 30px;
				.lg-block({
					width: 145px;
					min-width: 145px;
					height: 190px;
				});
				.md-block({
					width: 290px;
					min-width: 290px;
					max-width: 290px;
					height: 290px;
					margin-right: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
				});
				.xs-block({
					margin: 0;
					margin-bottom: 15px;
				});
			}
			.desc {
				display: flex;
				flex-direction: column;
				.md-block({
					padding-top: 22px;
					padding-right: 22px;
					padding-bottom: 44px;
				});
				.xs-block({
					align-items: center;
					padding: 0 15px;
					height: calc(100% - 305px);
				});
				.title {
					color: @twoColor;
					font-weight: 700;
					font-size: 26px;
					line-height: 126%;
					margin-bottom: 15px;
					.lg-block({
						font-size: 20px;
						line-height: 22px;
					});
					.xs-block({
						font-size: 22px;
						margin: 0 auto 0 0;
						margin-bottom: 25px;
					});
				}
				.text {
					color: @fontColor;
					font-weight: 300;
					font-size: 16px;
					line-height: 160%;
					.lg-block({
						font-size: 14px;
					});
					.xs-block({
						font-size: 16px;
						margin-bottom: 30px;
					});
				}
				button {
					.button_std(270px; 50px);
					margin: auto 0 0 0;
					&:after {
						background: url(../img/cost/highlight.png) no-repeat;
					}
					.lg-block({
						width: 205px;
						height: 40px;
					});
					.xs-block({
						.button_std(290px; 55px);
						margin-bottom: 50px;
					});
				}
			}
		}
	}
}

