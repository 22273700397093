@import "entry";

.landcomp_repair_opti {
	background: @mainBack;
	.wrapper {
		display: flex;
		justify-content: space-between;
		padding: 50px 0 50px 115px;
		.md-block({
			padding: 10px 0 10px 15px;
		});
		.xs-block({
			flex-direction: column;
			align-items: center;
		});
	}
	.item {
		width: 300px;
		position: relative;
		.lg-block({
			width: 270px;
		});
		.md-block({
			height: 200px;
			width: 220px;
			display: flex;
			align-items: center;
			&:last-child {
				width: 242px;
			}
		});
		&::before {
			content: "";
			position: absolute;
			background: url(../img/repair_opti/back.png) no-repeat;
			background-size: cover;
			width: 249px;
			height: 148px;
			top: -27px;
			left: -10px;
			.lg-block({
				top: -35px;
			});
			.md-block({
				background: url(../img/repair_opti/back_md.png) no-repeat;
				background-size: cover;
				width: 236px;
				height: 178px;
				top: unset;
				left: unset;
			});
			.xs-block({
				background: url(../img/repair_opti/back_xs.png) no-repeat;
				background-size: cover;
				width: 249px;
				height: 148px;
				top: unset;
				left: -20px;
			});
		}
		p {
			font-weight: 500;
			font-size: 18px;
			line-height: 140%;
			color: @fontColor;
			position: relative;
			.lg-block({
				font-size: 16px;
			});
			.md-block({
				font-size: 14px;
				transform: translateX(20px) translateY(15px);
			});
			&::before {
				content: "";
				position: absolute;
				background: url(../img/repair_opti/badge.png) no-repeat;
				background-size: cover;
				width: 47px;
				height: 64px;
				top: -13px;
				left: calc(-47px - 20px);
				.md-block({
					display: none;
				});
			}
		}
	}
}