@import "entry";

.title_modal() {
	font-weight: 700;
	font-size: 26px;
	line-height: 126%;
	color: @twoColor;
	text-align: center;
	.xs-block({
		font-size: 20px;
	});
}

.modal_opti() {
	&.fancybox-content {
		padding-top: 30px;
		padding-left: 20px;
		padding-right: 30px;
	}
	.fancybox-button svg {
		margin-top: 15px;
		margin-right: 15px;
		width: 16px;
		height: 16px;
		* {
			fill: #333333;
		}
	}
}

#modal_accepted {
	background: url(../img/optional/accepted.jpg) no-repeat;
	background-size: cover;
	box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
	width: 482px;
	height: 286px;
	overflow: hidden;
	position: relative;
	padding: 61px 94px 0 !important;
	display: none;
	.xs-block({
		width: 320px;
		background: url(../img/optional/accepted_xs.jpg) no-repeat;
		background-size: cover;
		padding: 70px 15px 0!important;
	});

	.title {
		.title_modal();
		margin-bottom: 15px;
	}
	.desc {
		font-weight: 300;
		font-size: 20px;
		line-height: 126%;
		color: @twoColor;
		text-align: center;
		display: block;
		margin: 0 auto;
		width: 290px;
	}
	.modal_opti();
}


#modal_call {
	background: url(../img/optional/call.jpg) no-repeat;
	background-size: cover;
	box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
	width: 482px;
	height: 581px;
	overflow: hidden;
	position: relative;
	padding: 61px 94px 0 !important;
	display: none;
	.xs-block({
		width: 320px;
		background: url(../img/optional/call_xs.jpg) no-repeat;
		background-size: cover;
		padding: 100px 15px 0!important;
	});

	.title {
		.title_modal();
		margin-bottom: 48px;
	}
	form {
		.error_name {
			.error();
		}
		.error_tel {
			.error();
		}
		.input_item {
			&:first-child {
				margin-bottom: 30px;
			}
			input {
				.input(290px; 50px);
			}
		}
		.action {
			.button_std(290px);
			margin-top: 50px;
		}
	}
	.modal_opti();
}




// =============================================================

#modal_call_sale {
	background: url(../img/optional/call_sale.jpg) no-repeat;
	background-size: cover;
	box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
	width: 482px;
	height: 581px;
	overflow: hidden;
	position: relative;
	padding: 61px 94px 0 !important;
	display: none;
	.xs-block({
		width: 320px;
		background: url(../img/optional/call_sale_xs.jpg) no-repeat;
		background-size: cover;
		padding: 100px 15px 0!important;
	});

	.title {
		.title_modal();
		margin-bottom: 48px;
	}
	form {
		.error_name {
			.error();
		}
		.error_tel {
			.error();
		}
		.input_item {
			&:first-child {
				margin-bottom: 30px;
			}
			input {
				.input(290px; 50px);
			}
		}
		.action {
			.button_std(290px);
			margin-top: 50px;
		}
	}
	.modal_opti();
}