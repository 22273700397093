@import "entry";

.landcomp_404 {
    height: 100vh;
    background: url(../img/404/back.jpg) no-repeat;
    background-size: cover;
    padding-top: 15vh;

    .md-block({
        background: url(../img/404/back_md.jpg) no-repeat;
        background-size: cover;
    });
    .xs-block({
        background: url(../img/404/back_xs.jpg) no-repeat;
        background-size: cover;
    });

    img {
        display: none;
        margin: 0 auto;
        &.lg_404 {
            display: block;
        }
        .md-block({
            &.error {
                display: none;
            }
            &.md_404 {
                display: block;
                width: 600px;
                height: auto;
            }
        });
        .xs-block({
            &.error {
                display: none;
            }
            &.xs_404 {
                display: block;
            }
        });
    }
    .text {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 24px;
        line-height: 145%;
        color: #4F4F4F;
        text-align: center;
        margin-top: 30px;

        .md-block({
            font-size: 18px;
            line-height: 145%;
            margin-top: 0;
        });
        .xs-block({
            font-size: 14px;
            line-height: 145%;
        });
    }
    a {
        .button_std(290px; 70px);
        margin: 0 auto;
        margin-top: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}