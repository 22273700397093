@import "entry";

// ================================================================
.order_title() {
	display: flex;
	align-items: center;
	.num {
		font-weight: 700;
		font-size: 56px;
		line-height: 126%;
		color: @optiColor;
		margin-right: 30px;
		.md-block({
			font-size: 46px;
			margin-right: 20px;
		});
		.xs-block({
			font-size: 56px;
			margin: 0;
		});
	}
	.text {
		font-weight: 700;
		font-size: 36px;
		line-height: 126%;
		color: @twoColor;
		.md-block({
			font-size: 24px;
		});
		.xs-block({
			font-size: 26px;
			margin: 0;
			margin-bottom: 25px !important;
		});
	}
}

.order_list() {
	position: relative;
	.xs-block({
		margin-bottom: 15px !important;
	});
	li {
		font-weight: 300;
		font-size: 18px;
		line-height: 220%;
		color: @fontColor;
		position: relative;
		.lg-block({
			line-height: 200%;
		});
		.md-block({
			font-size: 16px;
			line-height: 150%;
		});
		.xs-block({
			padding-left: 27px !important;
		});
		&::before {
			position: absolute;
			content: '';
			width: 13px;
			height: 1px;
			top: 18px;
			left: -40px;
			background: #000000;
			.xs-block({
				top: 12px;
				left: 0;
			});
		}	
	}
}
// ================================================================

.landcomp__order {
	margin-bottom: -148px;
	padding-top: 60px;
	.lg-block({
		padding-top: 50px;
	});
	.xs-block({
		margin-bottom: -100px;
		* {
			transform: unset !important;
			margin: 0 auto !important;
			padding: 0 !important;			
			flex-direction: column !important;
		}
	});
	h2 {
		.h2();
		.xs-block({
			padding: 0 15px !important;
		});
		&::before {
			.pseudo_lines();
			left: 0;
			top: 22px;		
			.lg-block({
				.pseudo_lines(110px);		
			});	
			.md-block({
				.pseudo_lines(23px);		
				top: 16px;	
			});
		}
		&::after {
			.pseudo_lines();
			right: 0;
			top: 22px;
			.lg-block({
				.pseudo_lines(110px);		
			});			
			.md-block({
				.pseudo_lines(23px);		
				top: 16px;	
			});
		}
	}	
	.one {
		display: flex;
		margin-bottom: -100px;
		.lg-block({
			margin-bottom: -10px;
		});
		.md-block({
			margin-bottom: 170px;
		});
		.left {
			img {
				min-width: 1020px;
				transform: translateX(-250px) translateY(-125px);
				&.lg {
					min-width: auto;
					min-width: 821px;
					transform: translateX(-226px) translateY(-90px);
				}
				&.md {
					min-width: auto;
					min-width: 633px;
					transform: translateX(-185px) translateY(-70px);
				}
				&.xs {
					min-width: auto;
					min-width: 290px;
				}
			}
			.for_order {
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: @fontColor;
				width: 286px;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
			    transform: translateY(-238px) translateX(160px);
			    .lg-block({
			    	transform: translateY(-180px) translateX(80px);
			    });
			    .md-block({
			    	transform: translateY(150px) translateX(242px);
			    });
			    .xs-block({
			    	display: none;
			    });
				span {
					color: @optiColor;
					text-transform: uppercase;
				}
				button {
					.button_std();
					margin-top: 25px;
				}
				&::after {
					content: '';
					position: absolute;
					width: 292px;
					height: 242px;
					background: url(../img/order/one/line.png) 0 0/cover no-repeat;
					left: 204px;
					top: 25px;
					z-index: 2;
					.lg-block({
						left: 105px;
						top: 64px;
					});
					.md-block({
						background: url(../img/order/one/line.png) 0 0/cover no-repeat;
						left: 190px;
						top: -31px;
					});
					.xs-block({
						display: none;
					});
				}
			}
		}
		.right {
			margin-left: -320px;
			padding-top: 80px;
			.lg-block({
				padding-top: 30px;
			});
			.md-block({
				margin-left: -257px;
				width: 350px;
			});
			.xs-block({
				width: 100%;
				padding: 0 15px !important;
				margin: 0 auto !important;
			});
			.title {
				.order_title();
				margin-left: -200px;
				margin-bottom: 55px;
				.lg-block({
					margin-bottom: 20px;
				});
				.md-block({
					margin-left: -128px;
				});
			}
			.main_text {
				.order_list();

				&::before {
					content: '';
					position: absolute;
					width: 828px;
					height: 668px;
					background: url(../img/order/one/back_1.png) 0 0/cover no-repeat;
					left: -184px;
					top: -235px;
					z-index: -2;

					.md-block({
						left: -150px;
						top: -183px;
					});
					.xs-block({
						display: none;
					});
				}
			}
			.optional {
				font-weight: 500;
				font-size: 16px;
				line-height: 180%;
				color: @fontColor;
				width: 649px;
				height: 422px;
				background: url(../img/order/one/back_2.png) no-repeat;
				background-size: cover;
				padding-top: 115px;
				padding-left: 173px;
				.lg-block({
					transform: translateX(-70px) translateY(-40px);
				});
				.md-block({
					background: url(../img/order/one/back_2_md.png) no-repeat;
					background-size: cover;
					transform: translateX(-360px) translateY(-35px);
					padding-left: 220px;
				});
				.xs-block({
					font-size: 14px;
					background: url(../img/order/one/back_2_xs.png) no-repeat;
					background-size: cover;
					transform: translateX(-171px) !important;
					padding-left: 170px!important;
					padding-top: 135px!important;
				});
				p {
					width: 327px;
					text-align: center;
					.xs-block({
						width: 290px;
						margin: 0 !important;
					});
				}
			}
			.for_order {
				display: none;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: @fontColor;
				width: 286px;
				text-align: center;
				flex-direction: column;
				align-items: center;
				span {
					color: @optiColor;
					text-transform: uppercase;
				}
				button {
					.button_std(290px);
					margin-top: 25px !important;
					flex-direction: row !important;
				}
				.xs-block({
					display: flex;
				});
			}
		}
	}
	.two {
		display: flex;
		justify-content: space-between;
	    margin-bottom: -110px;
	    .xs-block({
	    	margin-top: 60px !important;
	    });
		.left {
			.md-block({
				padding-left: 30px;
			});
			.title {
				.order_title();
			    margin-bottom: 50px;
			}
			.main_text {
				.order_list();
				.lg-block({
					transform: translateX(40px);
					br {
						display: none;
					}
				});
				.md-block({
					width: 298px;
					li {
						line-height: 200%;
					}
				});
				.xs-block({
					width: 100%;
					padding: 0 15px!important;
				});
			}
		}
		.right {
			.xs-block({
				width: 320px;
			});
			img {
				min-width: 648px;
				height: 430px;
				box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
				.lg-block({
					min-width: 520px;
					height: 345px;
					display: block;
					margin: 0 0 0 auto;
				});
				.md-block({
					min-width: 386px;
					height: 256px;
					margin: 0;
					margin-left: 50px;
				});
				.xs-block({
					width: 320px;
					min-width: 320px;
					margin-top: 30px !important;
					margin-bottom: 20px !important;
				});
			}
			.optional {
				z-index: -1;
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 180%;
				color: @fontColor;
				text-align: left;
				width: 630px;
				height: 356px;
				background: url(../img/order/two/back.png) no-repeat;
				background-size: cover;
				padding-top: 175px;
				padding-left: 185px;
				transform: translateY(-140px) translateX(-185px);
				.lg-block({
					transform: translateY(-145px) translateX(14px);
					padding-left: 165px;
				});
				.md-block({
					transform: translateY(-145px) translateX(-81px);
				});
				.xs-block({
				    transform: translateX(-145px) translateY(-168px) !important;
				    padding-left: 160px !important;
				    padding-top: 168px !important;
				});
				span {
					font-weight: 500;
					font-size: 14px;
					line-height: 160%;
					color: #919191;
					.xs-block({
						width: 290px !important;
						margin: 0 !important;
						text-align: center;
					});
				}
				p {
					.xs-block({
						width: 301px !important;
						margin: 0 !important;
						text-align: center;
						font-size: 14px;
					});
				}
			}
		}
	}
	.three {
		display: flex;
		margin-bottom: 70px;
		.lg-block({
			margin-bottom: 0;
		});
		.xs-block({
			margin-top: -130px !important;
		});
		.left {
			.md-block({
				padding-left: 25px;
			});
			.xs-block({
				justify-content: center;
				align-items: center;
				display: flex;
				width: 320px;
				padding: 0 15px !important;
			});
			.desc {
				padding-left: 80px;
				.lg-block({
					padding-left: 0;
				});
				.title {
					font-weight: 700;
					font-size: 26px;
					line-height: 126%;
					color: @twoColor;
					margin-bottom: 25px;
					.xs-block({
						width: 290px;
						font-size: 23px;
					});
				}
				.text {
					width: 454px;
					.md-block({
						width: 383px;
					});
					.xs-block({
						width: 100%;
					});
					p {
						font-weight: 300;
						font-size: 18px;
						line-height: 150%;
						color: @fontColor;
						.md-block({
							font-size: 16px;
						});
						&:first-child {
							margin-bottom: 45px;
						}
						span {
							font-weight: 700;
							font-size: 28px;
							line-height: 150%;
							color: @optiColor;
							margin-right: 15px;
							.xs-block({
								margin-right: 15px !important;
							});
						}
					}
				}
			}
			.optional {
				z-index: -1;
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 180%;
				color: @fontColor;
				text-align: center;

				width: 665px;
				height: 408px;
				background: url(../img/order/three/back.png) no-repeat;
				background-size: cover;
				padding-top: 155px;
				padding-left: 174px;
				transform: translateX(-35px);
				.md-block({
					transform: translateX(-35px) translateY(-25px);
				});
				.xs-block({
					width: 630px;
					height: 356px;
					background: url(../img/order/three/back_xs.png) no-repeat;
					background-size: cover;
					transform: translateX(-178px) translateY(-22px)!important;
					padding-left: 15px !important;
					padding-top: 140px !important;
				});
				p {
					width: 400px;
					text-align: center;
					.xs-block({
						width: 290px;
						font-size: 14px;
					});
				}
				&::after {
					content: '';
					position: absolute;
					width: 207px;
					height: 227px;
					background: url(../img/order/three/line.png) 0 0/cover no-repeat;
					right: -300px;
					top: 265px;
					z-index: 2;
					.lg-block({
						top: 140px;
						right: -220px;
					});
					.md-block({
						top: 154px;
						right: -79px;
						transform: rotate(23deg);
					});
					.xs-block({
						display: none;
					});
				}
			}
		}
		.right {
			margin: 0 0 0 auto;
			.lg-block({
				margin-left: -173px;
			});
			.md-block({
				margin-left: -250px;
			});
			.block {
				display: flex;
				.xs-block({
					flex-direction: row !important;
					width: 290px;
				});
				.for_img {
					width: 310px;
					min-width: 310px;
					height: 438px;
					box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.2);
					.lg-block({
						width: 247px;
						min-width: 247px;
						height: 349px;
					});
					.md-block({
						width: 148px;
						min-width: 148px;
						height: 210px;
					});
					.xs-block({
						width: 140px;
						min-width: 140px;
						height: 200px;
					});
					&:first-child {
						margin-right: 30px;
						.lg-block({
							margin-right: 23px;
						});
						.md-block({
							margin-right: 14px;
						});
					}
				}
			}
		}
	}
	.four {
		display: flex;
		.lg-block({
			padding-bottom: 65px;
		});
		.md-block({
			padding-bottom: 100px;
		});
		.xs-block({
			margin-top: 40px !important;
			margin-bottom: 120px !important;
		});
		.left {
			min-width: 469px;
			transform: translateY(100px);
			img {
				&.md {
					min-width: 290px;
					width: 290px;
				}
			}
			.xs-block({
				display: none;
			});
		}
		.right {
			margin-top: 20px;
			.xs-block({
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 320px;
			});
			.title {
				.order_title();
				transform: translateX(-171px);
				.lg-block({
					width: 630px;
				});
				.md-block({
					transform: translateX(-410px);
				});
				.xs-block({
					width: 100%;
					text-align: center;
					margin: 0 auto !important;
				});
			}
			.main_text {
				.order_list();
			    margin-left: 100px;
			    margin-top: 50px;
			    .lg-block({
			    	margin-left: 80px;
			    });
			    .md-block({
			    	margin-left: -134px;
			    	width: 361px;
			    	br {
			    		display: none;
			    	}
			    	li {
			    		line-height: 200%;
			    	}	
			    });
			    .xs-block({
			    	width: 100%;
			    	margin: 0 !important;
			    	padding: 0 15px !important;
			    });
				&::before {
					content: '';
					position: absolute;
					width: 828px;
					height: 668px;
					background: url(../img/order/four/back_1.png) 0 0/cover no-repeat;
					left: -572px;
					top: -180px;
					z-index: -2;
					.md-block({
						top: -125px;
					});
					.xs-block({
						display: none;
					});
				}
			}
			.optional {
				z-index: -1;
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 180%;
				color: @fontColor;
				text-align: center;

				width: 497px;
				height: 403px;
				background: url(../img/order/four/back_2.png) no-repeat;
				background-size: cover;
				padding-top: 89px;
				padding-left: 20px;
				transform: translateX(250px) translateY(-72px);
				z-index: 3;
				.lg-block({
					transform: translateX(23px) translateY(-42px);
				});
				.md-block({
					transform: translateX(-323px) translateY(-29px);
				});
				.xs-block({
					transform: translateX(-90px) translateY(110px) !important;
					padding-top: 90px !important;
				});
			}
			.book {
				display: none;
				position: relative;
				z-index: 5;
				width: 320px;
				margin: 0 auto !important;
			    margin-bottom: -150px !important;
				.xs-block({
					display: block;
				});
			}
		}
	}
}