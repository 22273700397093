@import "entry";

.landcomp__mac {
	padding-top: 120px;
	padding-bottom: 80px;
	.lg-block({
		padding-top: 70px;
		padding-bottom: 60px;
	});
	.md-block({
		padding-bottom: 20px;
	});
	.xs-block({
		padding-bottom: 50px;
		.flickity-viewport {
			width: 100%;
		}
		.carousel-cell {
			width: 100%;
		}
		.flickity-prev-next-button {
			top: 100%;
			z-index: 300;
		}
	});
	h2 {
		.h2();
		margin-bottom: 90px;
		.md-block({
			margin-bottom: 50px;
		});
	}
	.counter_1, .counter_2 {
		.counter();
		display: none;
		transform: translateY(-80px);
		.xs-block({
			display: block;
		});
	}
	.counter_2 {
		transform: translateY(-40px);
	}
	.models {
		margin-bottom: 90px;
		display: flex;
		justify-content: space-between;
		position: relative;
		.md-block({
			flex-wrap: wrap;
			justify-content: space-evenly;
			margin-bottom: 70px;
		});
		&::after {
			content: '';
			position: absolute;
			width: 450px;
			height: 366px;
			background: url(../img/mac/pseudo.png) 0 0/cover no-repeat;
			top: -325px;
			right: -70px;
			.lg-block({
				background: url(../img/mac/pseudo_lg.png) 0 0/cover no-repeat;
				width: 305px;
				height: 254px;
				top: -220px;
				right: -40px;
			});
			.md-block({
				background: url(../img/mac/pseudo_md.png) 0 0/cover no-repeat;
				width: 246px;
				height: 209px;
				top: -195px;
				right: -80px;
			});
			.xs-block({
				display: none;
			});
		}
		.item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			.md-block({
				&:nth-child(-n+3) {
					margin-bottom: 40px;
				}
			});
			.xs-block({
				width: 100%;
			});
			&:hover {
				.text {
					transition: .2s;
					color: @optiColor;
				}
			}
			.for_img {
				width: 200px;
				height: 150px;
				.lg-block({
					width: 160px;
					height: 110px;
				});
				.md-block({
					width: 183px;
					height: 130px;
				});
			}
			.text {
				font-weight: 700;
				font-size: 18px;
				line-height: 126%;
				color: @twoColor;
				text-align: center;
				.lg-block({
					font-size: 16px;
				});
				.xs-block({
					font-size: 18px;
				});
			}
		}
	}
	.malfunctions {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			width: 234px;
			height: 255px;
			background: url(../img/mac/malfunctions/pseudo.png) 0 0/cover no-repeat;
			top: -105px;
			left: 155px;
			z-index: -1;
			.lg-block({
				left: -10px;
			});
			.md-block({
				top: -120px;
				left: -90px;
			});
			.xs-block({
				display: none;
			});
		}
		.title {
			font-weight: 700;
			font-size: 26px;
			line-height: 126%;
			color: @twoColor;
			text-align: center;
			margin-bottom: 15px;
			br {
				display: none;
			}
			.md-block({
				font-size: 24px;
			});
			.xs-block({
				font-size: 21px;
				br {
					display: block;
				}
			});
		}
		.desc {
			font-weight: 300;
			font-size: 22px;
			line-height: 130%;
			margin-bottom: 50px;
			text-align: center;
			.xs-block({
				font-size: 18px;
			});
		}
		&_block {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-right: 95px;
				cursor: pointer;
				.lg-block({
					margin-right: 23px;
				});
				.md-block({
					margin: 0;
					width: 25%;
					justify-content: flex-start;
				});
				.xs-block({
					width: 100%;
				});
				&:hover {
					.for_img {
						background: @optiColor;
						transition: .3s;

						&::after {
							animation-name: pulse_circle_mac;
							animation-duration: .4s;
						}
					}
					.text {
						transition: color .2s;
						color: @optiColor;
					}
				}
				&:nth-child(5n+5) {
					margin-right: 0;
				}
				&:nth-child(-n+5) {
					margin-bottom: 40px;
				}
				.md-block({
					&:nth-child(odd), &:nth-child(even) {
						margin: 0;
					}
					&:nth-child(-n+4) {
						margin-bottom: 50px;
					}
					&:nth-child(n+4):nth-child(-n+8) {
						margin-bottom: 50px;
					}
					&:nth-child(9) {
						margin-left: 25%;
					}
					&:nth-child(10) {
						margin-right: 25%;
					}
				});
				.for_img {
					width: 120px;
					height: 120px;
					margin-bottom: 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: @backCircle;
					border-radius: 50%;
					position: relative;
					.lg-block({
						width: 100px;
						height: 100px;
					});

					&::after {
						content: '';
						position: absolute;
						width: 1px;
						height: 1px;
						background: transparent;
						border-radius: 50%;
						left: calc(60 / 2);
						top: 60px;
						z-index: -1;
						.lg-block({
							top: 50px;
						});
					}
				}
				.text {
					width: 196px;
					text-align: center;
					font-weight: 700;
					font-size: 18px;
					line-height: 126%;
					color: @twoColor;
					.lg-block({
						width: 150px;
						font-size: 16px;
						line-height: 126%;
					});
					.xs-block({
						font-size: 18px;
					});
				}
			}
		}
	}
}



@keyframes pulse_circle_mac {
	0% {
		box-shadow: 0 0 0 0 fade(@optiColor, 40%);
	}
	70% {
		box-shadow: 0 0 0 110px fade(@optiColor, 0%);
	}
	100% {
		box-shadow: 0 0 0 0 fade(@optiColor, 0%);
	}		
}